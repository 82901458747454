import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import { Financial, Rental, RentalProfitability } from '../../../graphql/schema/__generated__/graphql-types';
import { calcaulteRentalMetrics } from 'functions';

const Container = tw.div`
flex grid grid-cols-3 pt-1
`;
const ColDiv = tw.div`
col-span-2 pr-4 mr-2 mt-1 mb-1 border-r
`;

const Global = tw.div`
col-span-1 flex items-center
`;

const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;

const Val = tw.div`
flex-grow pl-2 flex justify-end text-base text-blue-500
`;

const RowDiv = tw.div`
flex flex-row justify-between
`;

const ReturnOnInvestment = (props: {
  financial: Financial;
  rental: Rental;
  rentalProfitability: RentalProfitability;
}) => {
  const { financial, rental, rentalProfitability } = props;
  const rentalMetrics = calcaulteRentalMetrics(financial, rental);
  const rentalAssetAppreciation = rentalProfitability.assetAppreciation;
  return (
    <Container>
      <ColDiv>
        <RowDiv>
          <Heading2>Cash On Cash</Heading2>
          <Val>
            <NumberFormat
              //value={rentalIncome}
              value={rentalProfitability.cashOnCash}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              suffix={' %'}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Val>
        </RowDiv>

        <RowDiv>
          <Heading2>Appreciation</Heading2>
          <Val css={rentalAssetAppreciation ? tw`` : tw`text-gray-500`}>
            {rentalAssetAppreciation ? (
              <NumberFormat
                value={rentalAssetAppreciation}
                decimalScale={2}
                fixedDecimalScale
                displayType={'text'}
                thousandSeparator={' '}
                suffix={' %'}
                renderText={(value: string) => <span>{value}</span>}
              />
            ) : (
              'no data'
            )}
          </Val>
        </RowDiv>
      </ColDiv>
      <Global>
        <Heading2>R.O.I</Heading2>
        <Val css={rentalMetrics.Return_On_Investment ? tw`` : tw`text-gray-500`}>
          {rentalMetrics.Return_On_Investment ? (
            <NumberFormat
              value={rentalMetrics.Return_On_Investment}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              suffix={' %'}
              renderText={(value: string) => <span>{value}</span>}
            />
          ) : (
            'no data'
          )}
        </Val>
        {/* <Val css={capitalAppreciation ? tw`` : tw`text-gray-500`}>
          {global ? (
            <NumberFormat
              value={global}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              suffix={' %'}
              renderText={(value: string) => <span>{value}</span>}
            />
          ) : (
            'no data'
          )}
        </Val> */}
      </Global>
    </Container>
  );
};

export default ReturnOnInvestment;
