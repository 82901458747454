import tw from 'twin.macro';
//import ReactCountryFlag from 'react-country-flag';
import parse from 'html-react-parser';
import styled from 'styled-components';
import Maps from './maps';
import Address from './address';
import ArticleContainer from 'components/article/article-container';
import { Asset } from 'graphql/schema/__generated__/graphql-types';
import _ from 'lodash';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

// const Country = tw.div`
// flex flex-row items-center mt-8
// `;

const Title = tw.h2`
text-blue-500 font-medium text-xl mr-4
`;

const AboutContent = styled.div`
  ${tw``}
  h1 {
    ${tw`font-semibold py-2 mt-2    text-lg`}
  }
`;

const MapContainer = tw.div`
flex h-128 mt-8
`;

const Location = (props: { asset: Asset; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { asset } = props;
  const countryCode = asset.location.address.country;
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Location</Headline>
      <Divider />
      {/* <Country>
        <Title>{regionNames.of(countryCode)}</Title>
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            width: '3em',
            height: '3em',
          }}
        />
      </Country> */}

      <Title css={tw`mt-4`}>Address</Title>
      <Address location={asset.location} country={regionNames.of(countryCode)} />

      <Title css={tw`mt-4`}>About the city</Title>
      <AboutContent>{parse(asset.location.address.city.descriptrion, { trim: true })}</AboutContent>

      <MapContainer>
        <Maps coordinates={asset.location.coordinates} />
      </MapContainer>
    </ArticleContainer>
  );
};

export default Location;
