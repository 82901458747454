import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`;
const CardHead = tw.div`
bg-primary-500 text-white p-2
`;
const HeadLine = tw.h1`
text-lg font-medium uppercase
`;

const Content = tw.div`
px-2 flex flex-row
`;

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`;

const Value = tw.div`
flex-grow text-sm text-right capitalize
`;

const Price = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Price</Headline>
      <Divider />

      <Card>
        <CardHead>
          <HeadLine>Price</HeadLine>
        </CardHead>
        <Content>
          <Label>Unit Briick Price</Label>
          <Value>
            <NumberFormat
              value={50}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <CardHead>
          <HeadLine>Sale amount</HeadLine>
        </CardHead>

        <Content>
          <Label>Amount</Label>
          <Value>
            <NumberFormat
              value={300000}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <Content>
          <Label>Remaining</Label>
          <Value>
            <NumberFormat
              value={72700}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <CardHead>
          <HeadLine>Purchase Volume</HeadLine>
        </CardHead>

        <Content>
          <Label>Minimum Investment</Label>
          <Value>
            <NumberFormat
              value={50}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <Content>
          <Label>Maximum Investment</Label>
          <Value>
            <NumberFormat
              value={5000}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>
      </Card>
    </ArticleContainer>
  );
};

export default Price;
