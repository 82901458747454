import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`;
const CardHead = tw.div`
bg-primary-500 text-white p-2
`;
const HeadLine = tw.h1`
text-lg font-medium uppercase
`;

const Content = tw.div`
px-2 flex flex-row
`;

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`;

const Value = tw.div`
flex-grow text-sm text-right capitalize
`;
const Renting = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Renting</Headline>
      <Divider />
      <Card>
        <CardHead>
          <HeadLine>Gross Rent</HeadLine>
        </CardHead>
        <Content>
          <Label>Annual </Label>
          <Value>
            <NumberFormat
              value={95000}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <Content>
          <Label>Monthly </Label>
          <Value>
            <NumberFormat
              value={3033.33}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <CardHead>
          <HeadLine>Settlement</HeadLine>
        </CardHead>

        <Content>
          <Label>Frequency </Label>
          <Value>Weekly</Value>
        </Content>

        <Content>
          <Label>Amount </Label>
          <Value>
            <NumberFormat
              value={700}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>

        <CardHead>
          <HeadLine>Lease details</HeadLine>
        </CardHead>

        <Content>
          <Label>Type</Label>
          <Value>Long-term</Value>
        </Content>

        <Content>
          <Label>Statut </Label>
          <Value>Fully Rented</Value>
        </Content>

        <Content>
          <Label>Start </Label>
          <Value>01/12/2023</Value>
        </Content>

        <Content>
          <Label>End </Label>
          <Value>01/12/2023</Value>
        </Content>

        <Content>
          <Label>Deposit </Label>
          <Value>
            <NumberFormat
              value={6000}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Value>
        </Content>
      </Card>
    </ArticleContainer>
  );
};

export default Renting;
