import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BriicksListingPaginate,
  BriicksListingsFiltersArgs,
  BriicksListingsSortArgs,
  Listings_Sort_By,
  QueryGetBriicksListingsArgs,
} from 'graphql/schema/__generated__/graphql-types';

export interface ListingsState {
  filters: BriicksListingsFiltersArgs;
  sort: BriicksListingsSortArgs;
  listings: BriicksListingPaginate;
  isLoading: boolean;
  error: string;
}

export const initialState: ListingsState = {
  filters: {
    globalSearch: '',
    status: [],
    shortCode: '',
    countries: [],
    makers: [],
    profitabilityMetrics: [],
  },
  sort: {
    by: Listings_Sort_By.DateDes,
    withNoData: true,
  },
  listings: {
    page: 0,
    limit: 27,
    docs: [],
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 0,
    offset: 0,
    pagingCounter: 0,
    prevPage: 0,
    totalPages: 0,
  },
  isLoading: false,
  error: '',
};

export const ListingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    getBriicksListingsRequest: (state, action: PayloadAction<QueryGetBriicksListingsArgs>) => {
      state.isLoading = true;
      state.filters = action.payload.filters!;
      state.sort = action.payload.sort!;
      state.error = '';
    },
    getBriicksListingsRequestSuccess: (state, action: PayloadAction<BriicksListingPaginate>) => {
      state.isLoading = false;
      state.listings = action.payload;
    },
    getBriicksListingsRequestFaillure: (state, action: PayloadAction<string>) => {
      //state.listingsPage = initialState.listingsPage
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const ListingsActions = ListingsSlice.actions;
export default ListingsSlice;
