import tw from 'twin.macro';
import parse from 'html-react-parser';
import styled from 'styled-components';
import ArticleContainer from 'components/article/article-container';
import { Ads, BriicksRestrictionItem } from 'graphql/schema/__generated__/graphql-types';
import _ from 'lodash';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const RestrictionContent = styled.div`
  ${tw`mt-2`}
  h1 {
    ${tw`font-semibold py-2 mt-2 text-lg`}
  }
  a {
    ${tw`text-primary-400`}
  }
`;

const RestrictionDescription = styled.div`
  ${tw`mt-2`}
  h1 {
    ${tw`font-semibold py-2 mt-2 text-lg`}
  }
  a {
    ${tw`text-blue-500`}
  }
  ul {
    ${tw`list-disc list-inside`}
  }

  li {
    ${tw`mb-1 ml-4`}
  }
`;

const Restrinctions = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { restrictions } = props.ads.briicks;
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Restrictions</Headline>
      <Divider />
      {!restrictions || (restrictions && !restrictions.hasRestriction && _.isEmpty(restrictions.description)) ? (
        'no restrictions'
      ) : (
        <>
          {restrictions &&
            restrictions.restrictionItems &&
            restrictions.restrictionItems.map((restriction: BriicksRestrictionItem, index: number) => (
              <RestrictionContent key={index}>
                <h1>{restriction.title}</h1>
                {parse(restriction.description || '', { trim: true })}
                {restriction.url && (
                  <a href={restriction.url} target={'_blank'} rel="noreferrer">
                    {' '}
                    more info...
                  </a>
                )}
              </RestrictionContent>
            ))}

          <RestrictionDescription>{parse(restrictions.description || '', { trim: true })}</RestrictionDescription>
        </>
      )}
    </ArticleContainer>
  );
};

export default Restrinctions;
