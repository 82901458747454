//import Involvement from './involvement';
import { IFullAdsSection } from 'components/common/types';
import Ledger from './ledger';
import Restrinctions from './restrictions';
import SPVId from './spv-id';
import SectionContainer from 'components/article/section-container';
import { BriicksListing } from 'graphql/schema/__generated__/graphql-types';

export const elements: IFullAdsSection[] = [
  {
    name: 'SPV',
    element: SPVId,
  },
  /*{
        name: 'Involvement',
        element: Involvement
    },*/
  {
    name: 'Ledger',
    element: Ledger,
  },
  {
    name: 'Restrictions',
    element: Restrinctions,
  },
];

const Legal = (props: { ads: BriicksListing; viewPortObserver: (articleName: string, isVisible: boolean) => void }) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return <V ads={props.ads} id={e.name} key={index} viewPortObserver={props.viewPortObserver} />;
      })}
    </SectionContainer>
  );
};

export default Legal;
