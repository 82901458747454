import Agenda from './agenda';
import Briicks from './briicks';
//import Price from './price';
import PaymentMethodes from './payment-methodes';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from 'components/article/section-container';
import { BriicksListing } from 'graphql/schema/__generated__/graphql-types';
import InvestmentType from './investment-type';
import Metrics from './metrics';

export const elements: IFullAdsSection[] = [
  {
    name: 'Type',
    element: InvestmentType,
  },
  {
    name: 'Offering',
    element: Briicks,
  },
  {
    name: 'Metrics',
    element: Metrics,
  },
  {
    name: 'Calendar',
    element: Agenda,
  },
  /*{
        name: 'Price',
        element: Price
    },*/
  {
    name: 'Payment',
    element: PaymentMethodes,
  },
];

const Offering = (props: {
  ads: BriicksListing;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  const { ads } = props;
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return <V ads={ads} id={e.name} key={index} viewPortObserver={props.viewPortObserver} />;
      })}
    </SectionContainer>
  );
};

export default Offering;
