import tw from 'twin.macro';
const Container = tw.div`flex justify-center items-center mt-8`;
const Head = tw.h1`
font-bold text-secondary-700 text-2xl
`;

const AdTitle = () => {
  return (
    <Container>
      <Head>14881 Greenfield Rd, Detroit MI 48227</Head>
    </Container>
  );
};

export default AdTitle;
