import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BriicksListing, QueryGetBriicksListingDetailsArgs } from 'graphql/schema/__generated__/graphql-types';

export interface ListingDetailsState {
  listing: BriicksListing | null;
  isLoading: boolean;
  error: string;
}

const initialState: ListingDetailsState = {
  listing: null,
  isLoading: false,
  error: '',
};

export const ListingDetailsSlice = createSlice({
  name: 'listingDetails',
  initialState,
  reducers: {
    getBriicksListingDetailsRequest: (state, _action: PayloadAction<QueryGetBriicksListingDetailsArgs>) => {
      state.isLoading = true;
      state.error = '';
    },
    getBriicksListingDetailsSuccess: (state, action: PayloadAction<BriicksListing>) => {
      state.isLoading = false;
      state.listing = action.payload;
    },
    getBriicksListingDetailsFaillure: (state, action: PayloadAction<string>) => {
      //state.listingsPage = initialState.listingsPage
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const ListingDetailsActions = ListingDetailsSlice.actions;
export default ListingDetailsSlice;
