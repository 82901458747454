import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Documents = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const docs = [
    {
      name: 'Document 1',
      description: "C'est ceci et cela",
      type: 'pdf',
      size: '3 Mo',
    },
    {
      name: 'Document 2',
      description: "C'est ceci et cela",
      type: 'image',
      size: '500 Kb   ',
    },
  ];

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Documents</Headline>
      <Divider />

      <Table css={tw`min-w-86`} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Size</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {docs.map((e, index) => (
            <TableRow key={index}>
              <TableCell>{e.name}</TableCell>
              <TableCell>{e.description}</TableCell>
              <TableCell align="right">{e.type}</TableCell>
              <TableCell align="right">{e.size}</TableCell>
              <TableCell align="right">
                <Link href="http://www.google.com">Preview</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ArticleContainer>
  );
};

export default Documents;
