import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { AssetFeatures, Metadata } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';

const AssetFeatureComponent = (props: { assetFeatures: AssetFeatures[] }) => {
  const { assetFeatures } = props;
  //   const hasDescription = assetFeatures.reduce((acc: boolean, feature: AssetFeatures) => {
  //     return (feature.metadata && feature.metadata.length > 0) || acc;
  //   }, false);
  const desiredNames = ['interior rooms', 'exterior elements', 'equipment'];
  const restFeatures = assetFeatures.filter((feature) => !desiredNames.includes(feature.name.toLowerCase()));
  //const extractedElements = assetFeatures.filter((feature) => desiredNames.includes(feature.name));

  const extractedInteriorRooms = assetFeatures.find((feature) => feature.name.toLocaleLowerCase() === 'interior rooms');
  const extractedExteriorElements = assetFeatures.find((feature) => feature.name === 'exterior elements');
  const extractedEquipment = assetFeatures.find((feature) => feature.name === 'equipment');

  return (
    <>
      <Box sx={{ width: 850, alignSelf: 'center' }}>
        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Features
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="multiline table"
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Interior
                  </Typography>

                  {extractedInteriorRooms && extractedInteriorRooms.metadata ? (
                    extractedInteriorRooms.metadata?.map((e: Metadata, index: number) => (
                      <Typography variant="body2" key={index}>
                        {e.key}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">no data</Typography>
                  )}
                </TableCell>
                <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                  {extractedInteriorRooms && extractedInteriorRooms.metadata ? (
                    extractedInteriorRooms.metadata?.map((e: Metadata, index: number) => (
                      <Typography variant="body2" key={index}>
                        {e.value}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2"></Typography>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Outside
                  </Typography>

                  {extractedExteriorElements && extractedExteriorElements.metadata ? (
                    extractedExteriorElements.metadata?.map((e: Metadata, index: number) => (
                      <Typography variant="body2" key={index}>
                        {e.key}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">no data</Typography>
                  )}
                </TableCell>

                <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                  {extractedExteriorElements && extractedExteriorElements.metadata ? (
                    extractedExteriorElements.metadata?.map((e: Metadata, index: number) => (
                      <Typography variant="body2" key={index}>
                        {e.value}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2"></Typography>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    H.V.A.C (Heating, Ventilation, and Air Conditioning)
                  </Typography>

                  {extractedEquipment && extractedEquipment.metadata ? (
                    extractedEquipment.metadata?.map((e: Metadata, index: number) => (
                      <Typography variant="body2" key={index}>
                        {e.key}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2">no data</Typography>
                  )}
                </TableCell>

                <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                  {extractedEquipment && extractedEquipment.metadata ? (
                    extractedEquipment.metadata?.map((e: Metadata, index: number) => (
                      <Typography variant="body2" key={index}>
                        {e.value}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="body2"></Typography>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Utilities
                  </Typography>
                  <Typography variant="body2">no data</Typography>
                </TableCell>

                <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="body2"></Typography>
                </TableCell>
              </TableRow>

              {/******* Other feature start*/}
              {restFeatures.map((feature: AssetFeatures, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                      {feature.name}
                    </Typography>
                    {feature.metadata ? (
                      feature.metadata?.map((e: Metadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          {e.key}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>

                  <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                    {feature.metadata ? (
                      feature.metadata?.map((e: Metadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          {e.value}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2"></Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AssetFeatureComponent;
