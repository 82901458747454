import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/schema/__generated__/graphql-types';
import { Typography } from '@mui/material';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Liabilities = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
        <Headline>Liabilities</Headline>
        <Divider />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          no data
        </Typography>
      </ArticleContainer>
    </>
  );
};

export default Liabilities;
