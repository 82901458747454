import { put, takeLatest } from 'redux-saga/effects';
import clientGraphQL from 'graphql/client';
import {
  GetBriicksListingFiltersDocument,
  GetBriicksListingFiltersQuery,
} from 'graphql/schema/__generated__/graphql-types';
import { ListingFiltersActions } from 'redux/reducers/listing-filters';

function* getBriicksListingFiltersAsyncRequest() {
  try {
    const result: GetBriicksListingFiltersQuery = yield clientGraphQL.request(GetBriicksListingFiltersDocument);
    if (result.getBriicksListingFilters.__typename === 'GetBriicksListingFiltersSuccess')
      yield put(ListingFiltersActions.getBriicksListingFiltersRequestSuccess(result.getBriicksListingFilters.filters));
    else
      yield put(ListingFiltersActions.getBriicksListingFiltersRequestFaillure(result.getBriicksListingFilters.message));
  } catch (error) {
    yield put(ListingFiltersActions.getBriicksListingFiltersRequestFaillure('error'));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export const ListingFiltersSaga = [
  //reducer-name/reducer-function
  takeLatest('ListingFiltersSlice/getBriicksListingFiltersRequest', getBriicksListingFiltersAsyncRequest),
];
