import { Box } from '@mui/material';
import tw from 'twin.macro';
import Grid from '@mui/material/Unstable_Grid2';
import { Location } from 'graphql/schema/__generated__/graphql-types';

const Container = tw(Box)`
`;

const Item = tw.div`
flex flex-col
`;
const Headline = tw.h1`
text-primary-100 font-medium text-base 
`;
const ItemContent = tw.div`
flex
`;

const LineTitle = tw.h3`
w-24 mr-1 text-secondary-500 font-medium text-sm
`;

const LineValue = tw.span`
flex-grow text-secondary-500 text-sm text-right
`;

const Address = (props: { location: Location; country: string } & any) => {
  const { location, country } = props;
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <Item>
            {false && <Headline>Outside</Headline>}
            <ItemContent>
              <LineTitle>Number</LineTitle>
              <LineValue>{location.address.number}</LineValue>
            </ItemContent>
            <ItemContent>
              <LineTitle>Street Name</LineTitle>
              <LineValue>{location.address.street}</LineValue>
            </ItemContent>
            {location.address?.neighborhood && (
              <ItemContent>
                <LineTitle>Neighborhood</LineTitle>
                <LineValue>{location.address.neighborhood}</LineValue>
              </ItemContent>
            )}

            <ItemContent>
              <LineTitle>City</LineTitle>
              <LineValue>{location.address.city.name}</LineValue>
            </ItemContent>
            <ItemContent>
              <LineTitle>Zip Code</LineTitle>
              <LineValue>{location.address.zipCode}</LineValue>
            </ItemContent>
            <ItemContent>
              <LineTitle>State</LineTitle>
              <LineValue>{location.address.stateRegion}</LineValue>
            </ItemContent>
            <ItemContent>
              <LineTitle>Country</LineTitle>
              <LineValue>{country}</LineValue>
            </ItemContent>
          </Item>
        </Grid>
        {false && (
          <Grid xs={12} md={6}>
            {location.inside && location.inside.length > 0 && (
              <Item>
                <Headline>Inside</Headline>
                {location.inside.map((e: { key: string; value: string }, index: number) => (
                  <ItemContent key={index}>
                    <LineTitle>{e.key}</LineTitle>
                    <LineValue>{e.value}</LineValue>
                  </ItemContent>
                ))}
              </Item>
            )}

            {location.anex && location.anex.length > 0 && (
              <Item css={tw`mt-3 `}>
                <Headline>Anex</Headline>
                {location.anex.map((e: { key: string; value: string }, index: number) => (
                  <ItemContent key={index}>
                    <LineTitle>{e.key}</LineTitle>
                    <LineValue>{e.value}</LineValue>
                  </ItemContent>
                ))}
              </Item>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Address;
