import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { BriicksListing } from 'graphql/schema/__generated__/graphql-types';
import BriicksTable from './briicks';
import LimitsTable from './limits';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const BriicksOffering = (props: {
  ads: BriicksListing;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { ads } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>Offering</Headline>
        <Divider />

        <BriicksTable briicks={ads.briicks} />
        <LimitsTable briicks={ads.briicks} />
      </ArticleContainer>
    </>
  );
};

export default BriicksOffering;
