import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BriicksListingFilters } from 'graphql/schema/__generated__/graphql-types';

export interface ListingsFilterState {
  filters: BriicksListingFilters;
  isLoading: boolean;
  error: string;
}

const initialState: ListingsFilterState = {
  filters: {
    makers: [],
    countries: [],
    status: [],
  },
  isLoading: false,
  error: '',
};

export const ListingFiltersSlice = createSlice({
  name: 'ListingFiltersSlice',
  initialState,
  reducers: {
    getBriicksListingFiltersRequest: (state, _action: PayloadAction) => {
      state.isLoading = true;
      state.error = '';
    },
    getBriicksListingFiltersRequestSuccess: (state, action: PayloadAction<BriicksListingFilters>) => {
      state.isLoading = false;
      state.filters = action.payload;
      //console.log(action.payload)
    },
    getBriicksListingFiltersRequestFaillure: (state, action: PayloadAction<string>) => {
      //state.listingsPage = initialState.listingsPage
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const ListingFiltersActions = ListingFiltersSlice.actions;
export default ListingFiltersSlice;
