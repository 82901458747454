import tw from 'twin.macro';
import { Button, IconButton, Link } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import LaunchIcon from '@mui/icons-material/Launch';
import { FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton } from 'react-share';
import { Menu as MenuBase, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg';
import { ReactComponent as TelegramIcon } from 'images/telegram-icon.svg';
import { ReactComponent as LinkedInIcon } from 'images/linkedin-icon.svg';
import ShareIcon from '@mui/icons-material/Share';

const Container = tw.div`
col-span-2 pr-1 flex flex-row items-center
`;

const BaseButton = muiStyled(IconButton)({
  flex: 1,
  borderRadius: 3,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

const BaseTempButton = muiStyled(IconButton)({
  height: 50,
  width: 50,
  borderRadius: 3,
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

const SocialLink = styled.span`
  ${tw`cursor-pointer p-1 rounded-full bg-gray-900! text-gray-100 hover:bg-gray-700 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
//https://github.com/nygardk/react-share/issues/416
const Menu = styled(MenuBase)`
  .share-menu {
    ${tw`bg-gray-100 min-w-0`}
  }
  .social-link {
    ${tw`bg-gray-100`}
  }
  button {
    ${tw`m-0 p-0`}
    span {
      ${tw`inline-block cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700! transition duration-300`}
      svg {
        ${tw`w-4 h-4`}
      }
    }
  }
`;

const Share = muiStyled(BaseTempButton)({
  backgroundColor: '#667eea',
  borderColor: '#434190',
  marginRight: 10,
  '&:hover': {
    backgroundColor: '#434190',
    borderColor: '#667eea',
  },
  '&:active': {
    backgroundColor: '#434190',
    borderColor: '#667eea',
  },
  '&:focus': {
    //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const Visit = muiStyled(BaseButton)({
  backgroundColor: '#6415FF',
  borderColor: '#3c0d99',
  '&:hover': {
    backgroundColor: '#3c0d99',
    borderColor: '#6415FF',
  },
  '&:active': {
    backgroundColor: '#3c0d99',
    borderColor: '#6415FF',
  },
  '&:focus': {
    //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const SocialShare = (props: { textToshare: string; linkToShare: string }) => {
  const SocialMedia = [
    {
      name: 'Facebook',
      component: () => (
        <FacebookShareButton url={props.linkToShare} quote={props.textToshare}>
          <SocialLink>
            <FacebookIcon />
          </SocialLink>
        </FacebookShareButton>
      ),
    },
    {
      name: 'Twitter',
      component: () => (
        <TwitterShareButton url={props.linkToShare} title={props.textToshare}>
          <SocialLink>
            <TwitterIcon />
          </SocialLink>
        </TwitterShareButton>
      ),
    },
    {
      name: 'Telegram',
      component: () => (
        <TelegramShareButton url={props.linkToShare} title={props.textToshare}>
          <SocialLink>
            <TelegramIcon />
          </SocialLink>
        </TelegramShareButton>
      ),
    },
    {
      name: 'Linkedin',
      component: () => (
        <LinkedinShareButton url={props.linkToShare}>
          <SocialLink>
            <LinkedInIcon />
          </SocialLink>
        </LinkedinShareButton>
      ),
    },
  ];

  return (
    <Menu
      menuClassName="share-menu"
      menuButton={
        <Share>
          <ShareIcon sx={{ color: 'white', fontSize: 30 }} />
        </Share>
      }
      direction="right"
      align="end"
      position="auto"
      viewScroll="auto"
      arrow={true}
      offsetX={0}
      offsetY={0}
    >
      {SocialMedia.map(({ name, component }) => (
        <MenuItem className="social-link" key={name}>
          {component()}
        </MenuItem>
      ))}
    </Menu>
  );
};

export const VistBuy__ = (props: { id: string }) => {
  const { id } = props;
  return (
    <Container>
      {/* <SocialShare linkToShare='' textToshare='' /> */}

      <Link /* href={link} target='_open'  */
        href={`/market/ads-details/${id}`}
        target="_blank"
        sx={{ flex: 1, display: 'flex' }}
      >
        <Visit>
          <LaunchIcon sx={{ color: 'white', fontSize: 30 }} />
        </Visit>
      </Link>
    </Container>
  );
};

const VisitBuy = (props: { id: string }) => {
  return (
    <Button
      variant="contained"
      startIcon={<LaunchIcon sx={{ width: 30, height: 30 }} />}
      sx={{ textTransform: 'none', background: '#6415FF' }}
      href={`/market/ads-details/${props.id}`}
      target="_blank"
    >
      Visit
    </Button>
  );
};

export default VisitBuy;
