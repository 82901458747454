import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  AdsStatus,
  ArithmeticComparator,
  Arithmetic_Operator,
  BriicksListingsFiltersArgs,
  ProfitabilityMetricArgs,
  CountryIso2Codes,
  Listing_Source_Names,
  Profitability_Metrics,
} from 'graphql/schema/__generated__/graphql-types';
import { useDebounce } from 'helpers/use-debounce';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import _ from 'lodash';
import { useSelector } from 'redux/store';
import { useDispatch } from 'react-redux';
import { ListingFiltersActions } from 'redux/reducers/listing-filters';
//import { getName } from 'country-list';
import styled from 'styled-components';
//import { ReactComponent as SearchIcon } from 'feather-icons/dist/icons/search.svg';
import { initialState } from 'redux/reducers/listings';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
//import styled from 'styled-components';

/* const LT_50 = 'LT_50';
const LT_5000 = 'LT_5000';
const GT_5000 = 'GT_5000';

const LT_5 = 'LT_5';
const LT_15 = 'LT_15';
const GT_15 = 'GT_15'; */

const FilterHeader = tw.h2`text-lg font-semibold mb-1 mt-2`;
/* const InputContainer = tw.div`flex items-center mb-1 pl-2`
const Input = tw.input`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`
const Label = tw.label`ml-2 text-sm font-medium text-gray-700 lowercase` 

const InputNumberContainer = tw.div`flex items-center mb-1 pl-2`;*/
const NumberInput = styled.input`
  ${tw`inline p-1 w-full mr-2 bg-gray-100 rounded border border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
`;

interface BetweenInterval {
  min: number | null;
  max: number | null;
}

function isValidNumber(x: any) {
  return x !== null && typeof x === 'number' && !isNaN(x);
}

const ListingFilters = (props: {
  onChangeFilters?: (filters: BriicksListingsFiltersArgs) => void;
  onResetFilters?: () => void;
}) => {
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  const dispatch = useDispatch();
  const emptyFunction = () => {};
  const { onChangeFilters = emptyFunction, onResetFilters = emptyFunction } = props;
  //const [metricsFilter, setMetricsFilter] = useState<Profitability_Metrics>(Profitability_Metrics.Coc);
  const initialFilters = useSelector((state) => state.listings.filters);
  const outFilters = useSelector((state) => state.listingFilters.filters);
  const [filters, setFilters] = useState<BriicksListingsFiltersArgs>(initialFilters);
  const [shortCode, setShortCode] = useState(filters.shortCode);
  const [selectedMetrics, setSelectedMetrics] = useState<Profitability_Metrics>(Profitability_Metrics.Coc);
  const debouncedShortCode = useDebounce(shortCode, 500); // Debounce the search term by 500ms
  const [investInterval, setInvestInterval] = useState<BetweenInterval>({
    min: null,
    max: null,
  });
  const [metricInterval, setMetricInterval] = useState<BetweenInterval>({
    min: null,
    max: null,
  });

  const PROFITABILITY_METRICS = filters.profitabilityMetrics || [];

  const onChangeAdsId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const shortCode = e.currentTarget.value;
    setShortCode(shortCode);
  };

  useEffect(() => {
    setFilters({ ...filters, shortCode: debouncedShortCode });
  }, [debouncedShortCode]);

  useEffect(() => {
    if (!_.isEqual(filters, initialFilters)) onChangeFilters(filters);
  }, [filters]);

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let countries: CountryIso2Codes[] = [];
    const countryCode = event.target.name as CountryIso2Codes;
    if (event.target.checked) {
      countries = [...filters.countries, countryCode];
    } else {
      countries = filters.countries.filter((country) => country !== countryCode);
    }
    setFilters({ ...filters, countries });
  };

  const handleMakerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let makers: Listing_Source_Names[] = [];
    const maker = event.target.name as Listing_Source_Names;
    if (event.target.checked) {
      makers = [...filters.makers, maker];
    } else {
      makers = filters.makers.filter((theMaker) => theMaker !== maker);
    }
    setFilters({ ...filters, makers });
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let status: AdsStatus[] = [];
    const one_status = event.target.name as AdsStatus;
    if (event.target.checked) {
      status = [...filters.status, one_status];
    } else {
      status = filters.status.filter((the_status) => the_status !== one_status);
    }
    setFilters({ ...filters, status });
  };

  /* const handleOptionsMinIncomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncomeInterval({
      max: null,
      min: null,
    });
    if (event.target.checked) {
      const option = event.target.name;
      let value: ArithmeticComparator | null = null;

      if (option === LT_5) {
        value = {
          numbers: [5],
          operator: Arithmetic_Operator.Lt,
        };
      } else if (option === LT_15) {
        value = {
          numbers: [15],
          operator: Arithmetic_Operator.Lt,
        };
      } else if (option === GT_15) {
        value = {
          numbers: [15],
          operator: Arithmetic_Operator.Gt,
        };
      }

      if (value) setFilters({ ...filters, profitability: { field: metricsFilter, value: value } });
      //if (value) setFilters({ ...filters, profitability: { ...filters.profitability, value: value} })
      //if (value) setFilters({ ...filters, income: value });
    } else {
      //setFilters({ ...filters, income: null });
      //setFilters({ ...filters, profitability: { ...filters.profitability, value: null} })
      setFilters({ ...filters, profitability: { field: metricsFilter, value: null } });
    }
  };

   const handleOptionsMinInvestChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvestInterval({
      max: null,
      min: null,
    });
    if (event.target.checked) {
      const option = event.target.name;
      let value: ArithmeticComparator | null = null;

      if (option === LT_50) {
        value = {
          numbers: [50],
          operator: Arithmetic_Operator.Lt,
        };
      } else if (option === LT_5000) {
        value = {
          numbers: [5000],
          operator: Arithmetic_Operator.Lt,
        };
      } else if (option === GT_5000) {
        value = {
          numbers: [5000],
          operator: Arithmetic_Operator.Gt,
        };
      }

      if (value) setFilters({ ...filters, invest: value });
    } else {
      setFilters({ ...filters, invest: null });
    }
  }; */

  const handleOnApplyInvestInterval = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the default button click behavior (optional)
    event.preventDefault();

    if (!isValidNumber(investInterval.min) && !isValidNumber(investInterval.max)) return;

    let invest: ArithmeticComparator | null = null;

    if (isValidNumber(investInterval.min) && !isValidNumber(investInterval.max)) {
      // Do something when button is clicked
      invest = {
        numbers: [investInterval.min!],
        operator: Arithmetic_Operator.Gt,
      };
    } else if (!isValidNumber(investInterval.min) && isValidNumber(investInterval.max)) {
      invest = {
        numbers: [investInterval.max!],
        operator: Arithmetic_Operator.Lt,
      };
    } else {
      invest = {
        numbers: [investInterval.min!, investInterval.max!],
        operator: Arithmetic_Operator.Between,
      };
    }

    setFilters({ ...filters, invest });
  };

  const handleProfitabilityFieldChange = (event: SelectChangeEvent<Profitability_Metrics>) => {
    const value = event.target.value as Profitability_Metrics; // no need for typecasting her
    /*setMetricsFilter(value);
    if (value !== filters.profitability?.field && filters.profitability?.value) {
      setFilters({ ...filters, profitability: { ...filters.profitability, field: value } });
    }*/
    setSelectedMetrics(value);
  };

  const handleOptionsInvestIntervaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numValue = value === '' ? null : Number(value);

    if (event.target.name === 'min-invest') {
      setInvestInterval({
        ...investInterval,
        min: numValue,
        // min: parseFloat(event.target.value),
      });
    } else if (event.target.name === 'max-invest') {
      setInvestInterval({
        ...investInterval,
        //max: parseFloat(event.target.value),
        max: numValue,
      });
    }
  };

  const handleOnAddMetricsInterval = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Prevent the default button click behavior (optional)
    event.preventDefault();

    if (!isValidNumber(metricInterval.min) && !isValidNumber(metricInterval.max)) return;

    let metricValue: ArithmeticComparator | null = null;

    if (isValidNumber(metricInterval.min) && !isValidNumber(metricInterval.max)) {
      metricValue = {
        numbers: [metricInterval.min!],
        operator: Arithmetic_Operator.Gt,
      };
    } else if (!isValidNumber(metricInterval.min) && isValidNumber(metricInterval.max)) {
      metricValue = {
        numbers: [metricInterval.max!],
        operator: Arithmetic_Operator.Lt,
      };
    } else {
      metricValue = {
        numbers: [metricInterval.min!, metricInterval.max!],
        operator: Arithmetic_Operator.Between,
      };
    }

    const metrics = filters.profitabilityMetrics || [];
    setFilters({ ...filters, profitabilityMetrics: [...metrics, { field: selectedMetrics, value: metricValue }] });
    setMetricInterval({
      min: null,
      max: null,
    });
    //etFilters({ ...filters, income });
  };

  const handleOptionsIncomeIntervaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numValue = value === '' ? null : Number(value);

    if (event.target.name === 'min-income') {
      setMetricInterval({
        ...metricInterval,
        //min: parseFloat(event.target.value),
        min: numValue,
      });
    } else if (event.target.name === 'max-income') {
      setMetricInterval({
        ...metricInterval,
        //max: parseFloat(event.target.value),
        max: numValue,
      });
    }
  };

  useEffect(() => {
    dispatch(ListingFiltersActions.getBriicksListingFiltersRequest());
  }, [dispatch]);

  const handleResetFilters = () => {
    setMetricInterval({
      min: null,
      max: null,
    });
    setInvestInterval({
      min: null,
      max: null,
    });
    setFilters({ ...initialState.filters });
    onResetFilters();
  };

  return (
    <>
      {false && (
        <>
          <FilterHeader>Ads ID</FilterHeader>
          <TextField id="ads-id" variant="outlined" size="small" onChange={onChangeAdsId} />
        </>
      )}

      {outFilters.status.length > 0 && (
        <>
          <FilterHeader>Status</FilterHeader>
          {outFilters.status.map((status: AdsStatus, index: number) => (
            <Box display="block">
              <FormControlLabel
                sx={{ height: 30 }}
                key={index}
                control={
                  <Checkbox checked={filters.status.includes(status)} onChange={handleStatusChange} name={status} />
                }
                label={
                  <Typography variant="body2">
                    {status === AdsStatus.NewLaunch ? 'LAUNCHING' : status.replaceAll('_', ' ')}
                  </Typography>
                }
              />
            </Box>
          ))}
        </>
      )}

      <FilterHeader>Briick price</FilterHeader>
      {/* <Box display="block">
        <FormControlLabel
          sx={{ height: 30 }}
          control={
            <Checkbox
              checked={
                filters.invest?.operator === Arithmetic_Operator.Lt &&
                filters.invest?.numbers.length === 1 &&
                filters.invest?.numbers[0] === 50
              }
              onChange={handleOptionsMinInvestChange}
              name={LT_50}
            />
          }
          label={<Typography variant="body2">{`< 50 $`}</Typography>}
        />
      </Box>

      <Box display="block">
        <FormControlLabel
          sx={{ height: 30 }}
          control={
            <Checkbox
              checked={
                filters.invest?.operator === Arithmetic_Operator.Lt &&
                filters.invest?.numbers.length === 1 &&
                filters.invest?.numbers[0] === 5000
              }
              onChange={handleOptionsMinInvestChange}
              name={LT_5000}
            />
          }
          label={<Typography variant="body2">{`< 5000 $`}</Typography>}
        />
      </Box>

      <Box display="block">
        <FormControlLabel
          sx={{ height: 30 }}
          control={
            <Checkbox
              checked={
                filters.invest?.operator === Arithmetic_Operator.Gt &&
                filters.invest?.numbers.length === 1 &&
                filters.invest?.numbers[0] === 5000
              }
              onChange={handleOptionsMinInvestChange}
              name={GT_5000}
            />
          }
          label={<Typography variant="body2">{`> 5000 $`}</Typography>}
        />
      </Box> */}

      {/* <InputNumberContainer>
      </InputNumberContainer> */}
      <Stack spacing={1}>
        <NumberInput
          name="min-invest"
          id="minInvest-min"
          type="number"
          placeholder="Min"
          onChange={handleOptionsInvestIntervaleChange}
          //value={investInterval.min || ''}
          value={investInterval.min === null || investInterval.min === undefined ? '' : investInterval.min}
        />
        <NumberInput
          name="max-invest"
          id="maxInvest-max"
          type="number"
          placeholder="Max"
          onChange={handleOptionsInvestIntervaleChange}
          //value={investInterval.max || ''}
          value={investInterval.max === null || investInterval.max === undefined ? '' : investInterval.max}
        />

        <Button
          sx={tw`bg-primary-500`}
          onClick={handleOnApplyInvestInterval}
          disabled={!isValidNumber(investInterval.min) && !isValidNumber(investInterval.max)}
          variant="contained"
          startIcon={<SearchIcon />}
        >
          Search
        </Button>

        {/* <PrimaryButton
          onClick={handleOnApplyInvestInterval}
          disabled={!isValidNumber(investInterval.min) || !isValidNumber(investInterval.max)}
        >
          <SearchIcon />
          Search
        </PrimaryButton> */}
      </Stack>

      <FilterHeader css={tw`mt-4`}>Metrics</FilterHeader>
      <FormControl variant="outlined" size="small" sx={{ ...tw`mt-2`, ...{ marginBottom: 1 } }} fullWidth>
        <Select labelId="sort-by-label" value={selectedMetrics} onChange={handleProfitabilityFieldChange}>
          <MenuItem
            disabled={PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Coc)}
            value={Profitability_Metrics.Coc}
          >
            Cash on cash
          </MenuItem>
          <MenuItem
            disabled={PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Appreciation)}
            value={Profitability_Metrics.Appreciation}
          >
            Appreciation
          </MenuItem>
          <MenuItem
            disabled={PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.ROI)}
            value={Profitability_Metrics.ROI}
          >
            R.O.I
          </MenuItem>
        </Select>
      </FormControl>

      {PROFITABILITY_METRICS.length > 0 && (
        <Stack spacing={1} css={tw`mb-2`}>
          {PROFITABILITY_METRICS.map((metric: ProfitabilityMetricArgs, index: number) => (
            <Chip
              key={index}
              label={((m: ProfitabilityMetricArgs): string => {
                let title = '';
                let symbole = '';
                if (m.field === Profitability_Metrics.Coc) {
                  title = 'C.O.C';
                  symbole = '%';
                } else if (m.field === Profitability_Metrics.ROI) {
                  title = 'R.O.I';
                  symbole = '%';
                } else if (m.field === Profitability_Metrics.Appreciation) {
                  title = 'Appr.';
                  symbole = '%';
                }

                let suffix = '';
                if (m.value?.operator === Arithmetic_Operator.Lt) suffix = `< ${m.value?.numbers[0]}${symbole}`;
                else if (m.value?.operator === Arithmetic_Operator.Gt) suffix = `> ${m.value?.numbers[0]}${symbole}`;
                else suffix = `${m.value?.numbers[0]}${symbole} to ${m.value?.numbers[1]}${symbole}`;
                return `${title} ${suffix}`;
                //return `${title} ${m.value?.numbers[0]}${symbole} to ${m.value?.numbers[1]}${symbole}`;
              })(metric)}
              onDelete={() => {
                setFilters({
                  ...filters,
                  profitabilityMetrics: PROFITABILITY_METRICS.filter((obj) => obj.field !== metric.field),
                });
              }}
            />
          ))}
        </Stack>
      )}

      {/*  <Box display="block">
        <FormControlLabel
          sx={{ height: 30 }}
          control={
            <Checkbox
              /* checked={
                filters.income?.operator === Arithmetic_Operator.Lt &&
                filters.income?.numbers.length === 1 &&
                filters.income?.numbers[0] === 5
              } *
              checked={
                filters.profitability?.value?.operator === Arithmetic_Operator.Lt &&
                filters.profitability?.value?.numbers.length === 1 &&
                filters.profitability?.value?.numbers[0] === 5
              }
              onChange={handleOptionsMinIncomeChange}
              name={LT_5}
            />
          }
          label={<Typography variant="body2">{`< 5 %`}</Typography>}
        />
      </Box> 
      <Box display="block">
        <FormControlLabel
          sx={{ height: 30 }}
          control={
            <Checkbox
              /* checked={
                filters.income?.operator === Arithmetic_Operator.Lt &&
                filters.income?.numbers.length === 1 &&
                filters.income?.numbers[0] === 15
              } *
              checked={
                filters.profitability?.value?.operator === Arithmetic_Operator.Lt &&
                filters.profitability?.value?.numbers.length === 1 &&
                filters.profitability?.value?.numbers[0] === 15
              }
              onChange={handleOptionsMinIncomeChange}
              name={LT_15}
            />
          }
          label={<Typography variant="body2">{`< 15 %`}</Typography>}
        />
      </Box>

      <Box display="block">
        <FormControlLabel
          sx={{ height: 30 }}
          control={
            <Checkbox
              /* checked={
                filters.income?.operator === Arithmetic_Operator.Gt &&
                filters.income?.numbers.length === 1 &&
                filters.income?.numbers[0] === 15
              } *
              checked={
                filters.profitability?.value?.operator === Arithmetic_Operator.Gt &&
                filters.profitability?.value?.numbers.length === 1 &&
                filters.profitability?.value?.numbers[0] === 15
              }
              onChange={handleOptionsMinIncomeChange}
              name={GT_15}
            />
          }
          label={<Typography variant="body2">{`> 15 %`}</Typography>}
        />
      </Box>*/}

      {/*  <InputNumberContainer>
      </InputNumberContainer> */}
      <Stack spacing={1}>
        <NumberInput
          name="min-income"
          id="minIncome-min"
          type="number"
          placeholder="Min"
          onChange={handleOptionsIncomeIntervaleChange}
          disabled={
            PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Coc) &&
            PROFITABILITY_METRICS.some(
              (obj) =>
                obj.field === Profitability_Metrics.ROI &&
                PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Appreciation),
            )
          }
          // value={incomeInterval.min || ''}
          value={metricInterval.min === null || metricInterval.min === undefined ? '' : metricInterval.min}
        />
        <NumberInput
          name="max-income"
          id="maxIncome-max"
          type="number"
          placeholder="Max"
          onChange={handleOptionsIncomeIntervaleChange}
          disabled={
            PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Coc) &&
            PROFITABILITY_METRICS.some(
              (obj) =>
                obj.field === Profitability_Metrics.ROI &&
                PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Appreciation),
            )
          }
          //value={incomeInterval.max || ''}
          value={metricInterval.max === null || metricInterval.max === undefined ? '' : metricInterval.max}
        />

        <Button
          sx={tw`bg-primary-500`}
          onClick={handleOnAddMetricsInterval}
          disabled={
            (!isValidNumber(metricInterval.min) && !isValidNumber(metricInterval.max)) ||
            (PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Coc) &&
              PROFITABILITY_METRICS.some(
                (obj) =>
                  obj.field === Profitability_Metrics.ROI &&
                  PROFITABILITY_METRICS.some((obj) => obj.field === Profitability_Metrics.Appreciation),
              ))
          }
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </Stack>

      {outFilters.countries.length > 0 && (
        <>
          <FilterHeader>Countries</FilterHeader>
          {outFilters.countries.map((country: CountryIso2Codes, index: number) => (
            <FormControlLabel
              sx={{ height: 30 }}
              key={index}
              control={
                <Checkbox checked={filters.countries.includes(country)} onChange={handleCountryChange} name={country} />
              }
              label={
                <Typography variant="body2">
                  {
                    regionNames.of(country)
                    //getName(country)
                  }
                </Typography>
              }
            />
          ))}
        </>
      )}

      {outFilters.makers.length > 0 && (
        <>
          <FilterHeader>Briickmarker</FilterHeader>
          {outFilters.makers.map((maker: Listing_Source_Names, index: number) => (
            <FormControlLabel
              sx={{ height: 30 }}
              key={index}
              control={<Checkbox checked={filters.makers.includes(maker)} onChange={handleMakerChange} name={maker} />}
              label={<Typography variant="body2">{maker}</Typography>}
            />
          ))}
        </>
      )}

      <Button
        fullWidth
        size="small"
        sx={{ marginTop: 1 }}
        variant="contained"
        color="info"
        onClick={handleResetFilters}
      >
        Reset filters
      </Button>
    </>
  );
};

export default ListingFilters;
