import tw from 'twin.macro';
import { SourcesIcon } from 'components/misc/SourcesIcon';
import { Listing_Source_Names } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`flex justify-end items-center`;
const Header = tw.div`
font-medium text-gray-600 pr-2
`;
const BriicksMakerLogo = tw.img`
w-auto h-6
`;

const ListedBy = (props: { source: Listing_Source_Names }) => {
  return (
    <Container>
      <Header>Listed by</Header>
      <BriicksMakerLogo src={SourcesIcon(props.source)} />
    </Container>
  );
};

export default ListedBy;
