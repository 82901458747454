import { Link, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { BriicksListing, Listing_Source_Names } from 'graphql/schema/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Documents = (props: {
  ads: BriicksListing;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { ads } = props;
  let docs: { name: string; type: string; source: string }[] = [];

  if ([Listing_Source_Names.Lofty].includes(ads.source) && ads.spv && ads.spv.documents.otherDocuments) {
    docs = ads.spv.documents.otherDocuments.map((e) => ({
      name: e.description,
      type: e.document.mimeType,
      source: e.document.source,
    }));
  }

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Documents</Headline>
      <Divider />

      {docs.length > 0 ? (
        <Table css={tw`min-w-86`} size="small">
          <TableBody>
            {docs.map((e, index) => (
              <TableRow key={index}>
                <TableCell>{e.name}</TableCell>
                {/* <TableCell>{e.type === 'application/pdf' ? "pdf" : e.type}</TableCell> */}
                <TableCell align="right">
                  <Link href={`${e.source}`} target="_blank">
                    Click to view doccument
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          no doccuments
        </Typography>
      )}
    </ArticleContainer>
  );
};

export default Documents;
