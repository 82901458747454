import Agenda from './agenda';
import Briicks from './briicks';
import Price from './price';
import PaymentMethodes from './payment-methodes';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from 'components/article/section-container';

export const elements: IFullAdsSection[] = [
  {
    name: 'Agenda',
    element: Agenda,
  },
  {
    name: 'Briicks',
    element: Briicks,
  },
  {
    name: 'Price',
    element: Price,
  },
  {
    name: 'Payment',
    element: PaymentMethodes,
  },
];

const Offering = (props: { setVisibleArticle: (articleName: string) => void }) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return (
          <V
            id={e.name}
            key={index}
            viewPortObserver={(isVisible: boolean) => {
              if (isVisible) props.setVisibleArticle(elements[index].name);
            }}
          />
        );
      })}
    </SectionContainer>
  );
};

export default Offering;
