import { Financial, Rental } from 'graphql/schema/__generated__/graphql-types';

export function calcaulteRentalMetrics(
  financial: Financial,
  rental: Rental,
): {
  Cash_On_Cash: number;
  Return_On_Investment?: number;
} {
  const rentalAppreciationRate = rental.profitability.assetAppreciation;
  const totalRentalIncome = rental.leaseDetails.annualGrossRent;
  const Total_Investment = financial.aquisitionCost.totalCost + financial.assetValue.totalAssetValue;
  //console.log('Total_Investment', Total_Investment)
  const Cash_Flow = totalRentalIncome - financial.holdingCost.totalCost;

  const Cash_On_Cash = (Cash_Flow * 100) / Total_Investment;

  const Appreciation_Value = rentalAppreciationRate
    ? (financial.assetValue.totalAssetValue * rentalAppreciationRate) / 100
    : undefined;

  const Return_On_Investment = Appreciation_Value
    ? ((Cash_Flow + Appreciation_Value) / Total_Investment) * 100
    : undefined;

  return {
    Cash_On_Cash,
    Return_On_Investment,
  };
}
