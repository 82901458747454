import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Briicks } from 'graphql/schema/__generated__/graphql-types';
import NumberFormat from 'react-number-format';
import tw from 'twin.macro';

const LimitsTable = (props: { briicks: Briicks }) => {
  const { briicks } = props;

  const getPercentage = (portion: number, total: number) => (portion * 100) / total;

  return (
    <>
      <Box sx={{ width: 850, alignSelf: 'center' }}>
        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Limits
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="multiline table"
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom', minWidth: 140 }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Purchase
                  </Typography>

                  <Typography variant="body2">Minimum</Typography>

                  <Typography variant="body2">Maximum</Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Briicks
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.minBriicksPurchase}
                  </Typography>
                  <Typography variant="body2" align="right">
                    {briicks.maxBriicksPurchase}
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Amount
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={briicks.minBriicksPurchase * briicks.price}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={briicks.maxBriicksPurchase * briicks.price}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    %
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={getPercentage(briicks.minBriicksPurchase, briicks.distribution.sharesOnSale)}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={getPercentage(briicks.maxBriicksPurchase, briicks.distribution.sharesOnSale)}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Takers
                  </Typography>

                  <Typography variant="body2">Minimum</Typography>
                  <Typography variant="body2">Maximum</Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Number
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.minTakers}
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.maxTakers}
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Amount
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={(briicks.distribution.sharesOnSale / briicks.minTakers) * briicks.price}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={(briicks.distribution.sharesOnSale / briicks.maxTakers) * briicks.price}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    %
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={
                        ((briicks.distribution.sharesOnSale / briicks.minTakers) * 100) /
                        briicks.distribution.sharesOnSale
                      }
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={
                        ((briicks.distribution.sharesOnSale / briicks.maxTakers) * 100) /
                        briicks.distribution.sharesOnSale
                      }
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default LimitsTable;
