import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Briicks, BriicksRestrictionItem } from 'graphql/schema/__generated__/graphql-types';
import NumberFormat from 'react-number-format';
import tw from 'twin.macro';

const BriicksTable = (props: { briicks: Briicks }) => {
  const { briicks } = props;
  const getPercentage = (portion: number, total: number) => (portion * 100) / total;
  return (
    <>
      <Box sx={{ width: 850, alignSelf: 'center' }}>
        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Briicks
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="multiline table"
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom', minWidth: 140 }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Value
                  </Typography>

                  <Typography variant="body2">Total offering</Typography>

                  <Typography variant="body2">Unit</Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Briicks
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.distribution.sharesOnSale}
                  </Typography>
                  <Typography variant="body2" align="right">
                    1
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Amount
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={briicks.distribution.sharesOnSale * briicks.price}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={briicks.price}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    %
                  </Typography>

                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={100}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                  <Typography variant="body2" align="right">
                    <NumberFormat
                      value={getPercentage(briicks.price, briicks.distribution.sharesOnSale * briicks.price)}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                    Allocation
                  </Typography>

                  <Typography variant="body2">Unallocated</Typography>

                  {briicks.restrictions &&
                    briicks.restrictions.hasRestriction &&
                    briicks.restrictions.restrictionItems?.map((item: BriicksRestrictionItem, index: number) => (
                      <Typography key={index} variant="body2">
                        {item.title}
                      </Typography>
                    ))}
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Briicks
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.distribution.unallocated === 0 ? '-' : briicks.distribution.unallocated}
                  </Typography>

                  {briicks.restrictions &&
                    briicks.restrictions.hasRestriction &&
                    briicks.restrictions.restrictionItems?.map((item: BriicksRestrictionItem, index: number) => (
                      <Typography key={index} variant="body2" align="right">
                        {item.quantity}
                      </Typography>
                    ))}
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    Amount
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.distribution.unallocated === 0 ? (
                      '-'
                    ) : (
                      <NumberFormat
                        value={briicks.distribution.unallocated * briicks.price}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        prefix={'$ '}
                        renderText={(value: string) => <span>{value}</span>}
                      />
                    )}
                  </Typography>

                  {briicks.restrictions &&
                    briicks.restrictions.hasRestriction &&
                    briicks.restrictions.restrictionItems?.map((item: BriicksRestrictionItem, index: number) => (
                      <Typography key={index} variant="body2" align="right">
                        <NumberFormat
                          value={item.quantity * briicks.price}
                          decimalScale={2}
                          fixedDecimalScale
                          displayType={'text'}
                          thousandSeparator={' '}
                          prefix={'$ '}
                          renderText={(value: string) => <span>{value}</span>}
                        />
                      </Typography>
                    ))}
                </TableCell>

                <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                  <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`} align="right">
                    %
                  </Typography>

                  <Typography variant="body2" align="right">
                    {briicks.distribution.unallocated === 0 ? (
                      '-'
                    ) : (
                      <NumberFormat
                        value={getPercentage(briicks.distribution.unallocated, briicks.distribution.sharesOnSale)}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        suffix={' %'}
                        renderText={(value: string) => <span>{value}</span>}
                      />
                    )}
                  </Typography>

                  {briicks.restrictions &&
                    briicks.restrictions.hasRestriction &&
                    briicks.restrictions.restrictionItems?.map((item: BriicksRestrictionItem, index: number) => (
                      <Typography key={index} variant="body2" align="right">
                        <NumberFormat
                          value={getPercentage(item.quantity, briicks.distribution.sharesOnSale)}
                          decimalScale={2}
                          fixedDecimalScale
                          displayType={'text'}
                          thousandSeparator={' '}
                          suffix={' %'}
                          renderText={(value: string) => <span>{value}</span>}
                        />
                      </Typography>
                    ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default BriicksTable;
