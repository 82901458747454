import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/schema/__generated__/graphql-types';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Ledger = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { ledger } = props.ads;
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Ledger</Headline>
      <Divider />

      <Box sx={{ width: 850, marginTop: 2, alignSelf: 'center' }}>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="simple table"
          >
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Type
                </TableCell>
                <TableCell align="right">{ledger.type}</TableCell>
              </TableRow>

              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Network
                </TableCell>
                <TableCell align="right">{ledger.network}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ArticleContainer>
  );
};

export default Ledger;
