import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Asset } from 'graphql/schema/__generated__/graphql-types';
import AssetType from './asset-type';
import AssetFeatureComponent from './asset-feature';
import AssetConditionComponent from './asset-condition';
import AmenitiesComponent from './Amenities';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Features = (props: { asset: Asset; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { assetType, assetCondition, assetFeatures } = props.asset;

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={50}>
      <Headline>Details</Headline>
      <Divider />

      <AssetType assetType={assetType} />

      <AssetFeatureComponent assetFeatures={assetFeatures} />

      <AssetConditionComponent assetCondition={assetCondition} />

      <AmenitiesComponent />
    </ArticleContainer>
  );
};

export default Features;
