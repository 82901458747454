import React from 'react';
import tw from 'twin.macro';
//import CardMedia from '@mui/material/CardMedia';
import { Box, Link, Tab, Tabs } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import VillaIcon from '@mui/icons-material/Villa';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import styled from '@emotion/styled';
import Pictures from './pictures';
import Plan from './plan';
import ArticleContainer from 'components/article/article-container';
import { Asset } from 'graphql/schema/__generated__/graphql-types';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
/*
import View3D from './view-3d';
import View360 from './view-360';
import Virtual from './virtual';
*/
const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
mb-8
`;

const TabPanel = styled(TabPanelBase)`
  ${tw`mt-1`}
`;

const View = (props: { asset: Asset; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { asset } = props;
  //const featured = `https://ik.imagekit.io/brma/tr:w-${size * cols + c},h-${size * rows + c}/${image}`

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>View</Headline>
      <Divider />
      {/* <CardMedia
        component="img"
        image={asset.pictures.featured}
        //alt="green iguana"
      /> */}

      <Tabs value={value} onChange={handleChange} centered css={tw`mt-4`}>
        <Tab sx={tw`text-blue-500`} icon={<CollectionsIcon />} label="Pictures" />
        <Tab
          sx={tw`text-blue-500`}
          icon={<VrpanoIcon />}
          label="360"
          disabled={!(asset.view_3d && asset.view_3d.length > 0)}
        />
        <Tab sx={tw`text-blue-500`} icon={<CameraswitchIcon />} label="Virtual" disabled />
        <Tab sx={tw`text-blue-500`} icon={<ViewInArIcon />} label="3D" disabled />
        <Tab
          sx={tw`text-blue-500`}
          icon={<VillaIcon />}
          label="Plan"
          disabled={!(asset.plans && asset.plans.length > 0)}
        />
        {/*
                    asset.plans && asset.plans.length > 0 &&
                    <Tab icon={<VillaIcon />} label='Plan' />
                }
                {
                    asset.view_3d && asset.view_3d.length > 0 &&
                    <Tab icon={<VrpanoIcon />} label='360' />
                    */}
      </Tabs>

      <TabPanel index={0} value={value}>
        <Pictures galleries={asset.pictures.galleries} />
      </TabPanel>
      {asset.plans && asset.plans.length > 0 && (
        <TabPanel index={1} value={value}>
          <Plan plans={asset.plans} />
        </TabPanel>
      )}

      {asset.view_3d && asset.view_3d.length > 0 && (
        <TabPanel index={1} value={value}>
          {asset.view_3d.map((v, index: number) => (
            <Box key={index}>
              <Link href={v.value} target="_blank">
                {v.key}
              </Link>
            </Box>
          ))}
        </TabPanel>
      )}

      {/*
            }
            
            <TabPanel index={2} value={value}>
                <Virtual />
            </TabPanel>
            <TabPanel index={3} value={value}>
                <View3D />
            </TabPanel>


            {
                */}
    </ArticleContainer>
  );
};

export default View;
