import tw from 'twin.macro';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@mui/lab';
import ArticleContainer from 'components/article/article-container';
import { Ads, OtherDate } from 'graphql/schema/__generated__/graphql-types';
import _ from 'lodash';
import moment from 'moment';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Content = tw.div`
flex justify-center pt-8 
`;

const Agenda = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const calendar = props.ads.calendar;
  let otherDate: OtherDate[] = [];
  if (calendar.otherDates) {
    otherDate = calendar.otherDates.reduce((acc: OtherDate[], el: OtherDate) => {
      return [...acc, el];
    }, []);
  }
  const events = _.sortBy(
    [
      ...otherDate,
      { title: 'Start Sale', date: calendar.salesStart },
      { title: 'Advertise', date: calendar.advertise },
    ],
    ['date'],
  ).reverse();

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
      <Headline css={tw`flex flex-row justify-between items-end `}>
        Calendar
        <Typography sx={tw`text-green-500 text-base`}>TimeZone: UTC</Typography>
      </Headline>
      <Divider />

      <Typography sx={tw`text-blue-500 text-base mt-2`}>
        <InfoIcon /> Sign In to get personalize TimeZone
      </Typography>

      <Content>
        <Timeline>
          {events.reverse().map((el: OtherDate, index: number) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent>{moment.utc(el.date).format('LL [-] HH:mm [UTC]')}</TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined" //sx={{ borderColor: '#38b2ac' }}
                />
                {index < events.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent color={'#38b2ac'} sx={tw`capitalize`}>
                {el.title}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Content>
    </ArticleContainer>
  );
};

export default Agenda;
