import tw from 'twin.macro';

const Span = tw.span`
w-full bg-purple-700 overflow-hidden rounded-md text-center mt-1 
p-1 text-white uppercase block whitespace-nowrap truncate
`;

//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const TakersBadge = (props: { children: React.ReactNode }) => {
  return <Span>{props.children}</Span>;
};

export default TakersBadge;
