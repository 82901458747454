import React from 'react';
import tw from 'twin.macro';
import CardMedia from '@mui/material/CardMedia';
import { Tab, Tabs } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import VillaIcon from '@mui/icons-material/Villa';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import styled from '@emotion/styled';
import Pictures from './pictures';
import View360 from './view-360';
import Virtual from './virtual';
import View3D from './view-3d';
import Plan from './plan';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
mb-8
`;

const TabPanel = styled(TabPanelBase)`
  ${tw`mt-1`}
`;

const View = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>View</Headline>
      <Divider />
      <CardMedia
        component="img"
        image="https://images.unsplash.com/photo-1613977257592-4871e5fcd7c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=800&q=80"
        //alt="green iguana"
      />

      <Tabs value={value} onChange={handleChange} centered css={tw`mt-4`}>
        <Tab icon={<CollectionsIcon />} label="Pictures" />
        <Tab icon={<VrpanoIcon />} label="360" />
        <Tab icon={<CameraswitchIcon />} label="Virtual" />
        <Tab icon={<ViewInArIcon />} label="3D" />
        <Tab icon={<VillaIcon />} label="Plan" />
      </Tabs>

      <TabPanel index={0} value={value}>
        <Pictures />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <View360 />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <Virtual />
      </TabPanel>
      <TabPanel index={3} value={value}>
        <View3D />
      </TabPanel>
      <TabPanel index={4} value={value}>
        <Plan />
      </TabPanel>
    </ArticleContainer>
  );
};

export default View;
