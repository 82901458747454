import tw from 'twin.macro';
import AdId from '../asset/ad-id';
import AdStatus from '../asset/ad-status';
import { BriicksListing, RedirectionModes } from 'graphql/schema/__generated__/graphql-types';
import DaysCount from '../asset/days-count';
//import TakersBadge from "../asset/takers-badge";
import { IFullAdsIndexMenu } from '../../common/types';
import { Button, Link, List, ListItem, Stack } from '@mui/material';
import { SourcesIcon } from 'components/misc/SourcesIcon';
import styled from 'styled-components';

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
`;

const Container = tw.div`
sticky top-0
`;

const NavigationContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col
`;

const TimeLineContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col mb-2
`;

const BadgesContainer = tw.div`
flex flex-col mb-2
`;

const TimelineHead = tw.h1`
text-base font-semibold text-green-500
`;

const Timelinedescription = tw.h3`
text-sm text-secondary-300
`;

const MakerContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col mt-2
`;

const BriicksMakerLogo = tw.img`
w-auto h-8 mt-2
`;

const Navigation = (props: {
  listing: BriicksListing;
  visibleArticle: string;
  sections: IFullAdsIndexMenu[];
  isNavigation?: boolean;
}) => {
  const { shortCode, status, source, calendar, preferences } = props.listing;

  return (
    <StickyContainer>
      <BadgesContainer>
        <AdId css={tw`bg-gray-200 ml-0 p-2 justify-center`} adId={shortCode} />
        <AdStatus status={status} />
        {/* <TakersBadge>bought by 3652 Takers</TakersBadge> */}
        <DaysCount calendar={calendar} status={status} />
      </BadgesContainer>

      {false && (
        <TimeLineContainer>
          <TimelineHead>Timeline</TimelineHead>
          <Timelinedescription>Last Update : 07.11.22 - 08:38</Timelinedescription>
        </TimeLineContainer>
      )}

      <Container>
        <NavigationContainer>
          {props.sections.map((e, index) => {
            return (
              <List
                key={index}
                sx={{
                  padding: 0,
                  '& .MuiTypography-root:hover': {
                    fontSize: 16,
                    '&, & .MuiListItemIcon-root': {
                      color: '#6415FF',
                    },
                  },
                }}
                subheader={<h1 css={tw`text-primary-500 text-lg font-semibold`}>{e.section}</h1>}
              >
                {e.elements.map((o, index) => {
                  /* if (o.name === 'Restrinctions' && (!props.listing.briicks.restrictions || !props.listing.briicks.restrictions?.hasRestriction)) return null
                                            if (o.name === 'Flipping' && !props.listing.flipping) return null
                                            if (o.name === 'Renting' && !props.listing.rental) return null
                                            if (o.name === 'Debt' && !props.listing.financial.holdingCost.debt) return null */
                  return (
                    <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index}>
                      <Link
                        href={`#${o.name}`}
                        //color={o.name === props.visibleArticle ? '#6415FF' : '#243E63'}
                        color={'#243E63'}
                        variant="body2"
                        underline="none"
                      >
                        {o.name}
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            );
          })}
        </NavigationContainer>

        <MakerContainer>
          <h1 css={tw`text-primary-500 text-base font-semibold`}>Briickmaker</h1>

          <BriicksMakerLogo src={SourcesIcon(source)} />
        </MakerContainer>

        <MakerContainer>
          {preferences.redirection.mode === RedirectionModes.Default && (
            <Button
              variant="contained"
              color="success"
              fullWidth
              size="large"
              href={preferences.redirection.default || '#'}
              target="_blank"
            >
              Invest Now
            </Button>
          )}

          {preferences.redirection.mode === RedirectionModes.Multiple && (
            <Stack>
              {preferences.redirection.multiRedirections?.map((e, index: number) => (
                <Button
                  sx={{ marginBottom: 1, marginTop: 1 }}
                  key={index}
                  variant="contained"
                  color="success"
                  fullWidth
                  size="small"
                  href={e.url || '#'}
                  target="_blank"
                >
                  Invest Now ({e.description})
                </Button>
              ))}
            </Stack>
          )}
        </MakerContainer>
      </Container>
    </StickyContainer>
  );
};

export default Navigation;
