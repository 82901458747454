import tw from 'twin.macro';
import AssetInfos, { elements as assetArticles } from './asset';
import Financial, { elements as financialArticles } from './financial';
import Legal, { elements as legalArticles } from './legal';
import ListedBy from './listed-by';
import Navigation from './navigation';
import Offering, { elements as offeringArticles } from './offering';
//import Operation, { elements as operationsArticles } from './operation';
import Resources, { elements as ressourcesArticles } from './resources';
import AdTitle from './title';
import { useState } from 'react';
import { IFullAdsIndexMenu } from '../../common/types';
import { BriicksListing, RedirectionModes } from 'graphql/schema/__generated__/graphql-types';
import { Box, IconButton, Link, Stack } from '@mui/material';
//import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

const Container = tw.div`md:flex flex-col md:flex-row md:min-h-screen w-full px-2`;

const CardLetfWraper = tw.div`flex flex-col rounded text-gray-700 w-56 flex-shrink-0`;
const ContentContainer = tw.div`flex flex-col flex-grow pl-4`;

const FullCard = (props: { listing: BriicksListing; isNavigation?: boolean }) => {
  const { listing, isNavigation } = props;
  const sections: IFullAdsIndexMenu[] = [
    {
      section: 'Property',
      elements: assetArticles,
    },
    {
      section: 'Investment',
      elements: offeringArticles,
    },
    /*{
            section: 'Operation',
            elements: operationsArticles
        },*/
    {
      section: 'Financial',
      elements: financialArticles,
    },
    {
      section: 'Legal',
      elements: legalArticles,
    },
    {
      section: 'Ressources',
      elements: ressourcesArticles,
    },
  ];
  const [visibleArticle, setVisibleArticle] = useState<string[]>([]);
  const navigate = useNavigate();

  const viewPortObserver = (id: string, isVisible: boolean) => {
    if (visibleArticle.includes(id) && !isVisible) {
      setVisibleArticle(visibleArticle.filter((e) => e !== id));
    } else if (!visibleArticle.includes(id) && isVisible) {
      setVisibleArticle([...visibleArticle, id]);
    }
  };
  const goBack = () => {
    //navigate(-1);
    navigate('/market');
  };
  return (
    <Container>
      <CardLetfWraper>
        <Navigation
          listing={listing}
          visibleArticle={_.last(visibleArticle) || ''}
          sections={sections}
          isNavigation={isNavigation}
        />
      </CardLetfWraper>
      <ContentContainer>
        <Box>
          <IconButton size="large" color="primary" onClick={goBack}>
            <ForwardIcon sx={{ transform: 'rotate(180deg)', fontSize: 40 }} />
          </IconButton>
        </Box>
        <ListedBy source={listing.source} />
        <Stack direction="column" alignItems="end" sx={{ marginTop: 1 }}>
          {listing.preferences.redirection.mode === RedirectionModes.Default && (
            <Link href={listing.preferences.redirection.default || '#'} target="_blank">
              View original Ads
            </Link>
          )}

          {listing.preferences.redirection.mode === RedirectionModes.Multiple && (
            <Stack>
              {listing.preferences.redirection.multiRedirections?.map((e, index: number) => (
                <Link href={e.url || '#'} target="_blank" key={index}>
                  View original Ads ({e.description})
                </Link>
              ))}
            </Stack>
          )}
        </Stack>
        <AdTitle title={listing.title} />
        <AssetInfos asset={listing.asset} viewPortObserver={viewPortObserver} />
        <Offering ads={listing} viewPortObserver={viewPortObserver} />
        {
          //<Operation ads={listing} viewPortObserver={viewPortObserver} />
        }

        <Financial ads={listing} viewPortObserver={viewPortObserver} />
        <Legal ads={listing} viewPortObserver={viewPortObserver} />
        <Resources ads={listing} viewPortObserver={viewPortObserver} />
      </ContentContainer>
    </Container>
  );
};

export default FullCard;
