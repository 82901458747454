import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { BriicksListing, ReservedShare } from 'graphql/schema/__generated__/graphql-types';
import _ from 'lodash';
import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import SPVIdentification from './spv-identification';
import SPVSponsor from './sponsor';
import TypeOfContract from './type-of-contract';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Row = (props: { title: string; value: number; percent: number }) => {
  const { title, value, percent } = props;
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={value}
          decimalScale={0}
          fixedDecimalScale
          displayType={'text'}
          thousandSeparator={' '}
          prefix={''}
          renderText={(value: string) => <span>{value}</span>}
        />
      </TableCell>
      <TableCell align="right">
        <NumberFormat
          value={percent}
          decimalScale={2}
          fixedDecimalScale
          displayType={'text'}
          thousandSeparator={' '}
          suffix={' %'}
          renderText={(value: string) => <span>{value}</span>}
        />
      </TableCell>
    </TableRow>
  );
};

const SPVId = (props: {
  ads: BriicksListing;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { spv, briicks } = props.ads;
  if (!spv) return null;

  const companyInfoProvider = spv.metadata?.filter((e) => e.key.toLowerCase() === 'companyinfoprovider') || [];
  const link = companyInfoProvider?.length > 0 ? companyInfoProvider[0].value : '#';
  const entries = spv.metadata?.reduce((acc: string[], e) => {
    if (e.key.toLowerCase() === 'companyinfosentries') return [...acc, e.value];
    else return acc;
  }, []);

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>SPV</Headline>
      <Divider />
      <Box sx={{ width: 850, alignSelf: 'center' }}>
        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Identification
        </Typography>

        <SPVIdentification
          spvName={_.isEmpty(spv.name.trim()) ? 'no data' : spv.name}
          registrationPlace={_.isEmpty(spv.registrationPlace.trim()) ? 'no data' : spv.registrationPlace}
          companyNumber={_.isEmpty(spv.registrationNumber.trim()) ? 'no data' : spv.registrationNumber}
          companyType="no data"
          incorportationDate="no data"
        />

        {entries && entries.length > 0 && (
          <>
            <Typography variant="body2" sx={tw`text-base text-blue-500 mt-6`}>
              External links
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {entries?.map((e: string, index: number) => (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Link
                          title={e}
                          href={link}
                          target="_blank"
                          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {e}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Sponsor
        </Typography>

        <SPVSponsor
          sponsorName={_.isEmpty(spv.sponsor.name.trim()) ? 'no data' : spv.sponsor.name}
          registrationPlace={
            _.isEmpty(spv.sponsor.registrationPlace.trim()) ? 'no data' : spv.sponsor.registrationPlace
          }
          companyNumber={_.isEmpty(spv.sponsor.registrationNumber.trim()) ? 'no data' : spv.sponsor.registrationNumber}
        />

        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Type of Contract
        </Typography>
        <TypeOfContract title="no data" />

        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Distribution
        </Typography>

        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="simple table"
          >
            <TableBody>
              <Row
                title="Total Issuance of Shares"
                value={briicks.distribution.totalShares}
                percent={(briicks.distribution.totalShares * 100) / briicks.distribution.totalShares}
              />

              <Row
                title="Market Offering"
                value={briicks.distribution.sharesOnSale}
                percent={(briicks.distribution.sharesOnSale * 100) / briicks.distribution.totalShares}
              />
              {briicks.distribution.hasReservedShares &&
                briicks.distribution.reservedShare?.map((row: ReservedShare, index: number) => (
                  <Row
                    key={index}
                    title={row.name}
                    value={row.shares}
                    percent={(row.shares * 100) / briicks.distribution.totalShares}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ArticleContainer>
  );
};

export default SPVId;
