import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads, FinancialMetadata } from 'graphql/schema/__generated__/graphql-types';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const OperationRow = (props: { title: string; finacialsData: Maybe<FinancialMetadata[]> | undefined }) => {
  const { title, finacialsData } = props;
  return (
    <TableRow>
      <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
        <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
          {title}
        </Typography>
        {finacialsData && finacialsData.length > 0 ? (
          finacialsData.map((data: FinancialMetadata, index: number) => (
            <Typography variant="body2" key={index}>
              {data.title}
            </Typography>
          ))
        ) : (
          <Typography variant="body2">no data</Typography>
        )}
      </TableCell>
      <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
        <Typography variant="subtitle1"> </Typography>
        {finacialsData && finacialsData.length > 0 ? (
          finacialsData.map((data: FinancialMetadata, index: number) => (
            <Typography variant="body2" key={index}>
              <NumberFormat
                value={data.value}
                decimalScale={2}
                fixedDecimalScale
                displayType={'text'}
                thousandSeparator={' '}
                prefix={'$ '}
                renderText={(value: string) => <span>{value}</span>}
              />
            </Typography>
          ))
        ) : (
          <Typography variant="body2">no data</Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

const OperationsCost = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { totalCost, utilities, maintenance, inusrance, taxes, management, administrative } =
    props.ads.financial.holdingCost;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
        <Headline>Operation cost</Headline>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
            <Table
              sx={{
                minWidth: 650,
                '& .MuiTableCell-root': {
                  padding: 1,
                  //border: 1
                },
              }}
              aria-label="multiline table"
            >
              <TableBody>
                <OperationRow title="Utilities" finacialsData={utilities} />
                <OperationRow title="Maintenance" finacialsData={maintenance} />
                <OperationRow title="Insurance" finacialsData={inusrance} />
                <OperationRow title="Taxes" finacialsData={taxes} />
                <OperationRow title="Management" finacialsData={management} />
                <OperationRow title="Administrative" finacialsData={administrative} />
                <OperationRow title="Loan" finacialsData={undefined} />

                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`}>
                      <NumberFormat
                        value={totalCost}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        prefix={'$ '}
                        renderText={(value: string) => <span>{value}</span>}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ArticleContainer>
    </>
  );
};

export default OperationsCost;
