import tw from 'twin.macro';
import ReactCountryFlag from 'react-country-flag';
import parse from 'html-react-parser';
import styled from 'styled-components';
import Maps from './maps';
import Address from './address';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Country = tw.div`
flex flex-row items-center mt-8
`;

const Title = tw.h2`
text-secondary-200 font-medium text-xl mr-4
`;

const AboutContent = styled.div`
  ${tw``}
  h1 {
    ${tw`font-semibold py-2 mt-2    text-lg`}
  }
`;
const aboutTheCity = `
<h1>Title 1</h1>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eu volutpat metus. Nam placerat, ante a malesuada rhoncus, turpis mi eleifend augue, non tempus justo ex vel ex. Donec in lectus ut leo pharetra cursus. Duis et tellus sit amet quam feugiat cursus ut vel quam. Duis vel maximus lectus.</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut rutrum tellus. Nam ut dolor rutrum, dictum nisl in, tincidunt.</p>
<h1>Title 2</h1>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vulputate tincidunt justo, a semper magna mollis vel. Curabitur facilisis eros vel lorem tristique tempor. Sed tristique tortor nunc, et porttitor nunc vehicula vitae. Sed malesuada erat lectus, in egestas odio porttitor vitae. Donec sodales, libero non iaculis luctus, leo purus fringilla dui, ullamcorper sodales ante purus quis mauris. Aliquam eu auctor nisi. In tincidunt diam at ante sollicitudin, et suscipit.</p>
`;

const MapContainer = tw.div`
flex h-128 mt-8
`;

const Location = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Location</Headline>
      <Divider />
      <Country>
        <Title>United states of Animation</Title>
        <ReactCountryFlag
          countryCode="US"
          svg
          style={{
            width: '3em',
            height: '3em',
          }}
        />
      </Country>

      <Title>Address</Title>
      <Address />
      <Title>About the city</Title>
      <AboutContent>{parse(aboutTheCity, { trim: true })}</AboutContent>
      <MapContainer>
        <Maps />
      </MapContainer>
    </ArticleContainer>
  );
};

export default Location;
