import tw from 'twin.macro';
import parse from 'html-react-parser';
import styled from 'styled-components';
import ArticleContainer from 'components/article/article-container';
import { Asset } from 'graphql/schema/__generated__/graphql-types';
import VideoPreview from 'components/media/video-preview';
import { Box, CardMedia, Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ReactCountryFlag from 'react-country-flag';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;
const Title = tw.h2`
text-blue-500 font-medium text-xl mt-8
`;
const AboutContent = styled.div`
  ${tw`mt-2`}
  h2 {
    ${tw`font-semibold py-2 mt-2 text-lg`}
  }
  a {
    ${tw`text-blue-500`}
  }
  ul {
    ${tw`list-disc list-inside`}
  }

  li {
    ${tw`mb-1 ml-4`}
  }
`;

const Abstract = (props: { asset: Asset; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { asset } = props;
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} css={tw`mt-0!`}>
      <Headline>Overview</Headline>
      <Divider />
      <Box sx={{ flexGrow: 1, padding: 1, marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            {
              //<Title>Presentation</Title>
              asset.video ? (
                <VideoPreview source={asset.video} />
              ) : (
                <CardMedia
                  component="img"
                  image={asset.pictures.featured}
                  //alt="green iguana"
                />
              )
            }
          </Grid>
          <Grid xs={3}>
            <Stack
              spacing={4}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={tw`h-full`}
              //sx={tw` flex items-center justify-center`}
            >
              <Paper
                elevation={0}
                sx={{ width: 180, padding: 2, ...tw`flex  items-center flex-col border border-gray-300` }}
              >
                <Typography variant="body1" sx={tw`text-lg font-semibold text-blue-500 uppercase mb-2`}>
                  {regionNames.of(asset.location.address.country)}
                </Typography>
                <ReactCountryFlag
                  countryCode={asset.location.address.country}
                  svg
                  style={{
                    width: 50,
                    height: 'auto',
                  }}
                />
              </Paper>

              <Paper
                elevation={0}
                sx={{ width: 180, padding: 2, ...tw`flex  items-center flex-col border border-gray-300` }}
              >
                <HolidayVillageIcon sx={tw`w-12 h-12 text-blue-500`} />

                <Typography variant="body1" sx={tw`text-lg font-semibold uppercase`}>
                  {asset.assetType.type}
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{ width: 180, padding: 2, ...tw`flex  items-center flex-col border border-gray-300` }}
              >
                <Typography variant="body2" sx={tw`text-xl font-semibold text-green-500 uppercase`}>
                  Rental
                </Typography>
                <Typography variant="body1" sx={tw`text-lg font-semibold uppercase`}>
                  Investment
                </Typography>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Title>About the property</Title>
      <AboutContent>{parse(asset.description, { trim: true })}</AboutContent>
    </ArticleContainer>
  );
};

export default Abstract;
