//import 'leaflet/dist/leaflet.css';
import { Coordinates } from 'graphql/schema/__generated__/graphql-types';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const Maps = (props: { coordinates: Coordinates } & any) => {
  const { coordinates } = props;
  return (
    <MapContainer
      style={{ width: '100%', height: '100%' }}
      center={[coordinates.latitude, coordinates.longitude]}
      zoom={13}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[coordinates.latitude, coordinates.longitude]}>
        {false && (
          <Popup>
            <h1>Title</h1>. <br /> <p>Descritpion</p>
          </Popup>
        )}
      </Marker>
    </MapContainer>
  );
};

export default Maps;
