import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import {
  AssetType,
  MeasurementsCode,
  AssetFeatures,
  Metadata,
} from '../../../graphql/schema/__generated__/graphql-types';
import { Divider } from '@mui/material';
import _ from 'lodash';

const Container = tw.div`
col-span-1
`;
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;
const Val = tw.div`
text-sm text-blue-500
`;

const Type = (props: { assetType: AssetType; measurement: MeasurementsCode; assetFeatures: AssetFeatures[] }) => {
  const { assetType, assetFeatures, measurement } = props;

  const area = assetType.area;
  let values: string[] = [];
  if (assetType.destination.toLowerCase().trim() !== 'unknown') values.push(assetType.destination.trim());
  if (assetType.type.toLowerCase().trim() !== '') values.push(assetType.type.trim());

  let metaData: Metadata[] = [];
  if (assetFeatures && !_.isEmpty(assetFeatures)) {
    metaData = _.flatMap(assetFeatures.map((e) => e.metadata || []));
    let bath = ``;
    let bed = ``;
    for (const iterator of metaData) {
      if (iterator.key.includes('bath')) bath = `${iterator.value} ${iterator.key}`;
      if (iterator.key.includes('bed')) bed = `${iterator.value} ${iterator.key}`;
    }

    if (!_.isEmpty(bath) && !_.isEmpty(bed)) values.push(`${bath} | ${bed}`);
    else if (!_.isEmpty(bath)) values.push(`${bath}`);
    else if (!_.isEmpty(bed)) values.push(`${bed}`);
    else values.push(' -- ');
  }
  /* 
    if (assetFeatures && !_.isEmpty(assetFeatures)) {
        const feature = _.first(assetFeatures);
        if (feature && feature.metadata) {
            const metadata = feature.metadata
            let value = '';
            if (metadata.length > 0) {
                const data = metadata[0];
                value = `${data.value} ${data.key}`
            }

            if (metadata.length > 1) {
                const data = metadata[1];
                value = `${value} | ${data.value} ${data.key}`
            }

            if (value !== '') values.push(value)
        }
    }
 */

  const measurmentCode = measurement === MeasurementsCode.SquareFeet ? 'sqft' : 'm²';
  return (
    <Container>
      <Heading2>Property</Heading2>
      <Divider variant={undefined} css={tw`bg-secondary-900 mr-2!`} />
      {values.map((e: string, index: number) => (
        <Val key={index} css={tw`capitalize`}>
          {e}
        </Val>
      ))}
      <Val>
        <NumberFormat
          value={area}
          decimalScale={0}
          fixedDecimalScale
          displayType={'text'}
          thousandSeparator={' '}
          suffix={` ${measurmentCode}`}
          renderText={(value: string) => <span>{value}</span>}
        />
      </Val>
    </Container>
  );
};

export default Type;
