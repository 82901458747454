import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import NumberFormat from 'react-number-format';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;
const Title = tw.h2`
text-secondary-200 font-medium text-xl mt-2
`;

const TotalInvest = (props: { acquisitionCost: number; assetPrice: number }) => {
  const { acquisitionCost, assetPrice } = props;
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
        <Table
          sx={{
            width: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={tw`text-blue-500`}>
                  Total Investment
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Total Acquisition Cost
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={acquisitionCost}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Total Assets Price
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={assetPrice}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`font-bold text-secondary-500`}>
                  <PauseCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(90deg)', ...tw`text-green-500` }}
                  />
                  Total Investment
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right" sx={tw`font-bold text-secondary-500`}>
                  <NumberFormat
                    value={assetPrice + acquisitionCost}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const CashFlow = (props: { rentalIncome: number; operationCost: number }) => {
  const { rentalIncome, operationCost } = props;
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
        <Table
          sx={{
            width: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={tw`text-blue-500`}>
                  Cash Flow
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Total Rental Income
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={rentalIncome}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <RemoveCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22, ...tw`text-red-500` }} />
                  Total Operation Cost
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={operationCost}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`font-bold text-secondary-500`}>
                  <PauseCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(90deg)', ...tw`text-green-500` }}
                  />
                  Cash Flow
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right" sx={tw`font-bold text-secondary-500`}>
                  <NumberFormat
                    value={rentalIncome - operationCost}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const CashOnCash = (props: { cashFlow: number; totalInvest: number }) => {
  const { cashFlow, totalInvest } = props;
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
        <Table
          sx={{
            width: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={tw`text-blue-500`}>
                  Cash On Cash ( C.O.C )
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Cash Flow
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={cashFlow}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <RemoveCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(-45deg)', ...tw`text-orange-500` }}
                  />
                  Total Investment
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={totalInvest}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`font-bold text-secondary-500`}>
                  <PauseCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(90deg)', ...tw`text-green-500` }}
                  />
                  Cash On Cash
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right" sx={tw`font-bold text-secondary-500`}>
                  <NumberFormat
                    value={(cashFlow * 100) / totalInvest}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    suffix={' %'}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const CapitalAppreciation = (props: { assetPrice: number; appriciationRate: number | null }) => {
  const { assetPrice, appriciationRate } = props;
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
        <Table
          sx={{
            width: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={tw`text-blue-500`}>
                  Capital Appreciation
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Total Assets Price
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={assetPrice}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <HighlightOffRoundedIcon sx={{ marginRight: 1, fontSize: 22, ...tw`text-primary-500` }} />
                  Appreciation Rate
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  {appriciationRate !== null ? (
                    <NumberFormat
                      value={appriciationRate}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  ) : (
                    'no data'
                  )}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`font-bold text-secondary-500`}>
                  <PauseCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(90deg)', ...tw`text-green-500` }}
                  />
                  Appreciation Value
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right" sx={tw`font-bold text-secondary-500`}>
                  {appriciationRate !== null ? (
                    <NumberFormat
                      value={(assetPrice * appriciationRate) / 100}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  ) : (
                    'no data'
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ReturnOnInvest = (props: { cashFlow: number; valueAppriciation: number | null; totalInvest: number }) => {
  const { cashFlow, totalInvest, valueAppriciation } = props;
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
        <Table
          sx={{
            width: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" sx={tw`text-red-500`}>
                  Return On Investment ( R.O.I )
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Cash Flow
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={cashFlow}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <AddCircleOutlineRoundedIcon sx={{ marginRight: 1, fontSize: 22 }} />
                  Appreciation Value
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  {valueAppriciation !== null ? (
                    <NumberFormat
                      value={valueAppriciation}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      prefix={'$ '}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  ) : (
                    'no data'
                  )}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`text-secondary-500`}>
                  <RemoveCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(-45deg)', ...tw`text-orange-500` }}
                  />
                  Total Investment
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right">
                  <NumberFormat
                    value={totalInvest}
                    decimalScale={2}
                    fixedDecimalScale
                    displayType={'text'}
                    thousandSeparator={' '}
                    prefix={'$ '}
                    renderText={(value: string) => <span>{value}</span>}
                  />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="body2" sx={tw`font-bold text-red-500`}>
                  <PauseCircleOutlineRoundedIcon
                    sx={{ marginRight: 1, fontSize: 22, transform: 'rotate(90deg)', ...tw`text-green-500` }}
                  />
                  Return On Investment ( R.O.I )
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" align="right" sx={tw`font-bold text-red-500`}>
                  {valueAppriciation !== null ? (
                    <NumberFormat
                      value={((cashFlow + valueAppriciation) * 100) / totalInvest}
                      decimalScale={2}
                      fixedDecimalScale
                      displayType={'text'}
                      thousandSeparator={' '}
                      suffix={' %'}
                      renderText={(value: string) => <span>{value}</span>}
                    />
                  ) : (
                    'no data'
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Metrics = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const acquisitionCost = props.ads.financial.aquisitionCost.totalCost;
  const assetPrice = props.ads.financial.assetValue.totalAssetValue;
  const totalInvest = acquisitionCost + assetPrice;

  const rentalIncome = props.ads.rental?.leaseDetails.annualGrossRent || 0;
  const operationCost = props.ads.financial.holdingCost.totalCost;
  const cashFlow = rentalIncome - operationCost;

  const appriciationRate = props.ads.rental?.profitability.assetAppreciation || null;

  const valueAppriciation = appriciationRate !== null ? (assetPrice * appriciationRate) / 100 : null;
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
      <Headline>Metrics</Headline>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <TotalInvest acquisitionCost={acquisitionCost} assetPrice={assetPrice} />

        <CashFlow rentalIncome={rentalIncome} operationCost={operationCost} />

        <CashOnCash cashFlow={cashFlow} totalInvest={totalInvest} />

        <CapitalAppreciation assetPrice={assetPrice} appriciationRate={appriciationRate} />

        <ReturnOnInvest cashFlow={cashFlow} totalInvest={totalInvest} valueAppriciation={valueAppriciation} />
      </Box>

      <Box css={tw`bg-orange-100 p-2 mt-4`}>
        <Title css={tw`mt-4 text-orange-500`}>Disclaimer</Title>
        <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic' }}>
          The financial metrics presented in this offer are calculated based on estimations provided by the Briickmaker.
          These estimates are subject to market and economic risks and should not be interpreted as a guarantee of
          future performance. Actual results may be higher or lower than these estimates. Prospective investors are
          advised to conduct their own due diligence and consider their risk tolerance before making an investment
          decision.
        </Typography>
      </Box>
    </ArticleContainer>
  );
};

export default Metrics;
