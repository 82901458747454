import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`;

const Content = tw.div`
px-2 flex flex-row
`;

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`;

const Value = tw.div`
flex-grow text-sm text-right capitalize
`;
const Providers = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Service Provider</Headline>
      <Divider />
      <Card>
        <Content>
          <Label>BRIICK MAKER</Label>
          <Value>Lofty</Value>
        </Content>

        <Content>
          <Label>Property Management</Label>
          <Value>REALBEST</Value>
        </Content>
      </Card>
    </ArticleContainer>
  );
};

export default Providers;
