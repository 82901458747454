import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const TypeOfContract = (props: { title: string }) => {
  const { title } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Title
              </TableCell>
              <TableCell align="right">{title}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TypeOfContract;
