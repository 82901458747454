import Documents from './documents';
import Providers from './provider';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from 'components/article/section-container';

export const elements: IFullAdsSection[] = [
  {
    name: 'Providers',
    element: Providers,
  },
  {
    name: 'Documents',
    element: Documents,
  },
];

const Resources = (props: { setVisibleArticle: (articleName: string) => void }) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return (
          <V
            id={e.name}
            key={index}
            viewPortObserver={(isVisible: boolean) => {
              if (isVisible) props.setVisibleArticle(elements[index].name);
            }}
          />
        );
      })}
    </SectionContainer>
  );
};

export default Resources;
