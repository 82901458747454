import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/schema/__generated__/graphql-types';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;
const Card = tw.div`
min-w-86 max-w-lg mt-2  w-128  self-center
`;
const CardHead = tw.div`
bg-primary-500 text-white p-2
`;
const HeadLine = tw.h1`
text-lg font-medium uppercase
`;
const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`;
const Content = tw.div`
px-2 flex flex-row text-sm border-b border-secondary-100
`;
const PaymentMethodes = (props: {
  ads: Ads;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
      <Headline>Payment</Headline>
      <Divider />
      no data
      {false && (
        <Card>
          <CardHead>
            <HeadLine>FIAT</HeadLine>
          </CardHead>

          <Content>
            <SubHeadLine>VISA</SubHeadLine>
          </Content>
          <Content>ALL CURRENCY</Content>

          <Content>
            <SubHeadLine>MasterCard</SubHeadLine>
          </Content>
          <Content>ALL CURRENCY</Content>

          <CardHead>
            <HeadLine>CYRPTOS</HeadLine>
          </CardHead>

          <Content>
            <SubHeadLine>ETHERUM</SubHeadLine>
          </Content>
          <Content>ETH, SOL, USDC, USDT</Content>

          <Content>
            <SubHeadLine>SOLANA</SubHeadLine>
          </Content>
          <Content>WETH, SOL, USDC, USDT</Content>
        </Card>
      )}
    </ArticleContainer>
  );
};

export default PaymentMethodes;
