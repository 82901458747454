import { ExpandMoreOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import tw from 'twin.macro';
import NumberFormat from 'react-number-format';

const Container = tw.div`min-w-86 max-w-lg mt-8   w-128  self-center`;
const SammaryTitle = tw.h2`
text-primary-500 text-sm
`;

const Card = tw.div`
min-w-86 max-w-lg
`;
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`;
const HeadLine = tw.h1`
text-lg font-medium uppercase
`;

const Content = tw.div`
px-2 flex flex-row
`;

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`;

const Value = tw.div`
flex-grow text-sm text-right capitalize
`;

const SubHeadLine = tw.h1`
text-primary-500 text-sm font-medium uppercase
`;

const Details = () => {
  return (
    <Container>
      <Accordion elevation={0}>
        <AccordionSummary sx={{ paddingLeft: 1 }} expandIcon={<ExpandMoreOutlined css={tw`text-primary-500`} />}>
          <SammaryTitle>Détails</SammaryTitle>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Card>
            <CardHead>
              <HeadLine>Underlying Asset Value</HeadLine>
              <Value css={tw`text-lg`}>
                <NumberFormat
                  value={42000}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </CardHead>

            <Content>
              <Label>Building</Label>
              <Value>
                <NumberFormat
                  value={400000}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <Label>Parking lot</Label>
              <Value>
                <NumberFormat
                  value={15000}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <Label>Furniture</Label>
              <Value>
                <NumberFormat
                  value={5000}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <CardHead>
              <HeadLine>Operation Cost</HeadLine>
              <Value css={tw`text-lg`}>
                <NumberFormat
                  value={36200}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </CardHead>

            <Content>
              <SubHeadLine>Reserve</SubHeadLine>
            </Content>

            <Content>
              <Label>Maintenance</Label>
              <Value>
                <NumberFormat
                  value={5000}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <Label>Vacancy</Label>
              <Value>
                <NumberFormat
                  value={1000}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <SubHeadLine>Closing Cost</SubHeadLine>
            </Content>

            <Content>
              <Label>Closing</Label>
              <Value>
                <NumberFormat
                  value={5500}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <SubHeadLine>Initial Revenovation</SubHeadLine>
            </Content>

            <Content>
              <Label>Details 1</Label>
              <Value>
                <NumberFormat
                  value={0}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <SubHeadLine>Tokenization Fees</SubHeadLine>
            </Content>

            <Content>
              <Label>Briick Maker - LOFTY </Label>
              <Value>
                <NumberFormat
                  value={4500}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>

            <Content>
              <Label>SPV Creation Cost</Label>
              <Value>
                <NumberFormat
                  value={200}
                  decimalScale={2}
                  fixedDecimalScale
                  displayType={'text'}
                  thousandSeparator={' '}
                  prefix={'$ '}
                  renderText={(value: string) => <span>{value}</span>}
                />
              </Value>
            </Content>
          </Card>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default Details;
