import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import { Briicks } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`
flex flex-row
`;
const Metrics = tw.div`
flex flex-grow flex-col pl-2 
`;

//mt-2 mb-2
const Title = tw.div`
pr-4 border-r min-h-full mt-1 mb-1
`;
const Heading1 = tw.h1`
flex items-center h-full uppercase 
font-medium text-primary-500 text-base
`;

const Row = tw.div`
flex flex-row 
`;

const Val = tw.div`
flex-grow pl-2 flex justify-end text-base text-blue-500
`;

const Heading2 = tw.h2`
font-medium text-base text-secondary-900
`;
const BriicksView = (props: { briicks: Briicks }) => {
  const { briicks } = props;
  const price = briicks.price;
  const stock = briicks.distribution.stock;

  return (
    <Container>
      <Title>
        <Heading1>Briick</Heading1>
      </Title>
      <Metrics>
        <Row>
          <Heading2>Price</Heading2>
          <Val>
            <NumberFormat
              value={price}
              decimalScale={2}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={'$ '}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Val>
        </Row>
        <Row>
          <Heading2>Remaining</Heading2>
          <Val>
            <NumberFormat
              value={stock}
              decimalScale={0}
              fixedDecimalScale
              displayType={'text'}
              thousandSeparator={' '}
              prefix={''}
              renderText={(value: string) => <span>{value}</span>}
            />
          </Val>
        </Row>
      </Metrics>
    </Container>
  );
};

export default BriicksView;
