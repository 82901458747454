//import Investment from './investement';
// import HoldingCost from './holding-cost'
//import Profitability from './profitability';
//import Debt from './debt';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from '../../../article/section-container';
import { BriicksListing } from 'graphql/schema/__generated__/graphql-types';
import AssetPrice from './asset-price';
import AcquisitionCost from './acquisition-cost';
import OperationCost from './operation-cost';
import Lease from './lease';
import Liabilities from './liabilities';

export const elements: IFullAdsSection[] = [
  {
    name: 'Asset',
    element: AssetPrice,
  },
  {
    name: 'Acquisition',
    element: AcquisitionCost,
  },
  {
    name: 'Operation',
    element: OperationCost,
  },
  {
    name: 'Lease',
    element: Lease,
  },
  {
    name: 'Liabilities',
    element: Liabilities,
  },
  /* {
        name: 'Investment',
        element: Investment
    },
      {
        name: 'Holding cost',
        element: HoldingCost
    }, 
    {
        name: 'Profitability',
        element: Profitability
    },
     {
        name: 'Debt',
        element: Debt
    }, */
];
const Financial = (props: {
  ads: BriicksListing;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        if (e.name === 'Debt' && !props.ads.financial.holdingCost.debt) return null;

        return <V ads={props.ads} id={e.name} key={index} viewPortObserver={props.viewPortObserver} />;
      })}
    </SectionContainer>
  );
};

export default Financial;
