import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads, FinancialMetadata } from 'graphql/schema/__generated__/graphql-types';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const AcquisitionCost = (props: {
  ads: Ads;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { closingCost, reserve, setupCost, otherCosts, totalCost } = props.ads.financial.aquisitionCost;

  let otherFees: FinancialMetadata[] = [];
  if (setupCost) otherFees = [...otherFees, ...setupCost];
  if (otherCosts) {
    for (const cost of otherCosts) {
      otherFees = [...otherFees, ...cost.metadata];
    }
  }
  //
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
        <Headline>Acquisition cost</Headline>
        <Divider />
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
            <Table
              sx={{
                minWidth: 650,
                '& .MuiTableCell-root': {
                  padding: 1,
                  //border: 1
                },
              }}
              aria-label="multiline table"
            >
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                      Closing Cost
                    </Typography>
                    {closingCost && closingCost.length > 0 ? (
                      closingCost.map((data: FinancialMetadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          {data.title}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1"> </Typography>
                    {closingCost && closingCost.length > 0 ? (
                      closingCost.map((data: FinancialMetadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          <NumberFormat
                            value={data.value}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                      Reserve
                    </Typography>
                    {reserve && reserve.length > 0 ? (
                      reserve.map((data: FinancialMetadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          {data.title}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1"> </Typography>
                    {reserve && reserve.length > 0 ? (
                      reserve.map((data: FinancialMetadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          <NumberFormat
                            value={data.value}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                      Renovation
                    </Typography>
                    <Typography variant="body2">no data</Typography>
                  </TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1"> </Typography>
                    <Typography variant="body2">no data</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1" sx={tw`font-bold text-green-500`}>
                      Other Fees
                    </Typography>
                    {otherFees ? (
                      otherFees.map((data: FinancialMetadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          {data.title}
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ verticalAlign: 'bottom' }}>
                    <Typography variant="subtitle1"> </Typography>
                    {otherFees ? (
                      otherFees.map((data: FinancialMetadata, index: number) => (
                        <Typography variant="body2" key={index}>
                          <NumberFormat
                            value={data.value}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        </Typography>
                      ))
                    ) : (
                      <Typography variant="body2">no data</Typography>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <Typography variant="subtitle1" sx={tw`font-bold text-blue-500`}>
                      <NumberFormat
                        value={totalCost}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        prefix={'$ '}
                        renderText={(value: string) => <span>{value}</span>}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ArticleContainer>
    </>
  );
};

export default AcquisitionCost;
