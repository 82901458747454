import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const SPVIdentification = (props: {
  spvName: string;
  registrationPlace: string;
  companyNumber: string;
  companyType: string;
  incorportationDate: string;
}) => {
  const { spvName, registrationPlace, companyNumber, companyType, incorportationDate } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Name
              </TableCell>
              <TableCell align="right">{spvName}</TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Registration Place
              </TableCell>
              <TableCell align="right">{registrationPlace}</TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Company Number
              </TableCell>
              <TableCell align="right">{companyNumber}</TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Company Type
              </TableCell>
              <TableCell align="right">{companyType}</TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Incorportation Date
              </TableCell>
              <TableCell align="right">{incorportationDate}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SPVIdentification;
