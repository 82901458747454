import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const SPVSponsor = (props: { sponsorName: string; registrationPlace: string; companyNumber: string }) => {
  const { sponsorName, registrationPlace, companyNumber } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Name
              </TableCell>
              <TableCell align="right">{sponsorName}</TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Registration Place
              </TableCell>
              <TableCell align="right">{registrationPlace}</TableCell>
            </TableRow>

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Company Number
              </TableCell>
              <TableCell align="right">{companyNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SPVSponsor;
