import { ListingDetailsActions } from 'redux/reducers/listing-details';
import { put, takeLatest } from 'redux-saga/effects';
import clientGraphQL from 'graphql/client';
import {
  GetBriicksListingDetailsDocument,
  QueryGetBriicksListingDetailsArgs,
  GetBriicksListingDetailsQuery,
  BriicksListing,
} from 'graphql/schema/__generated__/graphql-types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getBriicksListingDetailsAsyncRequest(action: PayloadAction<QueryGetBriicksListingDetailsArgs>) {
  const args = action.payload;
  try {
    const result: GetBriicksListingDetailsQuery = yield clientGraphQL.request(GetBriicksListingDetailsDocument, args);
    if (result.getBriicksListingDetails.__typename === 'GetBriicksListingDetailsSuccess')
      yield put(
        ListingDetailsActions.getBriicksListingDetailsSuccess(
          result.getBriicksListingDetails.listing as unknown as BriicksListing,
        ),
      );
    else yield put(ListingDetailsActions.getBriicksListingDetailsFaillure(result.getBriicksListingDetails.message));
  } catch (error) {
    yield put(ListingDetailsActions.getBriicksListingDetailsFaillure('error'));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export const ListingDetailsSaga = [
  //reducer-name/reducer-function
  takeLatest('listingDetails/getBriicksListingDetailsRequest', getBriicksListingDetailsAsyncRequest),
];
