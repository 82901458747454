import React from 'react';
import tw from 'twin.macro';
import { Tab, Tabs } from '@mui/material';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import styled from '@emotion/styled';
import QuiltedImageList from './quilted';
import { Gallery } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`flex pt-4 flex-col`;
const TabPanel = styled(TabPanelBase)`
  ${tw`mt-1`}
`;

const Pictures = (props: { galleries: Gallery[] } & any) => {
  const { galleries } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Tabs
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={{
          minHeight: 38,
          height: 38,
        }}
        value={value}
        onChange={handleChange}
      >
        {galleries.map((gallery: { name: string }, index: number) => (
          <Tab key={index} label={gallery.name} />
        ))}
      </Tabs>
      {galleries.map((gallery: { pictures: string[] }, index: number) => (
        <TabPanel key={index} index={index} value={value}>
          <QuiltedImageList images={gallery.pictures} />
        </TabPanel>
      ))}
    </Container>
  );
};

export default Pictures;
