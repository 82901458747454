import React, { useEffect } from 'react';
import tw from 'twin.macro';
import { useIsInViewport } from '../common/use-is-in-viewport';

const Container = tw.div`flex mt-24 flex-col`;

const ArticleContainer = (props: {
  className?: string;
  children?: React.ReactNode;
  id?: any;
  viewPortObserver?: (viewId: string, isVisible: boolean) => void;
  minHeight?: number;
}) => {
  const ref = React.useRef(null);
  const isInViewport = useIsInViewport(ref, 0.1, props.minHeight);

  useEffect(() => {
    if (props.viewPortObserver) {
      props.viewPortObserver(props.id, isInViewport);
    }
  }, [isInViewport, props.viewPortObserver]);

  return (
    <Container id={props.id} ref={ref} className={props.className}>
      {props.children}
    </Container>
  );
};

export default ArticleContainer;
