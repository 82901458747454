import { useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { SliderBase as SliderStyle } from 'components/misc/React-Slick';
import Slider from 'react-slick';
import { LazyBackground } from 'components/misc/LazyBackground';

const ImageSlide = styled.div<{ imgSrc: string }>(({ imgSrc }) => [
  `background-image: url("${imgSrc}");`,
  tw`min-w-full h-64 lg:h-72 bg-cover bg-center rounded-tl-lg rounded-tr-lg border-b`,
]);
//lg:h-96 h-64
const ImageSlider = (props: { imagesSrc: string[]; onSliderRef: (ref: Slider) => void }) => {
  //@ts-ignore
  const [, setSliderRef] = useState<Slider | null>(null);
  const { imagesSrc } = props;
  return (
    <SliderStyle
      arrows={false}
      ref={(ref: Slider) => {
        setSliderRef(ref);
        if (ref) props.onSliderRef(ref);
      }}
    >
      {imagesSrc.map((src, index) => {
        return (
          src && (
            <LazyBackground key={index} src={src}>
              <ImageSlide key={index} imgSrc={src} />
            </LazyBackground>
          )
        );
      })}
    </SliderStyle>
  );
};

export default ImageSlider;
