import React from 'react';
import tw from 'twin.macro';
import { Tab, Tabs } from '@mui/material';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import styled from '@emotion/styled';
import { Plans } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`flex pt-4 flex-col`;
const TabPanel = styled(TabPanelBase)`
  ${tw`mt-1`}
`;

const Pictures = (props: { plans: Plans[] } & any) => {
  const { plans } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Tabs
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={{
          minHeight: 38,
          height: 38,
        }}
        value={value}
        onChange={handleChange}
      >
        {plans.map((plan: { name: string }, index: number) => (
          <Tab key={index} label={plan.name} />
        ))}
      </Tabs>
      {plans.map((plan: { documents: { source: string }[] }, index: number) => (
        <TabPanel key={index} index={index} value={value}>
          {plan.documents.map((doucument: { source: string }, index: number) => (
            <img key={index} alt="" src={`https://ik.imagekit.io/brma/${doucument.source}`} />
          ))}
        </TabPanel>
      ))}
    </Container>
  );
};

export default Pictures;
