import Flipping from './flipping';
import { IFullAdsSection } from 'components/common/types';
import Renting from './renting';
import SectionContainer from '../../../article/section-container';

export const elements: IFullAdsSection[] = [
  {
    name: 'Renting',
    element: Renting,
  },
  {
    name: 'Flipping',
    element: Flipping,
  },
];

const Operation = (props: { setVisibleArticle: (articleName: string) => void }) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return (
          <V
            id={e.name}
            key={index}
            viewPortObserver={(isVisible: boolean) => {
              if (isVisible) props.setVisibleArticle(elements[index].name);
            }}
          />
        );
      })}
    </SectionContainer>
  );
};

export default Operation;
