import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { forwardRef, useImperativeHandle } from 'react';
import { BriicksListingsSortArgs, Listings_Sort_By } from 'graphql/schema/__generated__/graphql-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { useEffect, useState } from 'react';
import { useDebounce } from 'helpers/use-debounce';
import _ from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import SortIcon from '@mui/icons-material/Sort';
import { ApiInputType } from './types';
const StickyGlobalFilters = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000; // to make sure it stays on top of other elements
  ${tw`flex justify-between  p-4 w-full bg-white`}// add your own styling here
`;

enum SortBy {
  ADVERTISE_DATE,
  ROI,
  COC,
  SALE_VARIATION,
  BRIICK_PRICE,
  APPRECIATION,
}

enum SortDirection {
  ASC,
  DESC,
}

const GlobalFiltersSorts = forwardRef(
  (
    props: {
      apiInput: ApiInputType;
      onGlobalSearchChange: (query: string) => void;
      onSortChange: (sort: BriicksListingsSortArgs) => void;
    },
    ref,
  ) => {
    const [globalSearch, setGlobalSearch] = useState('');
    const debouncedGlobalSearch = useDebounce(globalSearch, 500); // Debounce the search term by 500ms

    const { apiInput, onGlobalSearchChange, onSortChange } = props;
    const { sort } = apiInput;
    const [sortBy, setSortBy] = useState<{ by: SortBy; direction: SortDirection }>({
      by: SortBy.ADVERTISE_DATE,
      direction: SortDirection.DESC,
    });
    const handleIncludeNoDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      if (!checked !== sort.withNoData) {
        //setApiInput({ ...apiInput, sort: { by: sort.by, withNoData: !checked } });
        onSortChange({ by: sort.by, withNoData: !checked });
      }
    };

    const onClearGlobalSearch = () => {
      setGlobalSearch('');
    };

    const handleSortChange = (event: SelectChangeEvent<SortBy>) => {
      /* const value = event.target.value as Listings_Sort_By;
    if (value !== apiInput.sort.by) {
      onSortChange({ by: value, withNoData: apiInput.sort.withNoData });
    } */
      const value = event.target.value as SortBy;
      setSortBy({ ...sortBy, by: value });
    };

    const handleSortDirectionChange = () => {
      const direction = sortBy.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
      setSortBy({ ...sortBy, direction });
    };

    useEffect(() => {
      const { by, direction } = sortBy;
      let sort: Listings_Sort_By | null = null;
      if (by === SortBy.ADVERTISE_DATE) {
        sort = direction === SortDirection.ASC ? Listings_Sort_By.DateAsc : Listings_Sort_By.DateDes;
      } else if (by === SortBy.BRIICK_PRICE) {
        sort = direction === SortDirection.ASC ? Listings_Sort_By.MinInvestAsc : Listings_Sort_By.MinInvestDes;
      } else if (by === SortBy.COC) {
        sort = direction === SortDirection.ASC ? Listings_Sort_By.CocAsc : Listings_Sort_By.CocDes;
      } else if (by === SortBy.ROI) {
        sort = direction === SortDirection.ASC ? Listings_Sort_By.RoiAsc : Listings_Sort_By.RoiDes;
      } else if (by === SortBy.SALE_VARIATION) {
        sort =
          direction === SortDirection.ASC ? Listings_Sort_By.SalesVariationAsc : Listings_Sort_By.SalesVariationDec;
      } else if (by === SortBy.APPRECIATION) {
        sort = direction === SortDirection.ASC ? Listings_Sort_By.AppreciationAsc : Listings_Sort_By.AppreciationDes;
      }

      if (sort && sort !== apiInput.sort.by) {
        onSortChange({ by: sort, withNoData: apiInput.sort.withNoData });
      }
    }, [sortBy]);

    const onChangeGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const search = e.currentTarget.value;
      setGlobalSearch(search);
    };

    useEffect(() => {
      //setApiInput({ ...apiInput, filters: { ...filters, globalSearch: debouncedGlobalSearch.trim() } });
      onGlobalSearchChange(debouncedGlobalSearch.trim());
    }, [debouncedGlobalSearch]);

    const onKeyDownGlobalSearch = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        //setApiInput({ ...apiInput });
        onGlobalSearchChange(debouncedGlobalSearch.trim());
      }
    };

    useEffect(() => {
      if (apiInput.filters.globalSearch !== globalSearch) {
        setGlobalSearch(apiInput.filters.globalSearch || '');
      }
    }, [apiInput.filters.globalSearch]);

    const resetSort = () => {
      setSortBy({
        by: SortBy.ADVERTISE_DATE,
        direction: SortDirection.ASC,
      });
    };

    // Expose the child function to the parent component
    useImperativeHandle(ref, () => ({
      resetSort,
    }));

    return (
      <StickyGlobalFilters>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={globalSearch}
          placeholder="Search..."
          onKeyDown={onKeyDownGlobalSearch}
          onChange={onChangeGlobalSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {globalSearch && (
                  <IconButton edge="end" onClick={onClearGlobalSearch}>
                    <ClearIcon css={tw`text-blue-500`} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ width: 335, display: 'flex', flexDirection: 'column', paddingLeft: 2 }}>
          <Stack direction="row" spacing={0.5}>
            <FormControl variant="outlined" size="small" style={{ marginTop: '0', width: 160 }}>
              <InputLabel id="sort-by-label">Sort by</InputLabel>
              <Select labelId="sort-by-label" label="Sort by" value={sortBy.by} onChange={handleSortChange}>
                <MenuItem value={SortBy.ADVERTISE_DATE}>Advertise date</MenuItem>
                <MenuItem value={SortBy.COC}>C.O.C</MenuItem>
                <MenuItem value={SortBy.ROI}>R.O.I</MenuItem>
                <MenuItem value={SortBy.BRIICK_PRICE}>Briick price</MenuItem>
                <MenuItem value={SortBy.SALE_VARIATION}>Sales variation</MenuItem>
                <MenuItem value={SortBy.APPRECIATION}>Appreciation</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleSortDirectionChange}
              size="small"
              variant="text"
              startIcon={
                <SortIcon sx={{ transform: sortBy.direction === SortDirection.ASC ? '' : 'rotate(180deg)' }} />
              }
              sx={tw`text-sm`}
            >
              {sortBy.direction === SortDirection.ASC ? `A → Z` : `Z → A`}
            </Button>
          </Stack>
          {
            //(sort.by === Listings_Sort_By.RoiAsc || sort.by === Listings_Sort_By.RoiDes)
          }
          {(sortBy.by === SortBy.ROI || sortBy.by === SortBy.APPRECIATION) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!apiInput.sort.withNoData || false}
                  onChange={handleIncludeNoDataChange}
                  inputProps={{ 'aria-label': 'Exclude no data' }}
                />
              }
              label="Exclude no data"
              style={{ marginTop: '0' }}
            />
          )}
        </Box>
      </StickyGlobalFilters>
    );
  },
);

export default GlobalFiltersSorts;
