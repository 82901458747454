import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/schema/__generated__/graphql-types';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { Maybe } from 'graphql/jsutils/Maybe';
import moment from 'moment';
import parse from 'html-react-parser';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Row = (props: { title: string; value: Maybe<string> | undefined; as: 'amount' | 'text' }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2" sx={tw`text-secondary-500`}>
          {props.title}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" sx={tw`text-secondary-500`}>
          {props.value && !_.isEmpty(props.value) ? (
            props.as === 'text' ? (
              props.value
            ) : (
              <NumberFormat
                value={props.value}
                decimalScale={2}
                fixedDecimalScale
                displayType={'text'}
                thousandSeparator={' '}
                prefix={'$ '}
                renderText={(value: string) => <span>{value}</span>}
              />
            )
          ) : (
            'no data'
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const Lease = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { rental } = props.ads;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
        <Headline>Lease</Headline>
        <Divider />

        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
            <Table
              sx={{
                width: 650,
                '& .MuiTableCell-root': {
                  padding: 1,
                  //border: 1
                },
              }}
              aria-label="simple table"
            >
              <TableBody>
                <Row title="Type" value={rental?.leaseDetails.type} as="text" />
                <Row title="Statut" value={rental?.leaseDetails.statut} as="text" />
                <Row title="Annual Gross Rent" value={`${rental?.leaseDetails.annualGrossRent}`} as="amount" />
                <Row title="Deposit" value={``} as="amount" />
                <Row title="Payments Frequency" value={rental?.leaseDetails.settlementFrequency} as="text" />
                <Row
                  title="Start"
                  value={
                    //rental?.leaseDetails.startDate ? moment(rental?.leaseDetails.startDate).format('YYYY/MM/DD') : ''
                    rental?.leaseDetails.startDate
                      ? moment.utc(rental?.leaseDetails.startDate).format('LL [-] HH:mm [UTC]')
                      : ''
                  }
                  as="text"
                />
                <Row
                  title="End"
                  value={
                    //rental?.leaseDetails.endtDate ? moment(rental?.leaseDetails.endtDate).format('YYYY/MM/DD') : ''
                    rental?.leaseDetails.endtDate
                      ? moment.utc(rental?.leaseDetails.endtDate).format('LL [-] HH:mm [UTC]')
                      : ''
                  }
                  as="text"
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ marginTop: 2, width: 650 }}>
            <Typography variant="subtitle1" sx={{ ...tw`font-bold text-secondary-500` }}>
              Comment
            </Typography>

            <Typography variant="body2">
              {rental?.leaseDetails.description && !_.isEmpty(rental?.leaseDetails.description)
                ? parse(rental?.leaseDetails.description)
                : 'no data'}
            </Typography>
          </Box>
        </Box>
      </ArticleContainer>
    </>
  );
};

export default Lease;
