import { Divider } from '@mui/material';
import { Address } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Container = tw.div`
col-span-2 pr-1
`;
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;
const Val = tw.div`
text-sm text-blue-500
`;

const AddressView = (props: { address: Address }) => {
  const { address } = props;
  const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

  const addressLine_1 = [address.number, address.street]
    .filter((e) => e.trim() !== '')
    .join(' ')
    .trim();
  const city = address.city.name.trim() !== '' ? `${address.city.name.trim()},` : '';
  const addressLine_2 = [city, address.stateRegion, address.zipCode]
    .filter((e) => e.trim() !== '')
    .join(' ')
    .trim();
  return (
    <Container>
      <Heading2>Address</Heading2>
      <Divider variant={undefined} css={tw`bg-secondary-900 mr-2!`} />
      <Val>{addressLine_1}</Val>
      <Val>{addressLine_2}</Val>
      <Val>{regionNames.of(address.country)}</Val>
    </Container>
  );
};

export default AddressView;
