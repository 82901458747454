import { Tooltip } from '@mui/material';
import tw from 'twin.macro';

const Div = tw.div`
cursor-pointer
bg-white overflow-hidden flex items-center rounded-md ml-1 mb-1 p-1 px-2 
text-black uppercase whitespace-nowrap truncate text-blue-500
`;

//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const AdId = (props: { adId: string }) => {
  const handleClick = () => {
    navigator.clipboard.writeText(props.adId);
  };
  return (
    <Tooltip title="Copy">
      <Div {...props} onClick={handleClick}>
        <span css={tw`text-black mr-1`}>Ad-Id:</span>
        {props.adId}
      </Div>
    </Tooltip>
  );
};

export default AdId;
