import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { AssetDestination, AssetType, Metadata } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';

const AssetTypeComponent = (props: { assetType: AssetType }) => {
  const { assetType } = props;
  return (
    <>
      <Box sx={{ width: 850, alignSelf: 'center' }}>
        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Type
        </Typography>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="simple table"
          >
            <TableBody>
              {assetType.destination && assetType.destination !== AssetDestination.Unknown && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    Destination
                  </TableCell>
                  <TableCell align="right">{assetType.destination}</TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell component="th" scope="row">
                  Subtype
                </TableCell>
                <TableCell align="right" sx={tw`capitalize`}>
                  {assetType.type}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell component="th" scope="row">
                  Area
                </TableCell>
                <TableCell align="right" sx={tw`capitalize`}>
                  {assetType.area}
                </TableCell>
              </TableRow>

              {assetType.metadata?.map((e: Metadata, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {e.key}
                  </TableCell>
                  <TableCell align="right" sx={tw`capitalize`}>
                    {e.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AssetTypeComponent;
