import { ListingsActions } from 'redux/reducers/listings';
import { put, takeLatest } from 'redux-saga/effects';
import clientGraphQL from 'graphql/client';
import {
  GetBriicksListingsDocument,
  QueryGetBriicksListingsArgs,
  GetBriicksListingsQuery,
  BriicksListingPaginate,
} from 'graphql/schema/__generated__/graphql-types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getBriicksListingsAsyncRequest(action: PayloadAction<QueryGetBriicksListingsArgs>) {
  const args = action.payload;
  try {
    const result: GetBriicksListingsQuery = yield clientGraphQL.request(GetBriicksListingsDocument, args);
    if (result.getBriicksListings.__typename === 'GetBriicksListingSuccess')
      yield put(
        ListingsActions.getBriicksListingsRequestSuccess(
          result.getBriicksListings.result as unknown as BriicksListingPaginate,
        ),
      );
    else yield put(ListingsActions.getBriicksListingsRequestFaillure(result.getBriicksListings.message));
  } catch (error) {
    yield put(ListingsActions.getBriicksListingsRequestFaillure('error'));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export const ListingsSaga = [
  //reducer-name/reducer-function
  takeLatest('listings/getBriicksListingsRequest', getBriicksListingsAsyncRequest),
];
