import React from 'react';
import tw from 'twin.macro';
import { Tab, Tabs } from '@mui/material';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import styled from '@emotion/styled';
import QuiltedImageList from './quilted';

const Container = tw.div`flex pt-4 flex-col`;
const TabPanel = styled(TabPanelBase)`
  ${tw`mt-1`}
`;

const Pictures = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Tabs
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
        sx={{
          minHeight: 38,
          height: 38,
        }}
        value={value}
        onChange={handleChange}
      >
        <Tab label="Living" />
        <Tab label="Bedroom N°1" />
        <Tab label="Bathromm N°1" />
      </Tabs>
      <TabPanel index={0} value={value}>
        <QuiltedImageList />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <QuiltedImageList />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <QuiltedImageList />
      </TabPanel>
    </Container>
  );
};

export default Pictures;
