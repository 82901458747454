import Documents from './documents';
import Providers from './provider';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from 'components/article/section-container';
import { BriicksListing } from 'graphql/schema/__generated__/graphql-types';

export const elements: IFullAdsSection[] = [
  {
    name: 'Providers',
    element: Providers,
  },
  {
    name: 'Documents',
    element: Documents,
  },
];

const Resources = (props: {
  ads: BriicksListing;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return <V ads={props.ads} id={e.name} key={index} viewPortObserver={props.viewPortObserver} />;
      })}
    </SectionContainer>
  );
};

export default Resources;
