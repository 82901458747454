import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Timestamp: any;
};

export enum Arithmetic_Operator {
  /** BETWEEN */
  Between = 'BETWEEN',
  /** GT */
  Gt = 'GT',
  /** LT */
  Lt = 'LT',
}

export type Address = {
  __typename?: 'Address';
  city: City;
  country: CountryIso2Codes;
  departement?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  stateRegion: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type AddressInput = {
  city: CityInput;
  country: CountryIso2Codes;
  departement?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  stateRegion: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type Ads = {
  __typename?: 'Ads';
  _id: Scalars['ID'];
  asset: Asset;
  briicks: Briicks;
  briicksSource: Listing_Source_Names;
  calendar: Calendar;
  financial: Financial;
  flipping?: Maybe<Flipping>;
  ledger: Ledger;
  maker: Maker;
  makerName: Scalars['String'];
  preferences: Preference;
  property_manager?: Maybe<PropertyManager>;
  rental?: Maybe<Rental>;
  shortCode: Scalars['String'];
  spv: Spv;
  status: AdsStatus;
  title: Scalars['String'];
  tokenizedFractionalized: Scalars['Boolean'];
  transactionType: Array<TransactionType>;
};

export type AdsAssetInput = {
  assetCondition?: InputMaybe<AssetConditionInput>;
  assetFeatures?: InputMaybe<Array<AssetFeaturesInput>>;
  assetType?: InputMaybe<AssetTypeInput>;
  description: Scalars['String'];
  location?: InputMaybe<AssetLocationInput>;
  pictures?: InputMaybe<PicturesInput>;
  plans?: InputMaybe<Array<PlansInput>>;
  video?: InputMaybe<Scalars['String']>;
};

export enum AdsCreationSteps {
  /** ADS-SETUP */
  AdsSetup = 'ADS_SETUP',
  /** ASSET */
  Asset = 'ASSET',
  /** Briicks distribution */
  Distribution = 'DISTRIBUTION',
  /** FINANCIAL */
  Financial = 'FINANCIAL',
  /** INITIAL */
  Initial = 'INITIAL',
  /** Offering calendar */
  OfferingCalendar = 'OFFERING_CALENDAR',
  /** Offering informations */
  OfferingInformations = 'OFFERING_INFORMATIONS',
  Restrictions = 'RESTRICTIONS',
  /** SPV */
  Spv = 'SPV',
  /** Transaction flipping */
  TransactionFlipping = 'TRANSACTION_FLIPPING',
  /** Transaction flipping profitability */
  TransactionFlippingProfitability = 'TRANSACTION_FLIPPING_PROFITABILITY',
  /** Transaction rental */
  TransactionRental = 'TRANSACTION_RENTAL',
  /** Transaction rental profitability */
  TransactionRentalProfitability = 'TRANSACTION_RENTAL_PROFITABILITY',
}

export type AdsDeleteResult = Error | Success;

export type AdsDraft = {
  __typename?: 'AdsDraft';
  _id: Scalars['ID'];
  adsSetup: AdsSetup;
  asset?: Maybe<DraftAdsAsset>;
  briicksMinMax?: Maybe<BriicksMinMax>;
  calendar?: Maybe<Calendar>;
  distribution?: Maybe<Distribution>;
  financial?: Maybe<Financial>;
  flipping?: Maybe<Flipping>;
  rental?: Maybe<Rental>;
  restrictions?: Maybe<BriicksRestrictions>;
  spv?: Maybe<Spv>;
  step: AdsCreationSteps;
};

export type AdsDraftResult = AdsDraft | Error;

export type AdsListing = {
  __typename?: 'AdsListing';
  _id: Scalars['ID'];
  asset: Asset;
  status: AdsStatus;
  title: Scalars['String'];
};

export type AdsListingItemsPaginateResult = {
  __typename?: 'AdsListingItemsPaginateResult';
  docs: Array<AdsListing>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPrevPage?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  pagingCounter: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalDocs: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type AdsListingResult = AdsListingItemsPaginateResult | Error;

export type AdsPublishResult = Ads | Error;

export type AdsRedirection = {
  __typename?: 'AdsRedirection';
  default?: Maybe<Scalars['String']>;
  mode: RedirectionModes;
  multiRedirections?: Maybe<Array<UrlDescription>>;
};

export type AdsRedirectionInput = {
  default?: InputMaybe<Scalars['String']>;
  mode: RedirectionModes;
  multiRedirections?: InputMaybe<Array<UrlDescriptionInput>>;
};

export type AdsResultUnion = Ads | Error;

export type AdsSetup = {
  __typename?: 'AdsSetup';
  currency: CurrencyCodes;
  language: LanguagesCode;
  measurement: MeasurementsCode;
  redirection: AdsRedirection;
  title: Scalars['String'];
  tokenizedFractionalized: Scalars['Boolean'];
  transactionType: Array<TransactionType>;
};

export type AdsSetupInput = {
  currency: CurrencyCodes;
  language: LanguagesCode;
  measurement: MeasurementsCode;
  redirection: AdsRedirectionInput;
  title: Scalars['String'];
  tokenizedFractionalized: Scalars['Boolean'];
  transactionType: Array<TransactionType>;
};

export enum AdsStatus {
  /** ALMOST DONE */
  AlmostDone = 'ALMOST_DONE',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** COMING SOON */
  ComingSoon = 'COMING_SOON',
  /** DRAFT */
  Draft = 'DRAFT',
  /** LAST CHANCE */
  LastChance = 'LAST_CHANCE',
  /** NEW LAUNCH */
  NewLaunch = 'NEW_LAUNCH',
  /** NOT FUNDED */
  NotFunded = 'NOT_FUNDED',
  /** OPEN */
  Open = 'OPEN',
  /** SOLD OUT */
  SoldOut = 'SOLD_OUT',
  Unknown = 'UNKNOWN',
}

export type Application = {
  __typename?: 'Application';
  _id: Scalars['ID'];
  apikey: Scalars['String'];
  appName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  lastUseAt?: Maybe<Scalars['DateTime']>;
};

export type AqarChain = {
  __typename?: 'AqarChain';
  AnnualRental: AqarChainDecimal;
  AqarChain_id: Scalars['String'];
  Discount?: Maybe<AqarChainDecimal>;
  ExtentionDurationForTokenSale?: Maybe<Scalars['DateTime']>;
  Fee?: Maybe<AqarChainDecimal>;
  MaxDurForTokenSale?: Maybe<Scalars['DateTime']>;
  MinDurForTokenSale?: Maybe<Scalars['DateTime']>;
  ROI: AqarChainDecimal;
  TokenizationStatus?: Maybe<Scalars['Boolean']>;
  _id: Scalars['ID'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  decimalPlaces: AqarChainDecimal;
  formattedAddress?: Maybe<Scalars['String']>;
  fundingAimingToRaise: AqarChainDecimal;
  id?: Maybe<Scalars['String']>;
  location: AqarChainLocation;
  maxForFundRaise: AqarChainDecimal;
  minfundToAccept: AqarChainDecimal;
  numberOfTokenForFundRaise: AqarChainDecimal;
  owners?: Maybe<AqarChainUser>;
  ownershipDocuments?: Maybe<AqarChainUser>;
  perForFundRaise: AqarChainDecimal;
  property: AqarChainProperty;
  propertySize: AqarChainPropertySize;
  propertyTitle?: Maybe<Scalars['String']>;
  propertyType?: Maybe<Scalars['String']>;
  propertyValue: AqarChainDecimal;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  submitted?: Maybe<Scalars['Boolean']>;
  tokenAbbreviation?: Maybe<Scalars['String']>;
  tokenName?: Maybe<Scalars['String']>;
  tokenPrice: AqarChainDecimal;
  totalTokenSupply: AqarChainDecimal;
  total_share?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: AqarChainUser;
  valuation?: Maybe<Scalars['Float']>;
  walletTokens?: Maybe<AqarChainDecimal>;
};

export type AqarChainDecimal = {
  __typename?: 'AqarChainDecimal';
  numberDecimal: Scalars['Float'];
};

export type AqarChainDecimalInput = {
  numberDecimal: Scalars['Float'];
};

export type AqarChainInput = {
  AnnualRental: AqarChainDecimalInput;
  AqarChain_id: Scalars['String'];
  Discount?: InputMaybe<AqarChainDecimalInput>;
  ExtentionDurationForTokenSale?: InputMaybe<Scalars['DateTime']>;
  Fee?: InputMaybe<AqarChainDecimalInput>;
  MaxDurForTokenSale?: InputMaybe<Scalars['DateTime']>;
  MinDurForTokenSale?: InputMaybe<Scalars['DateTime']>;
  ROI: AqarChainDecimalInput;
  TokenizationStatus?: InputMaybe<Scalars['Boolean']>;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  decimalPlaces: AqarChainDecimalInput;
  formattedAddress?: InputMaybe<Scalars['String']>;
  fundingAimingToRaise: AqarChainDecimalInput;
  id?: InputMaybe<Scalars['String']>;
  location: AqarChainLocationInput;
  maxForFundRaise: AqarChainDecimalInput;
  minfundToAccept: AqarChainDecimalInput;
  numberOfTokenForFundRaise: AqarChainDecimalInput;
  owners?: InputMaybe<AqarChainUserInput>;
  ownershipDocuments?: InputMaybe<AqarChainUserInput>;
  perForFundRaise: AqarChainDecimalInput;
  property: AqarChainPropertyInput;
  propertySize: AqarChainPropertySizeInput;
  propertyTitle?: InputMaybe<Scalars['String']>;
  propertyType?: InputMaybe<Scalars['String']>;
  propertyValue: AqarChainDecimalInput;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  submitted?: InputMaybe<Scalars['Boolean']>;
  tokenAbbreviation?: InputMaybe<Scalars['String']>;
  tokenName?: InputMaybe<Scalars['String']>;
  tokenPrice: AqarChainDecimalInput;
  totalTokenSupply: AqarChainDecimalInput;
  total_share?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: AqarChainUserInput;
  valuation?: InputMaybe<Scalars['Float']>;
  walletTokens?: InputMaybe<AqarChainDecimalInput>;
};

export type AqarChainLocation = {
  __typename?: 'AqarChainLocation';
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type AqarChainLocationInput = {
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type AqarChainProperty = {
  __typename?: 'AqarChainProperty';
  _id: Scalars['String'];
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  amenities: Array<Scalars['String']>;
  aqarVerified: Scalars['Boolean'];
  belongTo: Scalars['String'];
  city: Scalars['String'];
  company_name?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  cover: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  description: Scalars['String'];
  enquiryUsers: Array<Scalars['String']>;
  featured: Scalars['Boolean'];
  floorPlans: Array<Scalars['String']>;
  fractional_ownership: Scalars['Boolean'];
  id: Scalars['String'];
  likeUsers: Array<Scalars['String']>;
  likes: Scalars['Int'];
  location: AqarChainLocation;
  price: Scalars['Float'];
  propertyFeature: AqarChainPropertyFeature;
  property_for: Scalars['String'];
  property_status?: Maybe<Scalars['String']>;
  property_type: Scalars['String'];
  reviews: Scalars['Int'];
  state: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  tokenizationState: Scalars['Int'];
  unliked: Array<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  video?: Maybe<Scalars['String']>;
  wishlistUsers: Array<Scalars['String']>;
  wishlists: Scalars['Int'];
};

export type AqarChainPropertyFeature = {
  __typename?: 'AqarChainPropertyFeature';
  areaType: Scalars['String'];
  number_of_baths: Scalars['String'];
  number_of_beds: Scalars['String'];
  propertyArea: Scalars['String'];
};

export type AqarChainPropertyFeatureInput = {
  areaType: Scalars['String'];
  number_of_baths: Scalars['String'];
  number_of_beds: Scalars['String'];
  propertyArea: Scalars['String'];
};

export type AqarChainPropertyInput = {
  _id: Scalars['String'];
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  amenities: Array<Scalars['String']>;
  aqarVerified: Scalars['Boolean'];
  belongTo: Scalars['String'];
  city: Scalars['String'];
  company_name?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  cover: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  description: Scalars['String'];
  enquiryUsers: Array<Scalars['String']>;
  featured: Scalars['Boolean'];
  floorPlans: Array<Scalars['String']>;
  fractional_ownership: Scalars['Boolean'];
  id: Scalars['String'];
  likeUsers: Array<Scalars['String']>;
  likes: Scalars['Int'];
  location: AqarChainLocationInput;
  price: Scalars['Float'];
  propertyFeature: AqarChainPropertyFeatureInput;
  property_for: Scalars['String'];
  property_status?: InputMaybe<Scalars['String']>;
  property_type: Scalars['String'];
  reviews: Scalars['Int'];
  state: Scalars['String'];
  status?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  tokenizationState: Scalars['Int'];
  unliked: Array<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  video?: InputMaybe<Scalars['String']>;
  wishlistUsers: Array<Scalars['String']>;
  wishlists: Scalars['Int'];
};

export type AqarChainPropertySize = {
  __typename?: 'AqarChainPropertySize';
  available: Scalars['Float'];
  total: Scalars['Float'];
};

export type AqarChainPropertySizeInput = {
  available: Scalars['Float'];
  total: Scalars['Float'];
};

export type AqarChainUser = {
  __typename?: 'AqarChainUser';
  _id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type AqarChainUserInput = {
  _id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type AquisitionCost = {
  __typename?: 'AquisitionCost';
  closingCost?: Maybe<Array<FinancialMetadata>>;
  metadata?: Maybe<Array<Metadata>>;
  otherCosts?: Maybe<Array<OtherFinancialMetadata>>;
  reserve?: Maybe<Array<FinancialMetadata>>;
  setupCost?: Maybe<Array<FinancialMetadata>>;
  totalCost: Scalars['Float'];
};

export type AquisitionCostInput = {
  closingCost?: InputMaybe<Array<FinancialMetadataInput>>;
  metadata?: InputMaybe<Array<MetadataInput>>;
  otherCosts?: InputMaybe<Array<OtherFinancialMetadataInput>>;
  reserve?: InputMaybe<Array<FinancialMetadataInput>>;
  setupCost?: InputMaybe<Array<FinancialMetadataInput>>;
  totalCost: Scalars['Float'];
};

export type ArithmeticComparator = {
  numbers: Array<Scalars['Float']>;
  operator: Arithmetic_Operator;
};

export type Asset = {
  __typename?: 'Asset';
  assetCondition: AssetCondition;
  assetFeatures: Array<AssetFeatures>;
  assetType: AssetType;
  description: Scalars['String'];
  location: Location;
  pictures: Pictures;
  plans?: Maybe<Array<Plans>>;
  video?: Maybe<Scalars['String']>;
  view_3d?: Maybe<Array<Metadata>>;
};

export type AssetCondition = {
  __typename?: 'AssetCondition';
  description?: Maybe<Scalars['String']>;
  event: AssetEvents;
};

export type AssetConditionInput = {
  description?: InputMaybe<Scalars['String']>;
  event: AssetEventsInput;
};

export enum AssetDestination {
  /** RESIDENTIAL */
  Residential = 'RESIDENTIAL',
  /** UNKNOWN */
  Unknown = 'UNKNOWN',
}

export type AssetEvents = {
  __typename?: 'AssetEvents';
  constructionYear: ConstructionYear;
  eventMetadata?: Maybe<Array<EventMetadata>>;
};

export type AssetEventsInput = {
  constructionYear: ConstructionYearInput;
  eventMetadata?: InputMaybe<Array<EventMetadataInput>>;
};

export type AssetFeatures = {
  __typename?: 'AssetFeatures';
  metadata?: Maybe<Array<Metadata>>;
  name: Scalars['String'];
};

export type AssetFeaturesInput = {
  metadata?: InputMaybe<Array<MetadataInput>>;
  name: Scalars['String'];
};

export type AssetLocation = {
  __typename?: 'AssetLocation';
  address: Address;
  anex?: Maybe<Array<Metadata>>;
  coordinates: Coordinates;
  inside?: Maybe<Array<Metadata>>;
};

export type AssetLocationInput = {
  address: AddressInput;
  anex?: InputMaybe<Array<MetadataInput>>;
  coordinates: CoordinatesInput;
  inside?: InputMaybe<Array<MetadataInput>>;
};

export type AssetType = {
  __typename?: 'AssetType';
  area: Scalars['Float'];
  destination: AssetDestination;
  metadata?: Maybe<Array<Metadata>>;
  type: Scalars['String'];
};

export type AssetTypeInput = {
  area: Scalars['Float'];
  destination: AssetDestination;
  metadata?: InputMaybe<Array<MetadataInput>>;
  type: Scalars['String'];
};

export type AssetValue = {
  __typename?: 'AssetValue';
  metadata?: Maybe<Array<FinancialMetadata>>;
  totalAssetValue: Scalars['Float'];
};

export type AssetValueInput = {
  metadata?: InputMaybe<Array<FinancialMetadataInput>>;
  totalAssetValue: Scalars['Float'];
};

export type BrickS = {
  __typename?: 'BrickS';
  _id: Scalars['ID'];
  address: BrickSn18String;
  address_1: Scalars['String'];
  bathrooms: Scalars['Int'];
  bedrooms: Scalars['Int'];
  brickPrice: Scalars['Int'];
  bricksFunded: Scalars['Int'];
  bricksUser: BrickSBricksUser;
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  capitalGrowth: Scalars['Float'];
  cashReserve: Scalars['Int'];
  country: Scalars['String'];
  createdAt: Scalars['String'];
  debtReimbursement: Scalars['Int'];
  debtRemaining: Scalars['Int'];
  description: BrickSn18String;
  dividendsYield: Scalars['Float'];
  documents: Array<Scalars['String']>;
  floor: Scalars['Int'];
  foundingTarget: Scalars['Int'];
  grossRentPerYear: Scalars['Int'];
  gym?: Maybe<Scalars['Int']>;
  hotTub?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  imageGallery: Array<Scalars['String']>;
  initialDebt: Scalars['Int'];
  interestRate: Scalars['Float'];
  investmentCase: BrickSn18String;
  investors: Scalars['Int'];
  isFunded: Scalars['Boolean'];
  kitchen?: Maybe<Scalars['Int']>;
  lemonwayAccountId: Scalars['String'];
  loanTerms: BrickSn18String;
  loanType: BrickSn18String;
  localisationDescription: BrickSn18String;
  maxCommitmentBricks?: Maybe<Scalars['Int']>;
  minCommitmentBricks?: Maybe<Scalars['Int']>;
  name: BrickSn18String;
  neighborhood?: Maybe<Scalars['Int']>;
  netRentalBeforeTaxes: Scalars['Int'];
  netRentalPerYear: Scalars['Int'];
  numberOfBricks: Scalars['Int'];
  offerDateStart: Scalars['String'];
  offeringName?: Maybe<Scalars['String']>;
  otherPropertyPurchaseCost: Scalars['Int'];
  parking?: Maybe<Scalars['Int']>;
  patio?: Maybe<Scalars['Int']>;
  pool?: Maybe<BrickSn18String>;
  propertyCondition?: Maybe<Scalars['Int']>;
  propertyExpenses: Scalars['Int'];
  propertyPurchaseCost: Scalars['Int'];
  propertyValuation: Scalars['Int'];
  publishStatus: Scalars['String'];
  purchasedDate?: Maybe<Scalars['String']>;
  rentalDividends: Scalars['Float'];
  returnOnInvestment: Scalars['Float'];
  schools?: Maybe<Scalars['Int']>;
  state: Scalars['String'];
  status: Scalars['String'];
  surface: BrickSn18String;
  taxesOnResale: Scalars['Int'];
  taxesPayments: Scalars['Int'];
  totalAcquisitionCost: Scalars['Int'];
  transactionCost: Scalars['Int'];
  transport?: Maybe<BrickSTransport>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedAt_iso: Scalars['DateTime'];
  videoGallery: Array<Scalars['String']>;
};

export type BrickSBricksUser = {
  __typename?: 'BrickSBricksUser';
  currentOwned: Scalars['Int'];
  maxOwned: Scalars['Int'];
};

export type BrickSBricksUserInput = {
  currentOwned: Scalars['Int'];
  maxOwned: Scalars['Int'];
};

export type BrickSEnnabledField = {
  __typename?: 'BrickSEnnabledField';
  enabled?: Maybe<Scalars['Boolean']>;
  text?: Maybe<BrickSn18String>;
};

export type BrickSEnnabledFieldInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<BrickSn18StringInput>;
};

export type BrickSInput = {
  address: BrickSn18StringInput;
  address_1: Scalars['String'];
  bathrooms: Scalars['Int'];
  bedrooms: Scalars['Int'];
  brickPrice: Scalars['Int'];
  bricksFunded: Scalars['Int'];
  bricksUser: BrickSBricksUserInput;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  capitalGrowth: Scalars['Float'];
  cashReserve: Scalars['Int'];
  country: Scalars['String'];
  createdAt: Scalars['String'];
  debtReimbursement: Scalars['Int'];
  debtRemaining: Scalars['Int'];
  description: BrickSn18StringInput;
  dividendsYield: Scalars['Float'];
  documents: Array<Scalars['String']>;
  floor: Scalars['Int'];
  foundingTarget: Scalars['Int'];
  grossRentPerYear: Scalars['Int'];
  gym?: InputMaybe<Scalars['Int']>;
  hotTub?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  imageGallery: Array<Scalars['String']>;
  initialDebt: Scalars['Int'];
  interestRate: Scalars['Float'];
  investmentCase: BrickSn18StringInput;
  investors: Scalars['Int'];
  isFunded: Scalars['Boolean'];
  kitchen?: InputMaybe<Scalars['Int']>;
  lemonwayAccountId: Scalars['String'];
  loanTerms: BrickSn18StringInput;
  loanType: BrickSn18StringInput;
  localisationDescription: BrickSn18StringInput;
  maxCommitmentBricks?: InputMaybe<Scalars['Int']>;
  minCommitmentBricks?: InputMaybe<Scalars['Int']>;
  name: BrickSn18StringInput;
  neighborhood?: InputMaybe<Scalars['Int']>;
  netRentalBeforeTaxes: Scalars['Int'];
  netRentalPerYear: Scalars['Int'];
  numberOfBricks: Scalars['Int'];
  offerDateStart: Scalars['String'];
  offeringName?: InputMaybe<Scalars['String']>;
  otherPropertyPurchaseCost: Scalars['Int'];
  parking?: InputMaybe<Scalars['Int']>;
  patio?: InputMaybe<Scalars['Int']>;
  pool?: InputMaybe<BrickSn18StringInput>;
  propertyCondition?: InputMaybe<Scalars['Int']>;
  propertyExpenses: Scalars['Int'];
  propertyPurchaseCost: Scalars['Int'];
  propertyValuation: Scalars['Int'];
  publishStatus: Scalars['String'];
  purchasedDate?: InputMaybe<Scalars['String']>;
  rentalDividends: Scalars['Float'];
  returnOnInvestment: Scalars['Float'];
  schools?: InputMaybe<Scalars['Int']>;
  state: Scalars['String'];
  status: Scalars['String'];
  surface: BrickSn18StringInput;
  taxesOnResale: Scalars['Int'];
  taxesPayments: Scalars['Int'];
  totalAcquisitionCost: Scalars['Int'];
  transactionCost: Scalars['Int'];
  transport?: InputMaybe<BrickSTransportInput>;
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedAt_iso: Scalars['DateTime'];
  videoGallery: Array<Scalars['String']>;
};

export type BrickSn18String = {
  __typename?: 'BrickSN18String';
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
};

export type BrickSn18StringInput = {
  en?: InputMaybe<Scalars['String']>;
  fr?: InputMaybe<Scalars['String']>;
};

export type BrickSTransport = {
  __typename?: 'BrickSTransport';
  bike?: Maybe<BrickSEnnabledField>;
  boat?: Maybe<BrickSEnnabledField>;
  car?: Maybe<BrickSEnnabledField>;
  plane?: Maybe<BrickSEnnabledField>;
  subway?: Maybe<BrickSEnnabledField>;
  train?: Maybe<BrickSEnnabledField>;
  tramway?: Maybe<BrickSEnnabledField>;
  walk?: Maybe<BrickSEnnabledField>;
};

export type BrickSTransportInput = {
  bike?: InputMaybe<BrickSEnnabledFieldInput>;
  boat?: InputMaybe<BrickSEnnabledFieldInput>;
  car?: InputMaybe<BrickSEnnabledFieldInput>;
  plane?: InputMaybe<BrickSEnnabledFieldInput>;
  subway?: InputMaybe<BrickSEnnabledFieldInput>;
  train?: InputMaybe<BrickSEnnabledFieldInput>;
  tramway?: InputMaybe<BrickSEnnabledFieldInput>;
  walk?: InputMaybe<BrickSEnnabledFieldInput>;
};

export type BrickX = {
  __typename?: 'BrickX';
  _id: Scalars['ID'];
  address: Scalars['String'];
  bathroomCount: Scalars['Int'];
  bedroomCount: Scalars['Int'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  carSpotCount: Scalars['Int'];
  citySlug: Scalars['String'];
  criticalNews: BrickXCriticalNews;
  description: Scalars['String'];
  documents?: Maybe<Array<BrickXDocument>>;
  exchangeDate: Scalars['String'];
  financials: BrickXFinancials;
  floorplan: Scalars['String'];
  geolocation: BrickXGeolocation;
  historicalGrowthKey: Scalars['String'];
  id: Scalars['Int'];
  investmentMetrics: BrickXInvestmentMetrics;
  launchDateTime: Scalars['Timestamp'];
  launchDateTime_iso: Scalars['DateTime'];
  multiPropertyPlan: BrickXMultiPropertyPlan;
  nextValuationDate: Scalars['String'];
  platformSettlementDate: Scalars['String'];
  position: Scalars['Int'];
  postalCode: Scalars['String'];
  propertyCode: Scalars['String'];
  propertyFeatures: Scalars['String'];
  propertyImages?: Maybe<Array<BrickXPropertyImages>>;
  propertyStatus: Scalars['String'];
  propertyType: Scalars['String'];
  reasonsToInvest: Scalars['String'];
  settlementDate: Scalars['String'];
  shortDescription: Scalars['String'];
  sqmArea: Scalars['Int'];
  state: Scalars['String'];
  suburb: Scalars['String'];
  tenantStatus: BrickXTenantStatus;
  tradeCertificateSuburb: Scalars['String'];
  videoUrl: Scalars['String'];
  visibilityState: Scalars['String'];
};

export type BrickXCriticalNews = {
  __typename?: 'BrickXCriticalNews';
  active: Scalars['Boolean'];
  message: Scalars['String'];
};

export type BrickXDebtTerms = {
  __typename?: 'BrickXDebtTerms';
  fixedLoanTerms: Scalars['Int'];
  initialDebt: Scalars['Int'];
  interestRatePerc: Scalars['Float'];
  loanDescription: Scalars['String'];
  loanTermType: Scalars['String'];
  loanTerms: Scalars['Int'];
  outstandingDebt: Scalars['Int'];
  yearlyInterestPayment: Scalars['Float'];
};

export type BrickXDebtTermsInput = {
  fixedLoanTerms: Scalars['Int'];
  initialDebt: Scalars['Int'];
  interestRatePerc: Scalars['Float'];
  loanDescription: Scalars['String'];
  loanTermType: Scalars['String'];
  loanTerms: Scalars['Int'];
  outstandingDebt: Scalars['Int'];
  yearlyInterestPayment: Scalars['Float'];
};

export type BrickXDocument = {
  __typename?: 'BrickXDocument';
  type: Scalars['String'];
  url: Scalars['String'];
};

export type BrickXDocumentInput = {
  type: Scalars['String'];
  url: Scalars['String'];
};

export type BrickXFinancials = {
  __typename?: 'BrickXFinancials';
  acquisitionCost: Scalars['Float'];
  annualAuditValuationFees: Scalars['Int'];
  annualGrossRentalIncome: Scalars['Float'];
  annualInvestmentManagementFee: Scalars['Int'];
  annualNetRentalIncome: Scalars['Float'];
  annualPropertyExpenses: Scalars['Float'];
  annualPropertyManagementFee: Scalars['Int'];
  brickPrice: Scalars['Float'];
  brickValue: Scalars['Float'];
  buyersAgentFees: Scalars['Int'];
  cashReserve: Scalars['Float'];
  councilRates: Scalars['Float'];
  debtTerms?: Maybe<BrickXDebtTerms>;
  discountOnBrickValue: Scalars['Float'];
  equity: Scalars['Float'];
  estimatedROI: Scalars['Float'];
  financialsMonth: Scalars['String'];
  fiveYHistoricalSuburbGrowth: Scalars['Float'];
  gearingEffect: Scalars['Float'];
  grossRentalYield: Scalars['Float'];
  id: Scalars['String'];
  initialBrickPrice: Scalars['Int'];
  initialEquity: Scalars['Int'];
  isIndependentValued: Scalars['Boolean'];
  lVR: Scalars['Float'];
  landlordInsurance: Scalars['Int'];
  lastPropertyValuation: Scalars['Int'];
  latestValuationDate: Scalars['String'];
  latestValue: Scalars['Float'];
  legalProfessional: Scalars['Float'];
  liveCashReserve: Scalars['Float'];
  lowestAvailableBrickPrice?: Maybe<Scalars['Int']>;
  netRentalYield: Scalars['Float'];
  numberBricks: Scalars['Int'];
  oneYearChangeBrickValue?: Maybe<Scalars['Int']>;
  otherAcquisitionCosts: Scalars['Float'];
  otherCashFlow: Scalars['Int'];
  propertyCode: Scalars['String'];
  propertyId: Scalars['Int'];
  propertyLandTax: Scalars['Int'];
  purchasePrice: Scalars['Int'];
  repairsMaintenance: Scalars['Int'];
  stampDuty: Scalars['Float'];
  strata: Scalars['Int'];
  totalPurchasePrice: Scalars['Float'];
  trusteeFee: Scalars['Int'];
  unamortisedAcquisitionCosts: Scalars['Float'];
  valuationConfidenceLevel: Scalars['Int'];
  valuationConfidenceLevelText: Scalars['String'];
  waterRates: Scalars['Float'];
  weeklyRentalIncome: Scalars['Float'];
};

export type BrickXGeolocation = {
  __typename?: 'BrickXGeolocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type BrickXInput = {
  address: Scalars['String'];
  bathroomCount: Scalars['Int'];
  bedroomCount: Scalars['Int'];
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  carSpotCount: Scalars['Int'];
  citySlug: Scalars['String'];
  criticalNews: BrickxCriticalNewsInput;
  description: Scalars['String'];
  documents?: InputMaybe<Array<BrickXDocumentInput>>;
  exchangeDate: Scalars['String'];
  financials: BrickxFinancialsInput;
  floorplan: Scalars['String'];
  geolocation: BrickxGeolocationInput;
  historicalGrowthKey: Scalars['String'];
  id: Scalars['Int'];
  investmentMetrics: BrickxInvestmentMetricsInput;
  launchDateTime: Scalars['Timestamp'];
  launchDateTime_iso: Scalars['DateTime'];
  multiPropertyPlan: BrickxMultiPropertyPlanInput;
  nextValuationDate: Scalars['String'];
  platformSettlementDate: Scalars['String'];
  position: Scalars['Int'];
  postalCode: Scalars['String'];
  propertyCode: Scalars['String'];
  propertyFeatures: Scalars['String'];
  propertyImages?: InputMaybe<Array<BrickXPropertyImagesInput>>;
  propertyStatus: Scalars['String'];
  propertyType: Scalars['String'];
  reasonsToInvest: Scalars['String'];
  settlementDate: Scalars['String'];
  shortDescription: Scalars['String'];
  sqmArea: Scalars['Int'];
  state: Scalars['String'];
  suburb: Scalars['String'];
  tenantStatus: BrickxTenantStatusInput;
  tradeCertificateSuburb: Scalars['String'];
  videoUrl: Scalars['String'];
  visibilityState: Scalars['String'];
};

export type BrickXInvestmentMetrics = {
  __typename?: 'BrickXInvestmentMetrics';
  investors: Scalars['Int'];
  propertyCode: Scalars['String'];
  remainingBricks: Scalars['Int'];
};

export type BrickXMultiPropertyPlan = {
  __typename?: 'BrickXMultiPropertyPlan';
  numberOfProperties: Scalars['Int'];
};

export type BrickXPropertyImages = {
  __typename?: 'BrickXPropertyImages';
  id: Scalars['Int'];
  name: Scalars['String'];
  optimizedUrl: Scalars['String'];
  propertyId: Scalars['Int'];
  resized75Url: Scalars['String'];
  resized252Url: Scalars['String'];
  resized297Url: Scalars['String'];
  resized595Url: Scalars['String'];
};

export type BrickXPropertyImagesInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
  optimizedUrl: Scalars['String'];
  propertyId: Scalars['Int'];
  resized75Url: Scalars['String'];
  resized252Url: Scalars['String'];
  resized297Url: Scalars['String'];
  resized595Url: Scalars['String'];
};

export type BrickXTenantStatus = {
  __typename?: 'BrickXTenantStatus';
  leaseAgreementSigned: Scalars['Boolean'];
  leaseLength: Scalars['Int'];
  leaseStartDate: Scalars['String'];
  leaseTerm: Scalars['String'];
  rentContractExpiryDate: Scalars['String'];
  tenanted: Scalars['Boolean'];
};

export type BrickxCriticalNewsInput = {
  active: Scalars['Boolean'];
  message: Scalars['String'];
};

export type BrickxFinancialsInput = {
  acquisitionCost: Scalars['Float'];
  annualAuditValuationFees: Scalars['Int'];
  annualGrossRentalIncome: Scalars['Float'];
  annualInvestmentManagementFee: Scalars['Int'];
  annualNetRentalIncome: Scalars['Float'];
  annualPropertyExpenses: Scalars['Float'];
  annualPropertyManagementFee: Scalars['Int'];
  brickPrice: Scalars['Float'];
  brickValue: Scalars['Float'];
  buyersAgentFees: Scalars['Int'];
  cashReserve: Scalars['Float'];
  councilRates: Scalars['Float'];
  debtTerms?: InputMaybe<BrickXDebtTermsInput>;
  discountOnBrickValue: Scalars['Float'];
  equity: Scalars['Float'];
  estimatedROI: Scalars['Float'];
  financialsMonth: Scalars['String'];
  fiveYHistoricalSuburbGrowth: Scalars['Float'];
  gearingEffect: Scalars['Float'];
  grossRentalYield: Scalars['Float'];
  id: Scalars['String'];
  initialBrickPrice: Scalars['Int'];
  initialEquity: Scalars['Int'];
  isIndependentValued: Scalars['Boolean'];
  lVR: Scalars['Float'];
  landlordInsurance: Scalars['Int'];
  lastPropertyValuation: Scalars['Int'];
  latestValuationDate: Scalars['String'];
  latestValue: Scalars['Float'];
  legalProfessional: Scalars['Float'];
  liveCashReserve: Scalars['Float'];
  lowestAvailableBrickPrice?: InputMaybe<Scalars['Int']>;
  netRentalYield: Scalars['Float'];
  numberBricks: Scalars['Int'];
  oneYearChangeBrickValue?: InputMaybe<Scalars['Int']>;
  otherAcquisitionCosts: Scalars['Float'];
  otherCashFlow: Scalars['Int'];
  propertyCode: Scalars['String'];
  propertyId: Scalars['Int'];
  propertyLandTax: Scalars['Int'];
  purchasePrice: Scalars['Int'];
  repairsMaintenance: Scalars['Int'];
  stampDuty: Scalars['Float'];
  strata: Scalars['Int'];
  totalPurchasePrice: Scalars['Float'];
  trusteeFee: Scalars['Int'];
  unamortisedAcquisitionCosts: Scalars['Float'];
  valuationConfidenceLevel: Scalars['Int'];
  valuationConfidenceLevelText: Scalars['String'];
  waterRates: Scalars['Float'];
  weeklyRentalIncome: Scalars['Float'];
};

export type BrickxGeolocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type BrickxInvestmentMetricsInput = {
  investors: Scalars['Int'];
  propertyCode: Scalars['String'];
  remainingBricks: Scalars['Int'];
};

export type BrickxMultiPropertyPlanInput = {
  numberOfProperties: Scalars['Int'];
};

export type BrickxTenantStatusInput = {
  leaseAgreementSigned: Scalars['Boolean'];
  leaseLength: Scalars['Int'];
  leaseStartDate: Scalars['String'];
  leaseTerm: Scalars['String'];
  rentContractExpiryDate: Scalars['String'];
  tenanted: Scalars['Boolean'];
};

/** Object representing Briicks document */
export type Briicks = {
  __typename?: 'Briicks';
  distribution: Distribution;
  maxBriicksPurchase: Scalars['Int'];
  maxTakers: Scalars['Int'];
  minBriicksPurchase: Scalars['Int'];
  minTakers: Scalars['Int'];
  price: Scalars['Float'];
  restrictions?: Maybe<BriicksRestrictions>;
};

export type BriicksCalculatedMetrics = {
  __typename?: 'BriicksCalculatedMetrics';
  CashFlow: Scalars['Float'];
  CashOnCash: Scalars['Float'];
  ReturnOnInvestment?: Maybe<Scalars['Float']>;
};

export type BriicksCalculatedMetricsInput = {
  CashFlow: Scalars['Float'];
  CashOnCash: Scalars['Float'];
  ReturnOnInvestment?: InputMaybe<Scalars['Float']>;
};

export type BriicksCompanyInfos = {
  __typename?: 'BriicksCompanyInfos';
  entries: Array<Scalars['String']>;
  link: Scalars['String'];
};

export type BriicksCompanyInfosInput = {
  entries: Array<Scalars['String']>;
  link: Scalars['String'];
};

export type BriicksImage = {
  __typename?: 'BriicksImage';
  filePath: Scalars['String'];
  fileType: Scalars['String'];
  height: Scalars['Int'];
  isPrivateFile: Scalars['Boolean'];
  name: Scalars['String'];
  size: Scalars['Int'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type BriicksInput = {
  distribution: DistributionInput;
  maxBriicksPurchase: Scalars['Int'];
  maxTakers: Scalars['Int'];
  minBriicksPurchase: Scalars['Int'];
  minTakers: Scalars['Int'];
  price: Scalars['Float'];
  restrictions?: InputMaybe<BriicksRestrictionsInput>;
};

export type BriicksListing = {
  __typename?: 'BriicksListing';
  _id: Scalars['ID'];
  asset: Asset;
  briicks: Briicks;
  calendar: Calendar;
  financial: Financial;
  flipping?: Maybe<Flipping>;
  ledger: Ledger;
  makerName: Scalars['String'];
  preferences: Preference;
  property_manager?: Maybe<PropertyManager>;
  rental?: Maybe<Rental>;
  shortCode: Scalars['String'];
  source: Listing_Source_Names;
  spv?: Maybe<Spv>;
  status: AdsStatus;
  title: Scalars['String'];
  tokenizedFractionalized: Scalars['Boolean'];
  transactionType: Array<TransactionType>;
};

export type BriicksListingDetailsResult = Error | GetBriicksListingDetailsSuccess;

export type BriicksListingFilters = {
  __typename?: 'BriicksListingFilters';
  countries: Array<CountryIso2Codes>;
  makers: Array<Listing_Source_Names>;
  status: Array<AdsStatus>;
};

export type BriicksListingFiltersResult = Error | GetBriicksListingFiltersSuccess;

export type BriicksListingPaginate = {
  __typename?: 'BriicksListingPaginate';
  docs: Array<BriicksListing>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPrevPage?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  pagingCounter: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalDocs: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type BriicksListingPaginateResult = Error | GetBriicksListingSuccess;

export type BriicksListingsFiltersArgs = {
  countries: Array<CountryIso2Codes>;
  globalSearch?: InputMaybe<Scalars['String']>;
  income?: InputMaybe<ArithmeticComparator>;
  invest?: InputMaybe<ArithmeticComparator>;
  makers: Array<Listing_Source_Names>;
  profitabilityMetrics?: InputMaybe<Array<ProfitabilityMetricArgs>>;
  shortCode?: InputMaybe<Scalars['String']>;
  status: Array<AdsStatus>;
};

export type BriicksListingsSortArgs = {
  by: Listings_Sort_By;
  withNoData?: InputMaybe<Scalars['Boolean']>;
};

export type BriicksMinMax = {
  __typename?: 'BriicksMinMax';
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type BriicksMinMaxInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type BriicksParsedAddress = {
  __typename?: 'BriicksParsedAddress';
  city?: Maybe<Scalars['String']>;
  city_description?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  state_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type BriicksParsedAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  city_description?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  state_code?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Object representing BriicksRestrictionItem document */
export type BriicksRestrictionItem = {
  __typename?: 'BriicksRestrictionItem';
  description?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
  quantity: Scalars['Int'];
  stock: Scalars['Int'];
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type BriicksRestrictionItemInput = {
  description?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<DocumentInput>;
  quantity: Scalars['Int'];
  stock: Scalars['Int'];
  title: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

/** Object representing BriicksRestriction */
export type BriicksRestrictions = {
  __typename?: 'BriicksRestrictions';
  description?: Maybe<Scalars['String']>;
  hasRestriction: Scalars['Boolean'];
  restrictionItems?: Maybe<Array<BriicksRestrictionItem>>;
};

export type BriicksRestrictionsInput = {
  description?: InputMaybe<Scalars['String']>;
  hasRestriction: Scalars['Boolean'];
  restrictionItems?: InputMaybe<Array<BriicksRestrictionItemInput>>;
};

export type Calendar = {
  __typename?: 'Calendar';
  advertise: Scalars['DateTime'];
  otherDates?: Maybe<Array<OtherDate>>;
  salesStart: Scalars['DateTime'];
};

export type CalendarInput = {
  advertise: Scalars['DateTime'];
  otherDates?: InputMaybe<Array<OtherDateInput>>;
  salesStart: Scalars['DateTime'];
};

export type CashFlowPerUnit = {
  __typename?: 'CashFlowPerUnit';
  id?: Maybe<Scalars['String']>;
  monthly_cash_flow?: Maybe<Scalars['Float']>;
  monthly_rent?: Maybe<Scalars['Float']>;
  occupied?: Maybe<Scalars['Boolean']>;
  unit?: Maybe<Scalars['String']>;
};

export type CashFlowPerUnitInput = {
  id?: InputMaybe<Scalars['String']>;
  monthly_cash_flow?: InputMaybe<Scalars['Float']>;
  monthly_rent?: InputMaybe<Scalars['Float']>;
  occupied?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  descriptrion: Scalars['String'];
  name: Scalars['String'];
};

export type CityInput = {
  descriptrion: Scalars['String'];
  name: Scalars['String'];
};

export type ConstructionYear = {
  __typename?: 'ConstructionYear';
  document?: Maybe<Document>;
  year: Scalars['String'];
};

export type ConstructionYearInput = {
  document?: InputMaybe<DocumentInput>;
  year: Scalars['String'];
};

/** Object representing Coordinate */
export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum CountryIso2Codes {
  /** Andorra. */
  Ad = 'AD',
  /** United Arab Emirates. */
  Ae = 'AE',
  /** Afghanistan. */
  Af = 'AF',
  /** Antigua and Barbuda. */
  Ag = 'AG',
  /** Anguilla. */
  Ai = 'AI',
  /** Albania. */
  Al = 'AL',
  /** Armenia. */
  Am = 'AM',
  /** Angola. */
  Ao = 'AO',
  /** Antarctica. */
  Aq = 'AQ',
  /** Argentina. */
  Ar = 'AR',
  /** American Samoa. */
  As = 'AS',
  /** Austria. */
  At = 'AT',
  /** Australia. */
  Au = 'AU',
  /** Aruba. */
  Aw = 'AW',
  /** Aland Islands. */
  Ax = 'AX',
  /** Azerbaijan. */
  Az = 'AZ',
  /** Bosnia and Herzegovina. */
  Ba = 'BA',
  /** Barbados. */
  Bb = 'BB',
  /** Bangladesh. */
  Bd = 'BD',
  /** Belgium. */
  Be = 'BE',
  /** Burkina Faso. */
  Bf = 'BF',
  /** Bulgaria. */
  Bg = 'BG',
  /** Bahrain. */
  Bh = 'BH',
  /** Burundi. */
  Bi = 'BI',
  /** Benin. */
  Bj = 'BJ',
  /** Saint Barthelemy. */
  Bl = 'BL',
  /** Bermuda. */
  Bm = 'BM',
  /** Brunei. */
  Bn = 'BN',
  /** Bolivia. */
  Bo = 'BO',
  /** Bonaire, Saint Eustatius and Saba . */
  Bq = 'BQ',
  /** Brazil. */
  Br = 'BR',
  /** Bahamas. */
  Bs = 'BS',
  /** Bhutan. */
  Bt = 'BT',
  /** Bouvet Island. */
  Bv = 'BV',
  /** Botswana. */
  Bw = 'BW',
  /** Belarus. */
  By = 'BY',
  /** Belize. */
  Bz = 'BZ',
  /** Canada. */
  Ca = 'CA',
  /** Cocos Islands. */
  Cc = 'CC',
  /** Democratic Republic of the Congo. */
  Cd = 'CD',
  /** Central African Republic. */
  Cf = 'CF',
  /** Republic of the Congo. */
  Cg = 'CG',
  /** Switzerland. */
  Ch = 'CH',
  /** Ivory Coast. */
  Ci = 'CI',
  /** Cook Islands. */
  Ck = 'CK',
  /** Chile. */
  Cl = 'CL',
  /** Cameroon. */
  Cm = 'CM',
  /** China. */
  Cn = 'CN',
  /** Colombia. */
  Co = 'CO',
  /** Costa Rica. */
  Cr = 'CR',
  /** Cuba. */
  Cu = 'CU',
  /** Cape Verde. */
  Cv = 'CV',
  /** Curacao. */
  Cw = 'CW',
  /** Christmas Island. */
  Cx = 'CX',
  /** Cyprus. */
  Cy = 'CY',
  /** Czech Republic. */
  Cz = 'CZ',
  /** Germany. */
  De = 'DE',
  /** Djibouti. */
  Dj = 'DJ',
  /** Denmark. */
  Dk = 'DK',
  /** Dominica. */
  Dm = 'DM',
  /** Dominican Republic. */
  Do = 'DO',
  /** Algeria. */
  Dz = 'DZ',
  /** Ecuador. */
  Ec = 'EC',
  /** Estonia. */
  Ee = 'EE',
  /** Egypt. */
  Eg = 'EG',
  /** Western Sahara. */
  Eh = 'EH',
  /** Eritrea. */
  Er = 'ER',
  /** Spain. */
  Es = 'ES',
  /** Ethiopia. */
  Et = 'ET',
  /** Finland. */
  Fi = 'FI',
  /** Fiji. */
  Fj = 'FJ',
  /** Falkland Islands. */
  Fk = 'FK',
  /** Micronesia. */
  Fm = 'FM',
  /** Faroe Islands. */
  Fo = 'FO',
  /** France. */
  Fr = 'FR',
  /** Gabon. */
  Ga = 'GA',
  /** United Kingdom. */
  Gb = 'GB',
  /** Grenada. */
  Gd = 'GD',
  /** Georgia. */
  Ge = 'GE',
  /** French Guiana. */
  Gf = 'GF',
  /** Guernsey. */
  Gg = 'GG',
  /** Ghana. */
  Gh = 'GH',
  /** Gibraltar. */
  Gi = 'GI',
  /** Greenland. */
  Gl = 'GL',
  /** Gambia. */
  Gm = 'GM',
  /** Guinea. */
  Gn = 'GN',
  /** Guadeloupe. */
  Gp = 'GP',
  /** Equatorial Guinea. */
  Gq = 'GQ',
  /** Greece. */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands. */
  Gs = 'GS',
  /** Guatemala. */
  Gt = 'GT',
  /** Guam. */
  Gu = 'GU',
  /** Guinea-Bissau. */
  Gw = 'GW',
  /** Guyana. */
  Gy = 'GY',
  /** Hong Kong. */
  Hk = 'HK',
  /** Heard Island and McDonald Islands. */
  Hm = 'HM',
  /** Honduras. */
  Hn = 'HN',
  /** Croatia. */
  Hr = 'HR',
  /** Haiti. */
  Ht = 'HT',
  /** Hungary. */
  Hu = 'HU',
  /** Indonesia. */
  Id = 'ID',
  /** Ireland. */
  Ie = 'IE',
  /** Israel. */
  Il = 'IL',
  /** Isle of Man. */
  Im = 'IM',
  /** India. */
  In = 'IN',
  /** British Indian Ocean Territory. */
  Io = 'IO',
  /** Iraq. */
  Iq = 'IQ',
  /** Iran. */
  Ir = 'IR',
  /** Iceland. */
  Is = 'IS',
  /** Italy. */
  It = 'IT',
  /** Jersey. */
  Je = 'JE',
  /** Jamaica. */
  Jm = 'JM',
  /** Jordan. */
  Jo = 'JO',
  /** Japan. */
  Jp = 'JP',
  /** Kenya. */
  Ke = 'KE',
  /** Kyrgyzstan. */
  Kg = 'KG',
  /** Cambodia. */
  Kh = 'KH',
  /** Kiribati. */
  Ki = 'KI',
  /** Comoros. */
  Km = 'KM',
  /** Saint Kitts and Nevis. */
  Kn = 'KN',
  /** North Korea. */
  Kp = 'KP',
  /** South Korea. */
  Kr = 'KR',
  /** Kuwait. */
  Kw = 'KW',
  /** Cayman Islands. */
  Ky = 'KY',
  /** Kazakhstan. */
  Kz = 'KZ',
  /** Laos. */
  La = 'LA',
  /** Lebanon. */
  Lb = 'LB',
  /** Saint Lucia. */
  Lc = 'LC',
  /** Liechtenstein. */
  Li = 'LI',
  /** Sri Lanka. */
  Lk = 'LK',
  /** Liberia. */
  Lr = 'LR',
  /** Lesotho. */
  Ls = 'LS',
  /** Lithuania. */
  Lt = 'LT',
  /** Luxembourg. */
  Lu = 'LU',
  /** Latvia. */
  Lv = 'LV',
  /** Libya. */
  Ly = 'LY',
  /** Morocco. */
  Ma = 'MA',
  /** Monaco. */
  Mc = 'MC',
  /** Moldova. */
  Md = 'MD',
  /** Montenegro. */
  Me = 'ME',
  /** Saint Martin. */
  Mf = 'MF',
  /** Madagascar. */
  Mg = 'MG',
  /** Marshall Islands. */
  Mh = 'MH',
  /** Macedonia. */
  Mk = 'MK',
  /** Mali. */
  Ml = 'ML',
  /** Myanmar. */
  Mm = 'MM',
  /** Mongolia. */
  Mn = 'MN',
  /** Macao. */
  Mo = 'MO',
  /** Northern Mariana Islands. */
  Mp = 'MP',
  /** Martinique. */
  Mq = 'MQ',
  /** Mauritania. */
  Mr = 'MR',
  /** Montserrat. */
  Ms = 'MS',
  /** Malta. */
  Mt = 'MT',
  /** Mauritius. */
  Mu = 'MU',
  /** Maldives. */
  Mv = 'MV',
  /** Malawi. */
  Mw = 'MW',
  /** Mexico. */
  Mx = 'MX',
  /** Malaysia. */
  My = 'MY',
  /** Mozambique. */
  Mz = 'MZ',
  /** Namibia. */
  Na = 'NA',
  /** New Caledonia. */
  Nc = 'NC',
  /** Niger. */
  Ne = 'NE',
  /** Norfolk Island. */
  Nf = 'NF',
  /** Nigeria. */
  Ng = 'NG',
  /** Nicaragua. */
  Ni = 'NI',
  /** Netherlands. */
  Nl = 'NL',
  /** Norway. */
  No = 'NO',
  /** Nepal. */
  Np = 'NP',
  /** Nauru. */
  Nr = 'NR',
  /** Niue. */
  Nu = 'NU',
  /** New Zealand. */
  Nz = 'NZ',
  /** Oman. */
  Om = 'OM',
  /** Panama. */
  Pa = 'PA',
  /** Peru. */
  Pe = 'PE',
  /** French Polynesia. */
  Pf = 'PF',
  /** Papua New Guinea. */
  Pg = 'PG',
  /** Philippines. */
  Ph = 'PH',
  /** Pakistan. */
  Pk = 'PK',
  /** Poland. */
  Pl = 'PL',
  /** Saint Pierre and Miquelon. */
  Pm = 'PM',
  /** Pitcairn. */
  Pn = 'PN',
  /** Puerto Rico. */
  Pr = 'PR',
  /** Palestinian Territory. */
  Ps = 'PS',
  /** Portugal. */
  Pt = 'PT',
  /** Palau. */
  Pw = 'PW',
  /** Paraguay. */
  Py = 'PY',
  /** Qatar. */
  Qa = 'QA',
  /** Reunion. */
  Re = 'RE',
  /** Romania. */
  Ro = 'RO',
  /** Serbia. */
  Rs = 'RS',
  /** Russia. */
  Ru = 'RU',
  /** Rwanda. */
  Rw = 'RW',
  /** Saudi Arabia. */
  Sa = 'SA',
  /** Solomon Islands. */
  Sb = 'SB',
  /** Seychelles. */
  Sc = 'SC',
  /** Sudan. */
  Sd = 'SD',
  /** Sweden. */
  Se = 'SE',
  /** Singapore. */
  Sg = 'SG',
  /** Saint Helena. */
  Sh = 'SH',
  /** Slovenia. */
  Si = 'SI',
  /** Svalbard and Jan Mayen. */
  Sj = 'SJ',
  /** Slovakia. */
  Sk = 'SK',
  /** Sierra Leone. */
  Sl = 'SL',
  /** San Marino. */
  Sm = 'SM',
  /** Senegal. */
  Sn = 'SN',
  /** Somalia. */
  So = 'SO',
  /** Suriname. */
  Sr = 'SR',
  /** South Sudan. */
  Ss = 'SS',
  /** Sao Tome and Principe. */
  St = 'ST',
  /** El Salvador. */
  Sv = 'SV',
  /** Sint Maarten. */
  Sx = 'SX',
  /** Syria. */
  Sy = 'SY',
  /** Swaziland. */
  Sz = 'SZ',
  /** Turks and Caicos Islands. */
  Tc = 'TC',
  /** Chad. */
  Td = 'TD',
  /** French Southern Territories. */
  Tf = 'TF',
  /** Togo. */
  Tg = 'TG',
  /** Thailand. */
  Th = 'TH',
  /** Tajikistan. */
  Tj = 'TJ',
  /** Tokelau. */
  Tk = 'TK',
  /** East Timor. */
  Tl = 'TL',
  /** Turkmenistan. */
  Tm = 'TM',
  /** Tunisia. */
  Tn = 'TN',
  /** Tonga. */
  To = 'TO',
  /** Turkey. */
  Tr = 'TR',
  /** Trinidad and Tobago. */
  Tt = 'TT',
  /** Tuvalu. */
  Tv = 'TV',
  /** Taiwan. */
  Tw = 'TW',
  /** Tanzania. */
  Tz = 'TZ',
  /** Ukraine. */
  Ua = 'UA',
  /** Uganda. */
  Ug = 'UG',
  /** United States Minor Outlying Islands. */
  Um = 'UM',
  /** United States. */
  Us = 'US',
  /** Uruguay. */
  Uy = 'UY',
  /** Uzbekistan. */
  Uz = 'UZ',
  /** Vatican. */
  Va = 'VA',
  /** Saint Vincent and the Grenadines. */
  Vc = 'VC',
  /** Venezuela. */
  Ve = 'VE',
  /** British Virgin Islands. */
  Vg = 'VG',
  /** U.S. Virgin Islands. */
  Vi = 'VI',
  /** Vietnam. */
  Vn = 'VN',
  /** Vanuatu. */
  Vu = 'VU',
  /** Wallis and Futuna. */
  Wf = 'WF',
  /** Samoa. */
  Ws = 'WS',
  /** Kosovo. */
  Xk = 'XK',
  /** Yemen. */
  Ye = 'YE',
  /** Mayotte. */
  Yt = 'YT',
  /** South Africa. */
  Za = 'ZA',
  /** Zambia. */
  Zm = 'ZM',
  /** Zimbabwe. */
  Zw = 'ZW',
}

export enum CurrencyCodes {
  /** United Arab Emirates Dirham (AED). */
  Aed = 'AED',
  /** Afghan Afghani (AFN). */
  Afn = 'AFN',
  /** Albanian Lek (ALL). */
  All = 'ALL',
  /** Armenian Dram (AMD). */
  Amd = 'AMD',
  /** Netherlands Antillean Guilder. */
  Ang = 'ANG',
  /** Angolan Kwanza (AOA). */
  Aoa = 'AOA',
  /** Argentine Pesos (ARS). */
  Ars = 'ARS',
  /** Australian Dollars (AUD). */
  Aud = 'AUD',
  /** Aruban Florin (AWG). */
  Awg = 'AWG',
  /** Azerbaijani Manat (AZN). */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark (BAM). */
  Bam = 'BAM',
  /** Barbadian Dollar (BBD). */
  Bbd = 'BBD',
  /** Bangladesh Taka (BDT). */
  Bdt = 'BDT',
  /** Bulgarian Lev (BGN). */
  Bgn = 'BGN',
  /** Bahraini Dinar (BHD). */
  Bhd = 'BHD',
  /** Burundian Franc (BIF). */
  Bif = 'BIF',
  /** Bermudian Dollar (BMD). */
  Bmd = 'BMD',
  /** Brunei Dollar (BND). */
  Bnd = 'BND',
  /** Bolivian Boliviano (BOB). */
  Bob = 'BOB',
  /** Brazilian Real (BRL). */
  Brl = 'BRL',
  /** Bahamian Dollar (BSD). */
  Bsd = 'BSD',
  /** Bhutanese Ngultrum (BTN). */
  Btn = 'BTN',
  /** Botswana Pula (BWP). */
  Bwp = 'BWP',
  /** Belarusian Ruble (BYN). */
  Byn = 'BYN',
  /** Belize Dollar (BZD). */
  Bzd = 'BZD',
  /** Canadian Dollars (CAD). */
  Cad = 'CAD',
  /** Congolese franc (CDF). */
  Cdf = 'CDF',
  /** Swiss Francs (CHF). */
  Chf = 'CHF',
  /** Chilean Peso (CLP). */
  Clp = 'CLP',
  /** Chinese Yuan Renminbi (CNY). */
  Cny = 'CNY',
  /** Colombian Peso (COP). */
  Cop = 'COP',
  /** Costa Rican Colones (CRC). */
  Crc = 'CRC',
  /** Cape Verdean escudo (CVE). */
  Cve = 'CVE',
  /** Czech Koruny (CZK). */
  Czk = 'CZK',
  /** Djiboutian Franc (DJF). */
  Djf = 'DJF',
  /** Danish Kroner (DKK). */
  Dkk = 'DKK',
  /** Dominican Peso (DOP). */
  Dop = 'DOP',
  /** Algerian Dinar (DZD). */
  Dzd = 'DZD',
  /** Egyptian Pound (EGP). */
  Egp = 'EGP',
  /** Eritrean Nakfa (ERN). */
  Ern = 'ERN',
  /** Ethiopian Birr (ETB). */
  Etb = 'ETB',
  /** Euro (EUR). */
  Eur = 'EUR',
  /** Fijian Dollars (FJD). */
  Fjd = 'FJD',
  /** Falkland Islands Pounds (FKP). */
  Fkp = 'FKP',
  /** United Kingdom Pounds (GBP). */
  Gbp = 'GBP',
  /** Georgian Lari (GEL). */
  Gel = 'GEL',
  /** Ghanaian Cedi (GHS). */
  Ghs = 'GHS',
  /** Gibraltar Pounds (GIP). */
  Gip = 'GIP',
  /** Gambian Dalasi (GMD). */
  Gmd = 'GMD',
  /** Guinean Franc (GNF). */
  Gnf = 'GNF',
  /** Guatemalan Quetzal (GTQ). */
  Gtq = 'GTQ',
  /** Guyanese Dollar (GYD). */
  Gyd = 'GYD',
  /** Hong Kong Dollars (HKD). */
  Hkd = 'HKD',
  /** Honduran Lempira (HNL). */
  Hnl = 'HNL',
  /** Croatian Kuna (HRK). */
  Hrk = 'HRK',
  /** Haitian Gourde (HTG). */
  Htg = 'HTG',
  /** Hungarian Forint (HUF). */
  Huf = 'HUF',
  /** Indonesian Rupiah (IDR). */
  Idr = 'IDR',
  /** Israeli New Shekel (NIS). */
  Ils = 'ILS',
  /** Indian Rupees (INR). */
  Inr = 'INR',
  /** Iraqi Dinar (IQD). */
  Iqd = 'IQD',
  /** Iranian Rial (IRR). */
  Irr = 'IRR',
  /** Icelandic Kronur (ISK). */
  Isk = 'ISK',
  /** Jersey Pound. */
  Jep = 'JEP',
  /** Jamaican Dollars (JMD). */
  Jmd = 'JMD',
  /** Jordanian Dinar (JOD). */
  Jod = 'JOD',
  /** Japanese Yen (JPY). */
  Jpy = 'JPY',
  /** Kenyan Shilling (KES). */
  Kes = 'KES',
  /** Kyrgyzstani Som (KGS). */
  Kgs = 'KGS',
  /** Cambodian Riel. */
  Khr = 'KHR',
  /** Kiribati Dollar (KID). */
  Kid = 'KID',
  /** Comorian Franc (KMF). */
  Kmf = 'KMF',
  /** South Korean Won (KRW). */
  Krw = 'KRW',
  /** Kuwaiti Dinar (KWD). */
  Kwd = 'KWD',
  /** Cayman Dollars (KYD). */
  Kyd = 'KYD',
  /** Kazakhstani Tenge (KZT). */
  Kzt = 'KZT',
  /** Laotian Kip (LAK). */
  Lak = 'LAK',
  /** Lebanese Pounds (LBP). */
  Lbp = 'LBP',
  /** Sri Lankan Rupees (LKR). */
  Lkr = 'LKR',
  /** Liberian Dollar (LRD). */
  Lrd = 'LRD',
  /** Lesotho Loti (LSL). */
  Lsl = 'LSL',
  /** Lithuanian Litai (LTL). */
  Ltl = 'LTL',
  /** Latvian Lati (LVL). */
  Lvl = 'LVL',
  /** Libyan Dinar (LYD). */
  Lyd = 'LYD',
  /** Moroccan Dirham. */
  Mad = 'MAD',
  /** Moldovan Leu (MDL). */
  Mdl = 'MDL',
  /** Malagasy Ariary (MGA). */
  Mga = 'MGA',
  /** Macedonia Denar (MKD). */
  Mkd = 'MKD',
  /** Burmese Kyat (MMK). */
  Mmk = 'MMK',
  /** Mongolian Tugrik. */
  Mnt = 'MNT',
  /** Macanese Pataca (MOP). */
  Mop = 'MOP',
  /** Mauritanian Ouguiya (MRU). */
  Mru = 'MRU',
  /** Mauritian Rupee (MUR). */
  Mur = 'MUR',
  /** Maldivian Rufiyaa (MVR). */
  Mvr = 'MVR',
  /** Malawian Kwacha (MWK). */
  Mwk = 'MWK',
  /** Mexican Pesos (MXN). */
  Mxn = 'MXN',
  /** Malaysian Ringgits (MYR). */
  Myr = 'MYR',
  /** Mozambican Metical. */
  Mzn = 'MZN',
  /** Namibian Dollar. */
  Nad = 'NAD',
  /** Nigerian Naira (NGN). */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba (NIO). */
  Nio = 'NIO',
  /** Norwegian Kroner (NOK). */
  Nok = 'NOK',
  /** Nepalese Rupee (NPR). */
  Npr = 'NPR',
  /** New Zealand Dollars (NZD). */
  Nzd = 'NZD',
  /** Omani Rial (OMR). */
  Omr = 'OMR',
  /** Panamian Balboa (PAB). */
  Pab = 'PAB',
  /** Peruvian Nuevo Sol (PEN). */
  Pen = 'PEN',
  /** Papua New Guinean Kina (PGK). */
  Pgk = 'PGK',
  /** Philippine Peso (PHP). */
  Php = 'PHP',
  /** Pakistani Rupee (PKR). */
  Pkr = 'PKR',
  /** Polish Zlotych (PLN). */
  Pln = 'PLN',
  /** Paraguayan Guarani (PYG). */
  Pyg = 'PYG',
  /** Qatari Rial (QAR). */
  Qar = 'QAR',
  /** Romanian Lei (RON). */
  Ron = 'RON',
  /** Serbian dinar (RSD). */
  Rsd = 'RSD',
  /** Russian Rubles (RUB). */
  Rub = 'RUB',
  /** Rwandan Franc (RWF). */
  Rwf = 'RWF',
  /** Saudi Riyal (SAR). */
  Sar = 'SAR',
  /** Solomon Islands Dollar (SBD). */
  Sbd = 'SBD',
  /** Seychellois Rupee (SCR). */
  Scr = 'SCR',
  /** Sudanese Pound (SDG). */
  Sdg = 'SDG',
  /** Swedish Kronor (SEK). */
  Sek = 'SEK',
  /** Singapore Dollars (SGD). */
  Sgd = 'SGD',
  /** Saint Helena Pounds (SHP). */
  Shp = 'SHP',
  /** Sierra Leonean Leone (SLL). */
  Sll = 'SLL',
  /** Somali Shilling (SOS). */
  Sos = 'SOS',
  /** Surinamese Dollar (SRD). */
  Srd = 'SRD',
  /** South Sudanese Pound (SSP). */
  Ssp = 'SSP',
  /** Sao Tome And Principe Dobra (STN). */
  Stn = 'STN',
  /** Syrian Pound (SYP). */
  Syp = 'SYP',
  /** Swazi Lilangeni (SZL). */
  Szl = 'SZL',
  /** Thai baht (THB). */
  Thb = 'THB',
  /** Tajikistani Somoni (TJS). */
  Tjs = 'TJS',
  /** Turkmenistani Manat (TMT). */
  Tmt = 'TMT',
  /** Tunisian Dinar (TND). */
  Tnd = 'TND',
  /** Tongan Pa'anga (TOP). */
  Top = 'TOP',
  /** Turkish Lira (TRY). */
  Try = 'TRY',
  /** Trinidad and Tobago Dollars (TTD). */
  Ttd = 'TTD',
  /** Taiwan Dollars (TWD). */
  Twd = 'TWD',
  /** Tanzanian Shilling (TZS). */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia (UAH). */
  Uah = 'UAH',
  /** Ugandan Shilling (UGX). */
  Ugx = 'UGX',
  /** United States Dollars (USD). */
  Usd = 'USD',
  /** Uruguayan Pesos (UYU). */
  Uyu = 'UYU',
  /** Uzbekistan som (UZS). */
  Uzs = 'UZS',
  /** Venezuelan Bolivares (VED). */
  Ved = 'VED',
  /** Venezuelan Bolivares (VES). */
  Ves = 'VES',
  /** Vietnamese đồng (VND). */
  Vnd = 'VND',
  /** Vanuatu Vatu (VUV). */
  Vuv = 'VUV',
  /** Samoan Tala (WST). */
  Wst = 'WST',
  /** Central African CFA Franc (XAF). */
  Xaf = 'XAF',
  /** East Caribbean Dollar (XCD). */
  Xcd = 'XCD',
  /** West African CFA franc (XOF). */
  Xof = 'XOF',
  /** CFP Franc (XPF). */
  Xpf = 'XPF',
  /** Unrecognized currency. */
  Xxx = 'XXX',
  /** Yemeni Rial (YER). */
  Yer = 'YER',
  /** South African Rand (ZAR). */
  Zar = 'ZAR',
  /** Zambian Kwacha (ZMW). */
  Zmw = 'ZMW',
}

/** Object representing Distribution document */
export type Distribution = {
  __typename?: 'Distribution';
  hasReservedShares: Scalars['Boolean'];
  reservedShare?: Maybe<Array<ReservedShare>>;
  sharesOffSale: Scalars['Int'];
  sharesOnSale: Scalars['Int'];
  stock: Scalars['Int'];
  totalShares: Scalars['Int'];
  unallocated: Scalars['Int'];
};

export type DistributionInput = {
  hasReservedShares: Scalars['Boolean'];
  reservedShare?: InputMaybe<Array<ReservedShareInput>>;
  sharesOffSale: Scalars['Int'];
  sharesOnSale: Scalars['Int'];
  stock: Scalars['Int'];
  totalShares: Scalars['Int'];
  unallocated: Scalars['Int'];
};

export type Document = {
  __typename?: 'Document';
  mimeType: Scalars['String'];
  source: Scalars['String'];
};

export type DocumentInput = {
  mimeType: Scalars['String'];
  source: Scalars['String'];
};

export type DraftAdsAsset = {
  __typename?: 'DraftAdsAsset';
  assetCondition?: Maybe<AssetCondition>;
  assetFeatures?: Maybe<Array<AssetFeatures>>;
  assetType?: Maybe<AssetType>;
  description: Scalars['String'];
  location?: Maybe<AssetLocation>;
  pictures?: Maybe<Pictures>;
  plans?: Maybe<Array<Plans>>;
  video?: Maybe<Scalars['String']>;
};

export type DurationMetadata = {
  __typename?: 'DurationMetadata';
  document?: Maybe<Document>;
  title: Scalars['String'];
  value: Scalars['Int'];
};

export type DurationMetadataInput = {
  document?: InputMaybe<DocumentInput>;
  title: Scalars['String'];
  value: Scalars['Int'];
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type EventMetadata = {
  __typename?: 'EventMetadata';
  date: Scalars['DateTime'];
  document?: Maybe<Document>;
  title: Scalars['String'];
};

export type EventMetadataInput = {
  date: Scalars['DateTime'];
  document?: InputMaybe<DocumentInput>;
  title: Scalars['String'];
};

export type Financial = {
  __typename?: 'Financial';
  aquisitionCost: AquisitionCost;
  assetValue: AssetValue;
  holdingCost: HoldingCost;
};

export type FinancialInput = {
  aquisitionCost: AquisitionCostInput;
  assetValue: AssetValueInput;
  holdingCost: HoldingCostInput;
};

export type FinancialMetadata = {
  __typename?: 'FinancialMetadata';
  document?: Maybe<Document>;
  title: Scalars['String'];
  value: Scalars['Float'];
};

export type FinancialMetadataInput = {
  document?: InputMaybe<DocumentInput>;
  title: Scalars['String'];
  value: Scalars['Float'];
};

export type Flipping = {
  __typename?: 'Flipping';
  income: FlippingIncome;
  investment: FlippingInvestment;
  operationDuration: OperationDuration;
  profitability: FlippingProfitability;
};

export type FlippingIncome = {
  __typename?: 'FlippingIncome';
  metadata: Array<FinancialMetadata>;
  totalAmount: Scalars['Float'];
};

export type FlippingIncomeInput = {
  metadata: Array<FinancialMetadataInput>;
  totalAmount: Scalars['Float'];
};

export type FlippingInput = {
  income: FlippingIncomeInput;
  investment: FlippingInvestmentInput;
  operationDuration: OperationDurationInput;
  profitability: FlippingProfitabilityInput;
};

export type FlippingInvestment = {
  __typename?: 'FlippingInvestment';
  /** in month */
  holdingDuration: Scalars['Int'];
  metadata?: Maybe<Array<FinancialMetadata>>;
  totalAmount: Scalars['Float'];
};

export type FlippingInvestmentInput = {
  /** in month */
  holdingDuration: Scalars['Int'];
  metadata?: InputMaybe<Array<FinancialMetadataInput>>;
  totalAmount: Scalars['Float'];
};

export type FlippingProfitability = {
  __typename?: 'FlippingProfitability';
  /** in month */
  holdingTime: Scalars['Int'];
  profit: Scalars['Float'];
  roiRation: Scalars['Float'];
};

export type FlippingProfitabilityInput = {
  /** in month */
  holdingTime: Scalars['Int'];
  profit: Scalars['Float'];
  roiRation: Scalars['Float'];
};

export type Gallery = {
  __typename?: 'Gallery';
  name: Scalars['String'];
  pictures: Array<Scalars['String']>;
};

export type GalleryInput = {
  name: Scalars['String'];
  pictures: Array<Scalars['String']>;
};

export type GetBriicksListingDetailsSuccess = {
  __typename?: 'GetBriicksListingDetailsSuccess';
  listing: BriicksListing;
};

export type GetBriicksListingFiltersSuccess = {
  __typename?: 'GetBriicksListingFiltersSuccess';
  filters: BriicksListingFilters;
};

export type GetBriicksListingSuccess = {
  __typename?: 'GetBriicksListingSuccess';
  result: BriicksListingPaginate;
};

export type GrapenInvest = {
  __typename?: 'GrapenInvest';
  _geoloc: GrapenInvestGeoloc;
  _id: Scalars['ID'];
  _tags?: Maybe<Array<Scalars['String']>>;
  actual_amount: Scalars['Float'];
  annual_interest_rate: Scalars['Float'];
  bond_amortization_en: Scalars['String'];
  bond_amortization_fr: Scalars['String'];
  bond_maturity_en: Scalars['String'];
  bond_maturity_fr: Scalars['String'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  categories_names_en?: Maybe<Array<Scalars['String']>>;
  categories_names_fr?: Maybe<Array<Scalars['String']>>;
  coupon_period_en: Scalars['String'];
  coupon_period_fr: Scalars['String'];
  currency: Scalars['String'];
  description_en: Scalars['String'];
  description_fr: Scalars['String'];
  full_description_en: Scalars['String'];
  full_description_fr: Scalars['String'];
  full_description_truncated_en: Scalars['String'];
  full_description_truncated_fr: Scalars['String'];
  homepage_image_url: Scalars['String'];
  location_city_en: Scalars['String'];
  location_city_fr: Scalars['String'];
  location_country_en?: Maybe<Scalars['String']>;
  location_country_fr?: Maybe<Scalars['String']>;
  maturity_sort: Scalars['Int'];
  maximum_target: Scalars['Float'];
  minimum_invest: Scalars['Float'];
  minimum_target: Scalars['Float'];
  name_en: Scalars['String'];
  name_fr: Scalars['String'];
  objectID: Scalars['String'];
  partner_id?: Maybe<Scalars['String']>;
  payments_count: Scalars['Int'];
  percentage_display: Scalars['Float'];
  percentage_html: Scalars['Float'];
  percentage_min_width: Scalars['String'];
  site_id: Scalars['Int'];
  slug: Scalars['String'];
  space_id?: Maybe<Scalars['String']>;
  sponsorings?: Maybe<Array<Scalars['String']>>;
  staff_pick: Scalars['Boolean'];
  status: Scalars['String'];
  status_en: Scalars['String'];
  status_filter_en: Scalars['String'];
  status_filter_fr: Scalars['String'];
  status_fr: Scalars['String'];
  target_amount: Scalars['Float'];
  tax_reduction: Scalars['Boolean'];
  time_remaining_en: Scalars['String'];
  time_remaining_fr: Scalars['String'];
  type: Scalars['String'];
  type_en: Scalars['String'];
  type_fr: Scalars['String'];
};

export type GrapenInvestGeoloc = {
  __typename?: 'GrapenInvestGeoloc';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GrapenInvestGeolocInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GrapenInvestInput = {
  _geoloc: GrapenInvestGeolocInput;
  _tags?: InputMaybe<Array<Scalars['String']>>;
  actual_amount: Scalars['Float'];
  annual_interest_rate: Scalars['Float'];
  bond_amortization_en: Scalars['String'];
  bond_amortization_fr: Scalars['String'];
  bond_maturity_en: Scalars['String'];
  bond_maturity_fr: Scalars['String'];
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  categories_names_en?: InputMaybe<Array<Scalars['String']>>;
  categories_names_fr?: InputMaybe<Array<Scalars['String']>>;
  coupon_period_en: Scalars['String'];
  coupon_period_fr: Scalars['String'];
  currency: Scalars['String'];
  description_en: Scalars['String'];
  description_fr: Scalars['String'];
  full_description_en: Scalars['String'];
  full_description_fr: Scalars['String'];
  full_description_truncated_en: Scalars['String'];
  full_description_truncated_fr: Scalars['String'];
  homepage_image_url: Scalars['String'];
  location_city_en: Scalars['String'];
  location_city_fr: Scalars['String'];
  location_country_en?: InputMaybe<Scalars['String']>;
  location_country_fr?: InputMaybe<Scalars['String']>;
  maturity_sort: Scalars['Int'];
  maximum_target: Scalars['Float'];
  minimum_invest: Scalars['Float'];
  minimum_target: Scalars['Float'];
  name_en: Scalars['String'];
  name_fr: Scalars['String'];
  objectID: Scalars['String'];
  partner_id?: InputMaybe<Scalars['String']>;
  payments_count: Scalars['Int'];
  percentage_display: Scalars['Float'];
  percentage_html: Scalars['Float'];
  percentage_min_width: Scalars['String'];
  site_id: Scalars['Int'];
  slug: Scalars['String'];
  space_id?: InputMaybe<Scalars['String']>;
  sponsorings?: InputMaybe<Array<Scalars['String']>>;
  staff_pick: Scalars['Boolean'];
  status: Scalars['String'];
  status_en: Scalars['String'];
  status_filter_en: Scalars['String'];
  status_filter_fr: Scalars['String'];
  status_fr: Scalars['String'];
  target_amount: Scalars['Float'];
  tax_reduction: Scalars['Boolean'];
  time_remaining_en: Scalars['String'];
  time_remaining_fr: Scalars['String'];
  type: Scalars['String'];
  type_en: Scalars['String'];
  type_fr: Scalars['String'];
};

export type HoldingCost = {
  __typename?: 'HoldingCost';
  administrative?: Maybe<Array<FinancialMetadata>>;
  debt?: Maybe<Array<FinancialMetadata>>;
  inusrance?: Maybe<Array<FinancialMetadata>>;
  maintenance?: Maybe<Array<FinancialMetadata>>;
  management?: Maybe<Array<FinancialMetadata>>;
  otherCosts?: Maybe<Array<OtherFinancialMetadata>>;
  taxes?: Maybe<Array<FinancialMetadata>>;
  totalCost: Scalars['Float'];
  utilities?: Maybe<Array<FinancialMetadata>>;
};

export type HoldingCostInput = {
  administrative?: InputMaybe<Array<FinancialMetadataInput>>;
  debt?: InputMaybe<Array<FinancialMetadataInput>>;
  inusrance?: InputMaybe<Array<FinancialMetadataInput>>;
  maintenance?: InputMaybe<Array<FinancialMetadataInput>>;
  management?: InputMaybe<Array<FinancialMetadataInput>>;
  otherCosts?: InputMaybe<Array<OtherFinancialMetadataInput>>;
  taxes?: InputMaybe<Array<FinancialMetadataInput>>;
  totalCost: Scalars['Float'];
  utilities?: InputMaybe<Array<FinancialMetadataInput>>;
};

export type Katapulte = {
  __typename?: 'Katapulte';
  Created_By: Scalars['String'];
  Created_Date: Scalars['Timestamp'];
  Modified_Date: Scalars['Timestamp'];
  _id: Scalars['ID'];
  _lots_number: Scalars['Int'];
  _managing_fees_number: Scalars['Float'];
  _participations_number: Scalars['Float'];
  _rooms_number: Scalars['Int'];
  _type: Scalars['String'];
  _version: Scalars['Int'];
  acquisition_frais_gestion_number: Scalars['Int'];
  active__boolean: Scalars['Boolean'];
  ann_e_construction_date?: Maybe<Scalars['Timestamp']>;
  ann_e_construction_txt_text?: Maybe<Scalars['String']>;
  announced_price_number: Scalars['Float'];
  aper_u_text: Scalars['String'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  charges_assurance_pno_number?: Maybe<Scalars['Float']>;
  classe_dpe_option_dpe: Scalars['String'];
  cr_dit_bancaire_number: Scalars['Float'];
  date_collecte_date: Scalars['Timestamp'];
  date_collecte_date_iso: Scalars['DateTime'];
  date_collecte_txt_text: Scalars['String'];
  date_dispo_date: Scalars['Timestamp'];
  date_notaire_date: Scalars['Timestamp'];
  date_notaire_text: Scalars['String'];
  date_r_servation_txt_text: Scalars['String'];
  date_reservation_date: Scalars['Timestamp'];
  date_reservation_date_iso: Scalars['DateTime'];
  date_versement_date: Scalars['Timestamp'];
  date_versement_txt_text: Scalars['String'];
  description_text: Scalars['String'];
  dividendes_number: Scalars['Float'];
  docs_list_file: Array<Scalars['String']>;
  featured__boolean?: Maybe<Scalars['Boolean']>;
  financier_pv_credit_number?: Maybe<Scalars['Float']>;
  graphes_evolution_population_text: Scalars['String'];
  graphes_evolution_prix_bien_text: Scalars['String'];
  impots_taxes_number: Scalars['Int'];
  katapulte_fees_number: Scalars['Int'];
  katapulte_id: Scalars['String'];
  location_geographic_address: KatapulteLocationGeographicAddress;
  locationtxt_text: Scalars['String'];
  loyers_bruts_number: Scalars['Float'];
  loyers_nets_number: Scalars['Float'];
  main_picture_image: Scalars['String'];
  mensualit__number?: Maybe<Scalars['Int']>;
  notary_fees_number: Scalars['Float'];
  p_n_tration_march__text: Scalars['String'];
  property_views_list_image: Array<Scalars['String']>;
  pv_annuelle_number: Scalars['Float'];
  reno_fees_number: Scalars['Float'];
  renta_affich_e_number: Scalars['Float'];
  rentabilit__brute_number: Scalars['Float'];
  rentabilit__nette_number: Scalars['Float'];
  surface_m___number: Scalars['Float'];
  taux_occupation_number: Scalars['Float'];
  tension_locative_number: Scalars['Float'];
  title_text: Scalars['String'];
  total___financer_number: Scalars['Float'];
  total_a_financer_init_number: Scalars['Float'];
  total_acquisition_number: Scalars['Float'];
  total_charges_number: Scalars['Float'];
  total_loyers_bruts_number: Scalars['Float'];
};

export type KatapulteAddressComponents = {
  __typename?: 'KatapulteAddressComponents';
  city: Scalars['String'];
  country: Scalars['String'];
  country_code: Scalars['String'];
  county: Scalars['String'];
  state: Scalars['String'];
  state_code: Scalars['String'];
  street: Scalars['String'];
  street_number: Scalars['String'];
  zip_code: Scalars['String'];
};

export type KatapulteAddressComponentsInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  country_code: Scalars['String'];
  county: Scalars['String'];
  state: Scalars['String'];
  state_code: Scalars['String'];
  street: Scalars['String'];
  street_number: Scalars['String'];
  zip_code: Scalars['String'];
};

export type KatapulteInput = {
  Created_By: Scalars['String'];
  Created_Date: Scalars['Timestamp'];
  Modified_Date: Scalars['Timestamp'];
  _lots_number: Scalars['Int'];
  _managing_fees_number: Scalars['Float'];
  _participations_number: Scalars['Float'];
  _rooms_number: Scalars['Int'];
  _type: Scalars['String'];
  _version: Scalars['Int'];
  acquisition_frais_gestion_number: Scalars['Int'];
  active__boolean: Scalars['Boolean'];
  ann_e_construction_date?: InputMaybe<Scalars['Timestamp']>;
  ann_e_construction_txt_text?: InputMaybe<Scalars['String']>;
  announced_price_number: Scalars['Float'];
  aper_u_text: Scalars['String'];
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  charges_assurance_pno_number?: InputMaybe<Scalars['Float']>;
  classe_dpe_option_dpe: Scalars['String'];
  cr_dit_bancaire_number: Scalars['Float'];
  date_collecte_date: Scalars['Timestamp'];
  date_collecte_date_iso: Scalars['DateTime'];
  date_collecte_txt_text: Scalars['String'];
  date_dispo_date: Scalars['Timestamp'];
  date_notaire_date: Scalars['Timestamp'];
  date_notaire_text: Scalars['String'];
  date_r_servation_txt_text: Scalars['String'];
  date_reservation_date: Scalars['Timestamp'];
  date_reservation_date_iso: Scalars['DateTime'];
  date_versement_date: Scalars['Timestamp'];
  date_versement_txt_text: Scalars['String'];
  description_text: Scalars['String'];
  dividendes_number: Scalars['Float'];
  docs_list_file: Array<Scalars['String']>;
  featured__boolean?: InputMaybe<Scalars['Boolean']>;
  financier_pv_credit_number?: InputMaybe<Scalars['Float']>;
  graphes_evolution_population_text: Scalars['String'];
  graphes_evolution_prix_bien_text: Scalars['String'];
  impots_taxes_number: Scalars['Int'];
  katapulte_fees_number: Scalars['Int'];
  katapulte_id: Scalars['String'];
  location_geographic_address: KatapulteLocationGeographicAddressInput;
  locationtxt_text: Scalars['String'];
  loyers_bruts_number: Scalars['Float'];
  loyers_nets_number: Scalars['Float'];
  main_picture_image: Scalars['String'];
  mensualit__number?: InputMaybe<Scalars['Int']>;
  notary_fees_number: Scalars['Float'];
  p_n_tration_march__text: Scalars['String'];
  property_views_list_image: Array<Scalars['String']>;
  pv_annuelle_number: Scalars['Float'];
  reno_fees_number: Scalars['Float'];
  renta_affich_e_number: Scalars['Float'];
  rentabilit__brute_number: Scalars['Float'];
  rentabilit__nette_number: Scalars['Float'];
  surface_m___number: Scalars['Float'];
  taux_occupation_number: Scalars['Float'];
  tension_locative_number: Scalars['Float'];
  title_text: Scalars['String'];
  total___financer_number: Scalars['Float'];
  total_a_financer_init_number: Scalars['Float'];
  total_acquisition_number: Scalars['Float'];
  total_charges_number: Scalars['Float'];
  total_loyers_bruts_number: Scalars['Float'];
};

export type KatapulteLocationGeographicAddress = {
  __typename?: 'KatapulteLocationGeographicAddress';
  address: Scalars['String'];
  components: KatapulteAddressComponents;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type KatapulteLocationGeographicAddressInput = {
  address: Scalars['String'];
  components: KatapulteAddressComponentsInput;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export enum Listings_Sort_By {
  /** APPRECIATION_ASC */
  AppreciationAsc = 'APPRECIATION_ASC',
  /** APPRECIATION_DES */
  AppreciationDes = 'APPRECIATION_DES',
  /** COC_ASC */
  CocAsc = 'COC_ASC',
  /** COC_DES */
  CocDes = 'COC_DES',
  /** DATE_ASC */
  DateAsc = 'DATE_ASC',
  /** DATE_DES */
  DateDes = 'DATE_DES',
  /** MIN_INVEST_ASC */
  MinInvestAsc = 'MIN_INVEST_ASC',
  /** MIN_INVEST_DES */
  MinInvestDes = 'MIN_INVEST_DES',
  /** ROI_ASC */
  RoiAsc = 'ROI_ASC',
  /** ROI_DES */
  RoiDes = 'ROI_DES',
  /** SALES_VARIATION_ASC */
  SalesVariationAsc = 'SALES_VARIATION_ASC',
  /** SALES_VARIATION_DEC */
  SalesVariationDec = 'SALES_VARIATION_DEC',
}

export enum Listing_Source_Names {
  AqarChain = 'AQAR_CHAIN',
  Bricks = 'BRICKS',
  Brickx = 'BRICKX',
  BriicksPublicApi = 'BRIICKS_PUBLIC_API',
  GrapenInvest = 'GRAPEN_INVEST',
  InvestDubai = 'INVEST_DUBAI',
  Katapulte = 'KATAPULTE',
  LandShare = 'LAND_SHARE',
  LaPremiereBrique = 'LA_PREMIERE_BRIQUE',
  Lofty = 'LOFTY',
  PowerShift = 'POWER_SHIFT',
  Realt = 'REALT',
  RealtMixed = 'REALT_MIXED',
  Reental = 'REENTAL',
  SmartCrowd = 'SMART_CROWD',
  SmartCrowdDirect = 'SMART_CROWD_DIRECT',
  SmartCrowdPakistan = 'SMART_CROWD_PAKISTAN',
  SolidBlock = 'SOLID_BLOCK',
  Unknown = 'UNKNOWN',
  Vairt = 'VAIRT',
  Vave = 'VAVE',
  VestaEquity = 'VESTA_EQUITY',
}

export type LaPremiereBrique = {
  __typename?: 'LaPremiereBrique';
  _id: Scalars['ID'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  collectes: Scalars['Float'];
  description: Scalars['String'];
  documents: Array<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Scalars['String']>;
  investisseurs: Scalars['Int'];
  ouverture: Scalars['String'];
  roi: Scalars['Float'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type LaPremiereBriqueInput = {
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  collectes: Scalars['Float'];
  description: Scalars['String'];
  documents: Array<Scalars['String']>;
  id: Scalars['String'];
  images: Array<Scalars['String']>;
  investisseurs: Scalars['Int'];
  ouverture: Scalars['String'];
  roi: Scalars['Float'];
  status: Scalars['String'];
  title: Scalars['String'];
};

export type LandShare = {
  __typename?: 'LandShare';
  _id: Scalars['ID'];
  address: Scalars['String'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  city: Scalars['String'];
  country: Scalars['String'];
  financials: LandShareFinancials;
  fullAddress: Scalars['String'];
  images: Array<Scalars['String']>;
  investmentOpportunities?: Maybe<LandShareStoBoxInvestmentOpportunities>;
  investmentSummary: LandShareInvestmentSummary;
  offeringType: Scalars['String'];
  propertyDetails: LandSharePropertyDetails;
  state: Scalars['String'];
  status: Scalars['String'];
};

export type LandShareFinancials = {
  __typename?: 'LandShareFinancials';
  cashOnCashReturn: Scalars['Float'];
  grossRentPerMonth: Scalars['Float'];
  grossRentPerYear: Scalars['Float'];
  insurance: Scalars['Float'];
  monthlyExpenses: Scalars['Float'];
  netAnnualRent: Scalars['Float'];
  netRentPerMonth: Scalars['Float'];
  payoutPeriod: Scalars['String'];
  propertyManagement: Scalars['Float'];
  propertyTaxes: Scalars['Float'];
  rentalYieldByTokenByYear: Scalars['Float'];
  tokenPrice: Scalars['Float'];
  totalTokens: Scalars['Float'];
};

export type LandShareFinancialsInput = {
  cashOnCashReturn: Scalars['Float'];
  grossRentPerMonth: Scalars['Float'];
  grossRentPerYear: Scalars['Float'];
  insurance: Scalars['Float'];
  monthlyExpenses: Scalars['Float'];
  netAnnualRent: Scalars['Float'];
  netRentPerMonth: Scalars['Float'];
  payoutPeriod: Scalars['String'];
  propertyManagement: Scalars['Float'];
  propertyTaxes: Scalars['Float'];
  rentalYieldByTokenByYear: Scalars['Float'];
  tokenPrice: Scalars['Float'];
  totalTokens: Scalars['Float'];
};

export type LandShareInput = {
  address: Scalars['String'];
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  city: Scalars['String'];
  country: Scalars['String'];
  financials: LandShareFinancialsInput;
  fullAddress: Scalars['String'];
  images: Array<Scalars['String']>;
  investmentOpportunities?: InputMaybe<LandShareStoBoxInvestmentOpportunitiesInput>;
  investmentSummary: LandShareInvestmentSummaryInput;
  offeringType: Scalars['String'];
  propertyDetails: LandSharePropertyDetailsInput;
  state: Scalars['String'];
  status: Scalars['String'];
};

export type LandShareInvestmentSummary = {
  __typename?: 'LandShareInvestmentSummary';
  est1YearAppreciation: Scalars['Float'];
  est1YearReturn: Scalars['Float'];
  legalAndAdministrative: Scalars['Float'];
  maintenanceReserve: Scalars['Float'];
  netAnnualCashFlow: Scalars['Float'];
  platformFee: Scalars['Float'];
  property: Scalars['Float'];
  renovationFund: Scalars['Float'];
  totalInvestment: Scalars['Float'];
};

export type LandShareInvestmentSummaryInput = {
  est1YearAppreciation: Scalars['Float'];
  est1YearReturn: Scalars['Float'];
  legalAndAdministrative: Scalars['Float'];
  maintenanceReserve: Scalars['Float'];
  netAnnualCashFlow: Scalars['Float'];
  platformFee: Scalars['Float'];
  property: Scalars['Float'];
  renovationFund: Scalars['Float'];
  totalInvestment: Scalars['Float'];
};

export type LandSharePropertyDetails = {
  __typename?: 'LandSharePropertyDetails';
  bathRoom: Scalars['Int'];
  bedRoom: Scalars['Int'];
  constructionYear: Scalars['String'];
  propertyType: Scalars['String'];
  rentalStatus: Scalars['String'];
  squareFootage: Scalars['Float'];
  totalunits: Scalars['Int'];
};

export type LandSharePropertyDetailsInput = {
  bathRoom: Scalars['Int'];
  bedRoom: Scalars['Int'];
  constructionYear: Scalars['String'];
  propertyType: Scalars['String'];
  rentalStatus: Scalars['String'];
  squareFootage: Scalars['Float'];
  totalunits: Scalars['Int'];
};

export type LandShareStoBoxInvestmentOpportunities = {
  __typename?: 'LandShareSTOBoxInvestmentOpportunities';
  amountCollected: Scalars['String'];
  amountCollected_number: Scalars['Float'];
  chainID: Scalars['Int'];
  country: Scalars['String'];
  dateEnd: Scalars['Timestamp'];
  dateEnd_iso: Scalars['DateTime'];
  dateStart: Scalars['Timestamp'];
  dateStart_iso: Scalars['DateTime'];
  dividends: Scalars['Boolean'];
  equity: Scalars['Boolean'];
  hardCap: Scalars['String'];
  hardCap_number: Scalars['Float'];
  isHaveCap: Scalars['Boolean'];
  kind: Scalars['String'];
  nameOffering: Scalars['String'];
  offeringSize: Scalars['String'];
  priceToken: Scalars['String'];
  primaryID: Scalars['Int'];
  revenueShare: Scalars['Boolean'];
  saleProgress: Scalars['Float'];
  softCap: Scalars['String'];
  specialConditions: Scalars['Boolean'];
  statusOffering: Scalars['String'];
  tickerRegistered: Scalars['String'];
  typeOffering: Scalars['String'];
  typeStock: Scalars['String'];
  votingRights: Scalars['Boolean'];
};

export type LandShareStoBoxInvestmentOpportunitiesInput = {
  amountCollected: Scalars['String'];
  amountCollected_number: Scalars['Float'];
  chainID: Scalars['Int'];
  country: Scalars['String'];
  dateEnd: Scalars['Timestamp'];
  dateEnd_iso: Scalars['DateTime'];
  dateStart: Scalars['Timestamp'];
  dateStart_iso: Scalars['DateTime'];
  dividends: Scalars['Boolean'];
  equity: Scalars['Boolean'];
  hardCap: Scalars['String'];
  hardCap_number: Scalars['Float'];
  isHaveCap: Scalars['Boolean'];
  kind: Scalars['String'];
  nameOffering: Scalars['String'];
  offeringSize: Scalars['String'];
  priceToken: Scalars['String'];
  primaryID: Scalars['Int'];
  revenueShare: Scalars['Boolean'];
  saleProgress: Scalars['Float'];
  softCap: Scalars['String'];
  specialConditions: Scalars['Boolean'];
  statusOffering: Scalars['String'];
  tickerRegistered: Scalars['String'];
  typeOffering: Scalars['String'];
  typeStock: Scalars['String'];
  votingRights: Scalars['Boolean'];
};

export enum LanguagesCode {
  /** Afar (Afaraf). */
  Aar = 'AAR',
  /** Abkhazian (аҧсуа бызшәа, аҧсшәа). */
  Abk = 'ABK',
  /** Afrikaans (Afrikaans). */
  Afr = 'AFR',
  /** Akan (Akan). */
  Aka = 'AKA',
  /** Amharic (አማርኛ). */
  Amh = 'AMH',
  /** Arabic (العربية). */
  Ara = 'ARA',
  /** Aragonese (aragonés). */
  Arg = 'ARG',
  /** Assamese (অসমীয়া). */
  Asm = 'ASM',
  /** Avaric (авар мацӀ, магӀарул мацӀ). */
  Ava = 'AVA',
  /** Avestan (avesta). */
  Ave = 'AVE',
  /** Aymara (aymar aru). */
  Aym = 'AYM',
  /** Azerbaijani (azərbaycan dili). */
  Aze = 'AZE',
  /** Bashkir (башҡорт теле). */
  Bak = 'BAK',
  /** Bambara (bamanankan). */
  Bam = 'BAM',
  /** Belarusian (беларуская мова). */
  Bel = 'BEL',
  /** Bengali (বাংলা). */
  Ben = 'BEN',
  /** Bihari languages (भोजपुरी). */
  Bih = 'BIH',
  /** Bislama (Bislama). */
  Bis = 'BIS',
  /** Tibetan (བོད་ཡིག). */
  Bod = 'BOD',
  /** Bosnian (bosanski jezik). */
  Bos = 'BOS',
  /** Breton (brezhoneg). */
  Bre = 'BRE',
  /** Bulgarian (български език). */
  Bul = 'BUL',
  /** Catalan, Valencian (català, valencià). */
  Cat = 'CAT',
  /** Czech (čeština, český jazyk). */
  Ces = 'CES',
  /** Chamorro (Chamoru). */
  Cha = 'CHA',
  /** Chechen (нохчийн мотт). */
  Che = 'CHE',
  /** Church Slavic, Church Slavonic, Old Church Slavonic, Old Slavonic, Old Bulgarian (ѩзыкъ словѣньскъ). */
  Chu = 'CHU',
  /** Chuvash (чӑваш чӗлхи). */
  Chv = 'CHV',
  /** Cornish (Kernewek). */
  Cor = 'COR',
  /** Corsican (corsu, lingua corsa). */
  Cos = 'COS',
  /** Cree (ᓀᐦᐃᔭᐍᐏᐣ). */
  Cre = 'CRE',
  /** Welsh (Cymraeg). */
  Cym = 'CYM',
  /** Danish (dansk). */
  Dan = 'DAN',
  /** German (Deutsch). */
  Deu = 'DEU',
  /** Divehi, Dhivehi, Maldivian (ދިވެހި). */
  Div = 'DIV',
  /** Dzongkha (རྫོང་ཁ). */
  Dzo = 'DZO',
  /** Greek (modern) (ελληνικά). */
  Ell = 'ELL',
  /** English (English). */
  Eng = 'ENG',
  /** Esperanto (Esperanto). */
  Epo = 'EPO',
  /** Estonian (eesti, eesti keel). */
  Est = 'EST',
  /** Basque (euskara, euskera). */
  Eus = 'EUS',
  /** Ewe (Eʋegbe). */
  Ewe = 'EWE',
  /** Faroese (føroyskt). */
  Fao = 'FAO',
  /** Persian (فارسی). */
  Fas = 'FAS',
  /** Fijian (vosa Vakaviti). */
  Fij = 'FIJ',
  /** Finnish (suomi, suomen kieli). */
  Fin = 'FIN',
  /** French (français, langue française). */
  Fra = 'FRA',
  /** Western Frisian (Frysk). */
  Fry = 'FRY',
  /** Fulah (Fulfulde, Pulaar, Pular). */
  Ful = 'FUL',
  /** Gaelic, Scottish Gaelic (Gàidhlig). */
  Gla = 'GLA',
  /** Irish (Gaeilge). */
  Gle = 'GLE',
  /** Galician (Galego). */
  Glg = 'GLG',
  /** Manx (Gaelg, Gailck). */
  Glv = 'GLV',
  /** Guaraní (Avañe'ẽ). */
  Grn = 'GRN',
  /** Gujarati (ગુજરાતી). */
  Guj = 'GUJ',
  /** Haitian, Haitian Creole (Kreyòl ayisyen). */
  Hat = 'HAT',
  /** Hausa ((Hausa) هَوُسَ). */
  Hau = 'HAU',
  /** Hebrew (modern) (עברית). */
  Heb = 'HEB',
  /** Herero (Otjiherero). */
  Her = 'HER',
  /** Hindi (हिन्दी, हिंदी). */
  Hin = 'HIN',
  /** Hiri Motu (Hiri Motu). */
  Hmo = 'HMO',
  /** Croatian (hrvatski jezik). */
  Hrv = 'HRV',
  /** Hungarian (magyar). */
  Hun = 'HUN',
  /** Armenian (Հայերեն). */
  Hye = 'HYE',
  /** Igbo (Asụsụ Igbo). */
  Ibo = 'IBO',
  /** Ido (Ido). */
  Ido = 'IDO',
  /** Sichuan Yi, Nuosu (ꆈꌠ꒿ Nuosuhxop). */
  Iii = 'III',
  /** Inuktitut (ᐃᓄᒃᑎᑐᑦ). */
  Iku = 'IKU',
  /** Interlingue (Originally called Occidental; then Interlingue after WWII). */
  Ile = 'ILE',
  /** Interlingua (Interlingua). */
  Ina = 'INA',
  /** Indonesian (Bahasa Indonesia). */
  Ind = 'IND',
  /** Inupiaq (Iñupiaq, Iñupiatun). */
  Ipk = 'IPK',
  /** Icelandic (Íslenska). */
  Isl = 'ISL',
  /** Italian (Italiano). */
  Ita = 'ITA',
  /** Javanese (ꦧꦱꦗꦮ, Basa Jawa). */
  Jav = 'JAV',
  /** Japanese (日本語 (にほんご)). */
  Jpn = 'JPN',
  /** Kalaallisut, Greenlandic (kalaallisut, kalaallit oqaasii). */
  Kal = 'KAL',
  /** Kannada (ಕನ್ನಡ). */
  Kan = 'KAN',
  /** Kashmiri (कश्मीरी, كشميري‎). */
  Kas = 'KAS',
  /** Georgian (ქართული). */
  Kat = 'KAT',
  /** Kanuri (Kanuri). */
  Kau = 'KAU',
  /** Kazakh (қазақ тілі). */
  Kaz = 'KAZ',
  /** Central Khmer (ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ). */
  Khm = 'KHM',
  /** Kikuyu, Gikuyu (Gĩkũyũ). */
  Kik = 'KIK',
  /** Kinyarwanda (Ikinyarwanda). */
  Kin = 'KIN',
  /** Kirghiz, Kyrgyz (Кыргызча, Кыргыз тили). */
  Kir = 'KIR',
  /** Komi (коми кыв). */
  Kom = 'KOM',
  /** Kongo (Kikongo). */
  Kon = 'KON',
  /** Korean (한국어). */
  Kor = 'KOR',
  /** Kuanyama, Kwanyama (Kuanyama). */
  Kua = 'KUA',
  /** Kurdish (Kurdî, کوردی‎). */
  Kur = 'KUR',
  /** Lao (ພາສາລາວ). */
  Lao = 'LAO',
  /** Latin (latine, lingua latina). */
  Lat = 'LAT',
  /** Latvian (latviešu valoda). */
  Lav = 'LAV',
  /** Limburgan, Limburger, Limburgish (Limburgs). */
  Lim = 'LIM',
  /** Lingala (Lingála). */
  Lin = 'LIN',
  /** Lithuanian (lietuvių kalba). */
  Lit = 'LIT',
  /** Luxembourgish, Letzeburgesch (Lëtzebuergesch). */
  Ltz = 'LTZ',
  /** Luba-Katanga (Kiluba). */
  Lub = 'LUB',
  /** Ganda (Luganda). */
  Lug = 'LUG',
  /** Marshallese (Kajin M̧ajeļ). */
  Mah = 'MAH',
  /** Malayalam (മലയാളം). */
  Mal = 'MAL',
  /** Marathi (मराठी). */
  Mar = 'MAR',
  /** Macedonian (македонски јазик). */
  Mkd = 'MKD',
  /** Malagasy (fiteny malagasy). */
  Mlg = 'MLG',
  /** Maltese (Malti). */
  Mlt = 'MLT',
  /** Mongolian (Монгол хэл). */
  Mon = 'MON',
  /** Maori (te reo Māori). */
  Mri = 'MRI',
  /** Malay (Bahasa Melayu, بهاس ملايو‎). */
  Msa = 'MSA',
  /** Burmese (ဗမာစာ). */
  Mya = 'MYA',
  /** Nauru (Dorerin Naoero). */
  Nau = 'NAU',
  /** Navajo, Navaho (Diné bizaad). */
  Nav = 'NAV',
  /** South Ndebele (isiNdebele). */
  Nbl = 'NBL',
  /** North Ndebele (isiNdebele). */
  Nde = 'NDE',
  /** Ndonga (Owambo). */
  Ndo = 'NDO',
  /** Nepali (नेपाली). */
  Nep = 'NEP',
  /** Dutch, Flemish (Nederlands, Vlaams). */
  Nld = 'NLD',
  /** Norwegian Nynorsk (Norsk Nynorsk). */
  Nno = 'NNO',
  /** Norwegian Bokmål (Norsk Bokmål). */
  Nob = 'NOB',
  /** Norwegian (Norsk). */
  Nor = 'NOR',
  /** Chichewa, Chewa, Nyanja (chiCheŵa, chinyanja). */
  Nya = 'NYA',
  /** Occitan (occitan, lenga d'òc). */
  Oci = 'OCI',
  /** Ojibwa (ᐊᓂᔑᓈᐯᒧᐎᓐ). */
  Oji = 'OJI',
  /** Oriya (ଓଡ଼ିଆ). */
  Ori = 'ORI',
  /** Oromo (Afaan Oromoo). */
  Orm = 'ORM',
  /** Ossetian, Ossetic (ирон æвзаг). */
  Oss = 'OSS',
  /** Panjabi, Punjabi (ਪੰਜਾਬੀ). */
  Pan = 'PAN',
  /** Pali (पाऴि). */
  Pli = 'PLI',
  /** Polish (język polski, polszczyzna). */
  Pol = 'POL',
  /** Portuguese (Português). */
  Por = 'POR',
  /** Pashto, Pushto (پښتو). */
  Pus = 'PUS',
  /** Quechua (Runa Simi, Kichwa). */
  Que = 'QUE',
  /** Romansh (Rumantsch Grischun). */
  Roh = 'ROH',
  /** Romanian, Moldavian, Moldovan (Română). */
  Ron = 'RON',
  /** Rundi (Ikirundi). */
  Run = 'RUN',
  /** Russian (русский). */
  Rus = 'RUS',
  /** Sango (yângâ tî sängö). */
  Sag = 'SAG',
  /** Sanskrit (संस्कृतम्). */
  San = 'SAN',
  /** Sinhala, Sinhalese (සිංහල). */
  Sin = 'SIN',
  /** Slovak (Slovenčina, Slovenský Jazyk). */
  Slk = 'SLK',
  /** Slovene (Slovenski Jezik, Slovenščina). */
  Slv = 'SLV',
  /** Northern Sami (Davvisámegiella). */
  Sme = 'SME',
  /** Samoan (gagana fa'a Samoa). */
  Smo = 'SMO',
  /** Shona (chiShona). */
  Sna = 'SNA',
  /** Sindhi (सिन्धी, سنڌي، سندھی‎). */
  Snd = 'SND',
  /** Somali (Soomaaliga, af Soomaali). */
  Som = 'SOM',
  /** Southern Sotho (Sesotho). */
  Sot = 'SOT',
  /** Spanish, Castilian (Español). */
  Spa = 'SPA',
  /** Albanian (Shqip). */
  Sqi = 'SQI',
  /** Sardinian (sardu). */
  Srd = 'SRD',
  /** Serbian (српски језик). */
  Srp = 'SRP',
  /** Swati (SiSwati). */
  Ssw = 'SSW',
  /** Sundanese (Basa Sunda). */
  Sun = 'SUN',
  /** Swahili (Kiswahili). */
  Swa = 'SWA',
  /** Swedish (Svenska). */
  Swe = 'SWE',
  /** Tahitian (Reo Tahiti). */
  Tah = 'TAH',
  /** Tamil (தமிழ்). */
  Tam = 'TAM',
  /** Tatar (татар теле, tatar tele). */
  Tat = 'TAT',
  /** Telugu (తెలుగు). */
  Tel = 'TEL',
  /** Tajik (тоҷикӣ, toçikī, تاجیکی‎). */
  Tgk = 'TGK',
  /** Tagalog (Wikang Tagalog). */
  Tgl = 'TGL',
  /** Thai (ไทย). */
  Tha = 'THA',
  /** Tigrinya (ትግርኛ). */
  Tir = 'TIR',
  /** Tongan (Tonga Islands) (Faka Tonga). */
  Ton = 'TON',
  /** Tswana (Setswana). */
  Tsn = 'TSN',
  /** Tsonga (Xitsonga). */
  Tso = 'TSO',
  /** Turkmen (Türkmen, Түркмен). */
  Tuk = 'TUK',
  /** Turkish (Türkçe). */
  Tur = 'TUR',
  /** Twi (Twi). */
  Twi = 'TWI',
  /** Uighur, Uyghur (ئۇيغۇرچە‎, Uyghurche). */
  Uig = 'UIG',
  /** Ukrainian (Українська). */
  Ukr = 'UKR',
  /** Urdu (اردو). */
  Urd = 'URD',
  /** Uzbek (Oʻzbek, Ўзбек, أۇزبېك‎). */
  Uzb = 'UZB',
  /** Venda (Tshivenḓa). */
  Ven = 'VEN',
  /** Vietnamese (Tiếng Việt). */
  Vie = 'VIE',
  /** Volapük (Volapük). */
  Vol = 'VOL',
  /** Walloon (Walon). */
  Wln = 'WLN',
  /** Wolof (Wollof). */
  Wol = 'WOL',
  /** Xhosa (isiXhosa). */
  Xho = 'XHO',
  /** Yiddish (ייִדיש). */
  Yid = 'YID',
  /** Yoruba (Yorùbá). */
  Yor = 'YOR',
  /** Zhuang, Chuang (Saɯ cueŋƅ, Saw cuengh). */
  Zha = 'ZHA',
  /** Chinese (中文 (Zhōngwén), 汉语, 漢語). */
  Zho = 'ZHO',
  /** Zulu (isiZulu). */
  Zul = 'ZUL',
}

export type LeaseDetails = {
  __typename?: 'LeaseDetails';
  annualGrossRent: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  endtDate?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Array<Metadata>>;
  settlementFrequency?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  statut: Scalars['String'];
  type: Scalars['String'];
};

export type LeaseDetailsInput = {
  annualGrossRent: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  endtDate?: InputMaybe<Scalars['DateTime']>;
  metadata?: InputMaybe<Array<MetadataInput>>;
  settlementFrequency?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  statut: Scalars['String'];
  type: Scalars['String'];
};

export type Ledger = {
  __typename?: 'Ledger';
  network: Scalars['String'];
  type: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address: Address;
  anex?: Maybe<Array<Metadata>>;
  coordinates: Coordinates;
  inside?: Maybe<Array<Metadata>>;
};

export type Lofty = {
  __typename?: 'Lofty';
  _id: Scalars['ID'];
  acres?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  ai_recommended?: Maybe<Scalars['Boolean']>;
  appreciation?: Maybe<Scalars['Float']>;
  assetCreator?: Maybe<Scalars['String']>;
  assetId?: Maybe<Scalars['Int']>;
  assetName?: Maybe<Scalars['String']>;
  assetUnit?: Maybe<Scalars['String']>;
  available_date?: Maybe<Scalars['Float']>;
  available_date_iso?: Maybe<Scalars['DateTime']>;
  avmHistory?: Maybe<LoftyAvmHistory>;
  baths?: Maybe<Scalars['Float']>;
  beds?: Maybe<Scalars['Int']>;
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksProjectTimeLine?: Maybe<Array<LoftyProjectTimeLine>>;
  briicksRenatlLeaseDetailsDescription?: Maybe<Scalars['String']>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  briicks_3d_view_link?: Maybe<Scalars['String']>;
  briicks_property_manager?: Maybe<Scalars['String']>;
  briicks_property_manager_website?: Maybe<Scalars['String']>;
  cap_rate?: Maybe<Scalars['Float']>;
  capitalize_fees?: Maybe<Scalars['Boolean']>;
  cash_flow?: Maybe<Scalars['Float']>;
  cashflow_per_unit?: Maybe<Array<CashFlowPerUnit>>;
  city?: Maybe<Scalars['String']>;
  city_transfer_tax?: Maybe<Scalars['Float']>;
  closing_costs?: Maybe<Scalars['Float']>;
  closing_date?: Maybe<Scalars['Float']>;
  closing_date_iso?: Maybe<Scalars['DateTime']>;
  coc?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdAt_iso?: Maybe<Scalars['DateTime']>;
  custom_occupancy?: Maybe<Scalars['String']>;
  dao_app_id?: Maybe<Scalars['Int']>;
  dataType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  description_html?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<LoftyDocument>>;
  featured?: Maybe<Scalars['String']>;
  fullDoccuments?: Maybe<LoftyFullDocuments>;
  gross_yield?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['String']>;
  groupItems?: Maybe<Array<LoftyGroupItem>>;
  hcSlug?: Maybe<Scalars['String']>;
  hideMkt?: Maybe<Scalars['Boolean']>;
  hide_details?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  insurance?: Maybe<Scalars['Float']>;
  irr?: Maybe<Scalars['Float']>;
  isTimeShare?: Maybe<Scalars['Boolean']>;
  is_deposit_made?: Maybe<Scalars['Boolean']>;
  is_occupied?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lease_begins_date?: Maybe<Scalars['Float']>;
  lease_begins_date_iso?: Maybe<Scalars['DateTime']>;
  listing_fee?: Maybe<Scalars['Float']>;
  llc_admin_fee_upfront?: Maybe<Scalars['Int']>;
  llc_admin_fee_yearly?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  maintenance_fees?: Maybe<Scalars['Int']>;
  maintenance_reserve?: Maybe<Scalars['Float']>;
  management_fees?: Maybe<Scalars['Float']>;
  market?: Maybe<Scalars['String']>;
  maxBriicksPurchase?: Maybe<Scalars['Float']>;
  monthly_rent?: Maybe<Scalars['Float']>;
  num_sold?: Maybe<Scalars['Int']>;
  occupied_units?: Maybe<Scalars['Int']>;
  or_replenishment?: Maybe<Scalars['Float']>;
  original_listing_fee?: Maybe<Scalars['Float']>;
  original_sellout_date?: Maybe<Scalars['Float']>;
  original_sellout_date_iso?: Maybe<Scalars['DateTime']>;
  original_starting_date?: Maybe<Scalars['Float']>;
  original_starting_date_iso?: Maybe<Scalars['DateTime']>;
  original_total_investment?: Maybe<Scalars['Float']>;
  participant_app_id?: Maybe<Scalars['Int']>;
  projected_annual_cash_flow?: Maybe<Scalars['Float']>;
  projected_annual_return?: Maybe<Scalars['Float']>;
  projected_rental_yield?: Maybe<Scalars['Float']>;
  property_type?: Maybe<Scalars['String']>;
  redirect?: Maybe<Scalars['String']>;
  sale_price?: Maybe<Scalars['Float']>;
  sellout_date?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  soldout_tracked_date_iso?: Maybe<Scalars['DateTime']>;
  sqft?: Maybe<Scalars['Int']>;
  starting_date?: Maybe<Scalars['Float']>;
  starting_date_iso?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<LoftyTags>>;
  taxes?: Maybe<Scalars['Float']>;
  thumbnail?: Maybe<Scalars['String']>;
  timeline_offering_complete?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Float']>;
  tokens_purchased?: Maybe<Scalars['Int']>;
  total_fees?: Maybe<Scalars['Float']>;
  total_investment?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Int']>;
  trading?: Maybe<Trading>;
  underlying_price?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedAt_iso?: Maybe<Scalars['DateTime']>;
  utilities?: Maybe<Scalars['Float']>;
  utilities_water_sewer?: Maybe<Scalars['Int']>;
  vacancy_reserve?: Maybe<Scalars['Float']>;
  year_built?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type LoftyAvmHistory = {
  __typename?: 'LoftyAVMHistory';
  latest?: Maybe<LoftyLatestOverAll>;
  overall?: Maybe<LoftyLatestOverAll>;
  prices?: Maybe<Array<LoftyPriceHistory>>;
  sales?: Maybe<Array<LoftySaleHistory>>;
  trend?: Maybe<Array<LoftyTrendHistory>>;
};

export type LoftyAvmHistoryInput = {
  latest?: InputMaybe<LoftyLatestOverAllInput>;
  overall?: InputMaybe<LoftyLatestOverAllInput>;
  prices?: InputMaybe<Array<LoftyPriceHistoryInput>>;
  sales?: InputMaybe<Array<LoftySaleHistoryInput>>;
  trend?: InputMaybe<Array<LoftyTrendHistoryInput>>;
};

export type LoftyDocument = {
  __typename?: 'LoftyDocument';
  targetUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LoftyDocumentInput = {
  targetUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type LoftyFullDocuments = {
  __typename?: 'LoftyFullDocuments';
  checksum?: Maybe<Scalars['String']>;
  document?: Maybe<Document>;
};

export type LoftyFullDocumentsInput = {
  checksum?: InputMaybe<Scalars['String']>;
  targetUrl?: InputMaybe<Scalars['String']>;
};

export type LoftyGroupItem = {
  __typename?: 'LoftyGroupItem';
  address?: Maybe<Scalars['String']>;
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  appreciation?: Maybe<Scalars['Float']>;
  assetUnit?: Maybe<Scalars['String']>;
  available_date?: Maybe<Scalars['Float']>;
  available_date_iso?: Maybe<Scalars['DateTime']>;
  baths?: Maybe<Scalars['Float']>;
  beds?: Maybe<Scalars['Int']>;
  cap_rate?: Maybe<Scalars['Float']>;
  capitalize_fees?: Maybe<Scalars['Boolean']>;
  cash_flow?: Maybe<Scalars['Float']>;
  cashflow_per_unit?: Maybe<Array<CashFlowPerUnit>>;
  city?: Maybe<Scalars['String']>;
  city_transfer_tax?: Maybe<Scalars['Int']>;
  closing_costs?: Maybe<Scalars['Float']>;
  coc?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdAt_iso?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<LoftyDocument>>;
  groupId?: Maybe<Scalars['String']>;
  hide_details?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  images?: Maybe<Array<Scalars['String']>>;
  insurance?: Maybe<Scalars['Float']>;
  irr?: Maybe<Scalars['Float']>;
  is_occupied?: Maybe<Scalars['Boolean']>;
  lat?: Maybe<Scalars['Float']>;
  lease_begins_date?: Maybe<Scalars['Float']>;
  lease_begins_date_iso?: Maybe<Scalars['DateTime']>;
  listing_fee?: Maybe<Scalars['Float']>;
  llc_admin_fee_upfront?: Maybe<Scalars['Int']>;
  llc_admin_fee_yearly?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  maintenance_reserve?: Maybe<Scalars['Float']>;
  management_fees?: Maybe<Scalars['Float']>;
  market?: Maybe<Scalars['String']>;
  monthly_rent?: Maybe<Scalars['Float']>;
  original_starting_date?: Maybe<Scalars['Float']>;
  original_starting_date_iso?: Maybe<Scalars['DateTime']>;
  property_type?: Maybe<Scalars['String']>;
  sale_price?: Maybe<Scalars['Float']>;
  sqft?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  taxes?: Maybe<Scalars['Float']>;
  timeline_offering_complete?: Maybe<Scalars['String']>;
  tokens?: Maybe<Scalars['Float']>;
  total_investment?: Maybe<Scalars['Float']>;
  underlying_price?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedAt_iso?: Maybe<Scalars['DateTime']>;
  utilities?: Maybe<Scalars['Float']>;
  vacancy_reserve?: Maybe<Scalars['Float']>;
  year_built?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type LoftyGroupItemInput = {
  address?: InputMaybe<Scalars['String']>;
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  appreciation?: InputMaybe<Scalars['Float']>;
  assetUnit?: InputMaybe<Scalars['String']>;
  available_date?: InputMaybe<Scalars['Float']>;
  available_date_iso?: InputMaybe<Scalars['DateTime']>;
  baths?: InputMaybe<Scalars['Float']>;
  beds?: InputMaybe<Scalars['Int']>;
  cap_rate?: InputMaybe<Scalars['Float']>;
  capitalize_fees?: InputMaybe<Scalars['Boolean']>;
  cash_flow?: InputMaybe<Scalars['Float']>;
  cashflow_per_unit?: InputMaybe<Array<CashFlowPerUnitInput>>;
  city?: InputMaybe<Scalars['String']>;
  city_transfer_tax?: InputMaybe<Scalars['Int']>;
  closing_costs?: InputMaybe<Scalars['Float']>;
  coc?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['Float']>;
  createdAt_iso?: InputMaybe<Scalars['DateTime']>;
  dataType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<LoftyDocumentInput>>;
  groupId?: InputMaybe<Scalars['String']>;
  hide_details?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  images?: InputMaybe<Array<Scalars['String']>>;
  insurance?: InputMaybe<Scalars['Float']>;
  irr?: InputMaybe<Scalars['Float']>;
  is_occupied?: InputMaybe<Scalars['Boolean']>;
  lat?: InputMaybe<Scalars['Float']>;
  lease_begins_date?: InputMaybe<Scalars['Float']>;
  lease_begins_date_iso?: InputMaybe<Scalars['DateTime']>;
  listing_fee?: InputMaybe<Scalars['Float']>;
  llc_admin_fee_upfront?: InputMaybe<Scalars['Int']>;
  llc_admin_fee_yearly?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  maintenance_reserve?: InputMaybe<Scalars['Float']>;
  management_fees?: InputMaybe<Scalars['Float']>;
  market?: InputMaybe<Scalars['String']>;
  monthly_rent?: InputMaybe<Scalars['Float']>;
  original_starting_date?: InputMaybe<Scalars['Float']>;
  original_starting_date_iso?: InputMaybe<Scalars['DateTime']>;
  property_type?: InputMaybe<Scalars['String']>;
  sale_price?: InputMaybe<Scalars['Float']>;
  sqft?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<Scalars['String']>;
  taxes?: InputMaybe<Scalars['Float']>;
  timeline_offering_complete?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<Scalars['Float']>;
  total_investment?: InputMaybe<Scalars['Float']>;
  underlying_price?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
  updatedAt_iso?: InputMaybe<Scalars['DateTime']>;
  utilities?: InputMaybe<Scalars['Float']>;
  vacancy_reserve?: InputMaybe<Scalars['Float']>;
  year_built?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type LoftyInput = {
  acres?: InputMaybe<Scalars['Float']>;
  address?: InputMaybe<Scalars['String']>;
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  ai_recommended?: InputMaybe<Scalars['Boolean']>;
  appreciation?: InputMaybe<Scalars['Float']>;
  assetCreator?: InputMaybe<Scalars['String']>;
  assetId?: InputMaybe<Scalars['Int']>;
  assetName?: InputMaybe<Scalars['String']>;
  assetUnit?: InputMaybe<Scalars['String']>;
  available_date?: InputMaybe<Scalars['Float']>;
  available_date_iso?: InputMaybe<Scalars['DateTime']>;
  avmHistory?: InputMaybe<LoftyAvmHistoryInput>;
  baths?: InputMaybe<Scalars['Float']>;
  beds?: InputMaybe<Scalars['Int']>;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  briicksProjectTimeLine?: InputMaybe<Array<LoftyProjectTimeLineInput>>;
  briicksRenatlLeaseDetailsDescription?: InputMaybe<Scalars['String']>;
  briicks_3d_view_link?: InputMaybe<Scalars['String']>;
  briicks_property_manager?: InputMaybe<Scalars['String']>;
  briicks_property_manager_website?: InputMaybe<Scalars['String']>;
  cap_rate?: InputMaybe<Scalars['Float']>;
  capitalize_fees?: InputMaybe<Scalars['Boolean']>;
  cash_flow?: InputMaybe<Scalars['Float']>;
  cashflow_per_unit?: InputMaybe<Array<CashFlowPerUnitInput>>;
  city?: InputMaybe<Scalars['String']>;
  city_transfer_tax?: InputMaybe<Scalars['Float']>;
  closing_costs?: InputMaybe<Scalars['Float']>;
  closing_date?: InputMaybe<Scalars['Float']>;
  closing_date_iso?: InputMaybe<Scalars['DateTime']>;
  coc?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['Float']>;
  createdAt_iso?: InputMaybe<Scalars['DateTime']>;
  custom_occupancy?: InputMaybe<Scalars['String']>;
  dao_app_id?: InputMaybe<Scalars['Int']>;
  dataType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  description_html?: InputMaybe<Scalars['String']>;
  documents?: InputMaybe<Array<LoftyDocumentInput>>;
  featured?: InputMaybe<Scalars['String']>;
  fullDoccuments?: InputMaybe<LoftyFullDocumentsInput>;
  gross_yield?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['String']>;
  groupItems?: InputMaybe<Array<LoftyGroupItemInput>>;
  hcSlug?: InputMaybe<Scalars['String']>;
  hideMkt?: InputMaybe<Scalars['Boolean']>;
  hide_details?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  images?: InputMaybe<Array<Scalars['String']>>;
  insurance?: InputMaybe<Scalars['Float']>;
  irr?: InputMaybe<Scalars['Float']>;
  isTimeShare?: InputMaybe<Scalars['Boolean']>;
  is_deposit_made?: InputMaybe<Scalars['Boolean']>;
  is_occupied?: InputMaybe<Scalars['Boolean']>;
  lat?: InputMaybe<Scalars['Float']>;
  lease_begins_date?: InputMaybe<Scalars['Float']>;
  lease_begins_date_iso?: InputMaybe<Scalars['DateTime']>;
  listing_fee?: InputMaybe<Scalars['Float']>;
  llc_admin_fee_upfront?: InputMaybe<Scalars['Int']>;
  llc_admin_fee_yearly?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  maintenance_fees?: InputMaybe<Scalars['Int']>;
  maintenance_reserve?: InputMaybe<Scalars['Float']>;
  management_fees?: InputMaybe<Scalars['Float']>;
  market?: InputMaybe<Scalars['String']>;
  maxBriicksPurchase?: InputMaybe<Scalars['Float']>;
  monthly_rent?: InputMaybe<Scalars['Float']>;
  num_sold?: InputMaybe<Scalars['Int']>;
  occupied_units?: InputMaybe<Scalars['Int']>;
  or_replenishment?: InputMaybe<Scalars['Float']>;
  original_listing_fee?: InputMaybe<Scalars['Float']>;
  original_sellout_date?: InputMaybe<Scalars['Float']>;
  original_sellout_date_iso?: InputMaybe<Scalars['DateTime']>;
  original_starting_date?: InputMaybe<Scalars['Float']>;
  original_starting_date_iso?: InputMaybe<Scalars['DateTime']>;
  original_total_investment?: InputMaybe<Scalars['Float']>;
  participant_app_id?: InputMaybe<Scalars['Int']>;
  projected_annual_cash_flow?: InputMaybe<Scalars['Float']>;
  projected_annual_return?: InputMaybe<Scalars['Float']>;
  projected_rental_yield?: InputMaybe<Scalars['Float']>;
  property_type?: InputMaybe<Scalars['String']>;
  redirect?: InputMaybe<Scalars['String']>;
  sale_price?: InputMaybe<Scalars['Float']>;
  sellout_date?: InputMaybe<Scalars['Float']>;
  slug: Scalars['String'];
  sqft?: InputMaybe<Scalars['Int']>;
  starting_date?: InputMaybe<Scalars['Float']>;
  starting_date_iso?: InputMaybe<Scalars['DateTime']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<LoftyTagsInput>>;
  taxes?: InputMaybe<Scalars['Float']>;
  thumbnail?: InputMaybe<Scalars['String']>;
  timeline_offering_complete?: InputMaybe<Scalars['String']>;
  tokens?: InputMaybe<Scalars['Float']>;
  tokens_purchased?: InputMaybe<Scalars['Int']>;
  total_fees?: InputMaybe<Scalars['Float']>;
  total_investment?: InputMaybe<Scalars['Float']>;
  total_units?: InputMaybe<Scalars['Int']>;
  trading?: InputMaybe<TradingInput>;
  underlying_price?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Float']>;
  updatedAt_iso?: InputMaybe<Scalars['DateTime']>;
  utilities?: InputMaybe<Scalars['Float']>;
  utilities_water_sewer?: InputMaybe<Scalars['Int']>;
  vacancy_reserve?: InputMaybe<Scalars['Float']>;
  year_built?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type LoftyLatestOverAll = {
  __typename?: 'LoftyLatestOverAll';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdAt_iso?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['Int']>;
  oldPrice?: Maybe<Scalars['Int']>;
  pctChange?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Int']>;
  priceType?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  recordDate?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  reportLink?: Maybe<Scalars['String']>;
  sentinelPrice?: Maybe<Scalars['Boolean']>;
};

export type LoftyLatestOverAllInput = {
  address?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Float']>;
  createdAt_iso?: InputMaybe<Scalars['DateTime']>;
  dataType?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  newPrice?: InputMaybe<Scalars['Int']>;
  oldPrice?: InputMaybe<Scalars['Int']>;
  pctChange?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Int']>;
  priceType?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
  recordDate?: InputMaybe<Scalars['String']>;
  recordType?: InputMaybe<Scalars['String']>;
  reportLink?: InputMaybe<Scalars['String']>;
  sentinelPrice?: InputMaybe<Scalars['Boolean']>;
};

export type LoftyPriceHistory = {
  __typename?: 'LoftyPriceHistory';
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdAt_iso?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priceType?: Maybe<Scalars['String']>;
  propertyId?: Maybe<Scalars['String']>;
  recordDate?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  reportLink?: Maybe<Scalars['String']>;
  sentinelPrice?: Maybe<Scalars['Boolean']>;
};

export type LoftyPriceHistoryInput = {
  address?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Float']>;
  createdAt_iso?: InputMaybe<Scalars['DateTime']>;
  dataType?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priceType?: InputMaybe<Scalars['String']>;
  propertyId?: InputMaybe<Scalars['String']>;
  recordDate?: InputMaybe<Scalars['String']>;
  recordType?: InputMaybe<Scalars['String']>;
  reportLink?: InputMaybe<Scalars['String']>;
  sentinelPrice?: InputMaybe<Scalars['Boolean']>;
};

export type LoftyProjectTimeLine = {
  __typename?: 'LoftyProjectTimeLine';
  date?: Maybe<Scalars['DateTime']>;
  date_txt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LoftyProjectTimeLineInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  date_txt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LoftySaleHistory = {
  __typename?: 'LoftySaleHistory';
  PK?: Maybe<Scalars['String']>;
  SK?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  apn?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Float']>;
  createdAt_iso?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  hc_addressId?: Maybe<Scalars['Int']>;
  hc_blockId?: Maybe<Scalars['String']>;
  hc_blockgroupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isLoftySale?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Int']>;
  propertyId?: Maybe<Scalars['String']>;
  recordDate?: Maybe<Scalars['String']>;
  recordDocument?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
};

export type LoftySaleHistoryInput = {
  PK?: InputMaybe<Scalars['String']>;
  SK?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  apn?: InputMaybe<Scalars['String']>;
  buyer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Float']>;
  createdAt_iso?: InputMaybe<Scalars['DateTime']>;
  dataType?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
  hc_addressId?: InputMaybe<Scalars['Int']>;
  hc_blockId?: InputMaybe<Scalars['String']>;
  hc_blockgroupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isLoftySale?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Int']>;
  propertyId?: InputMaybe<Scalars['String']>;
  recordDate?: InputMaybe<Scalars['String']>;
  recordDocument?: InputMaybe<Scalars['String']>;
  recordType?: InputMaybe<Scalars['String']>;
  seller?: InputMaybe<Scalars['String']>;
};

export type LoftyTags = {
  __typename?: 'LoftyTags';
  endDate?: Maybe<Scalars['Float']>;
  endDate_iso?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['Float']>;
  startDate_iso?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type LoftyTagsInput = {
  endDate?: InputMaybe<Scalars['Float']>;
  endDate_iso?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['Float']>;
  startDate_iso?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type LoftyTrendHistory = {
  __typename?: 'LoftyTrendHistory';
  createdAt?: Maybe<Scalars['Float']>;
  createdAt_iso?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['String']>;
  newPrice?: Maybe<Scalars['Int']>;
  oldPrice?: Maybe<Scalars['Int']>;
  pctChange?: Maybe<Scalars['Float']>;
  reportLink?: Maybe<Scalars['String']>;
};

export type LoftyTrendHistoryInput = {
  createdAt?: InputMaybe<Scalars['Float']>;
  createdAt_iso?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['String']>;
  newPrice?: InputMaybe<Scalars['Int']>;
  oldPrice?: InputMaybe<Scalars['Int']>;
  pctChange?: InputMaybe<Scalars['Float']>;
  reportLink?: InputMaybe<Scalars['String']>;
};

export type Maker = {
  __typename?: 'Maker';
  _id: Scalars['ID'];
  apiKey: Scalars['String'];
};

export enum MeasurementsCode {
  /** SQUARE FEET (ft²). */
  SquareFeet = 'SQUARE_FEET',
  /** SQUARE METRE (m²). */
  SquareMetre = 'SQUARE_METRE',
}

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type MetadataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAqarChainListing: AqarChain;
  addBrickSListing: BrickS;
  addBrickXListing: BrickX;
  addGrapenInvestListing: GrapenInvest;
  addKatapulteListing: Katapulte;
  addLaPremiereBriqueListing: LaPremiereBrique;
  addLandShareListing: LandShare;
  addLoftyListing: Lofty;
  addRealTListing: RealT;
  addReentalListing: Reental;
  addSmartCrowdListing: SmartCrowd;
  addVairtListing: Vairt;
  addVestaEquityListing: VestaEquity;
  adsDeleteItemById: AdsDeleteResult;
  adsDraftPublish: AdsPublishResult;
  /** create new maker */
  adsSaveDraft: AdsDraft;
  /** create new maker */
  makerCreate: Maker;
  /** create new maker */
  makerUserSignup: Result;
  registerNewApp: Application;
};

export type MutationAddAqarChainListingArgs = {
  listing: AqarChainInput;
};

export type MutationAddBrickSListingArgs = {
  listing: BrickSInput;
};

export type MutationAddBrickXListingArgs = {
  listing: BrickXInput;
};

export type MutationAddGrapenInvestListingArgs = {
  listing: GrapenInvestInput;
};

export type MutationAddKatapulteListingArgs = {
  listing: KatapulteInput;
};

export type MutationAddLaPremiereBriqueListingArgs = {
  listing: LaPremiereBriqueInput;
};

export type MutationAddLandShareListingArgs = {
  listing: LandShareInput;
};

export type MutationAddLoftyListingArgs = {
  listing: LoftyInput;
};

export type MutationAddRealTListingArgs = {
  listing: RealTInput;
};

export type MutationAddReentalListingArgs = {
  listing: ReentalInput;
};

export type MutationAddSmartCrowdListingArgs = {
  listing: SmartCrowdInput;
};

export type MutationAddVairtListingArgs = {
  listing: VairtInput;
};

export type MutationAddVestaEquityListingArgs = {
  listing: VestaEquityInput;
};

export type MutationAdsDeleteItemByIdArgs = {
  _id: Scalars['String'];
};

export type MutationAdsDraftPublishArgs = {
  _id?: InputMaybe<Scalars['String']>;
  adsSetup: AdsSetupInput;
  asset: AdsAssetInput;
  briicksMinMax: BriicksMinMaxInput;
  calendar: CalendarInput;
  distribution: DistributionInput;
  financial: FinancialInput;
  flipping?: InputMaybe<FlippingInput>;
  rental?: InputMaybe<RentalInput>;
  restrictions: BriicksRestrictionsInput;
  spv: SpvInput;
  step: Scalars['String'];
};

export type MutationAdsSaveDraftArgs = {
  _id?: InputMaybe<Scalars['String']>;
  adsSetup: AdsSetupInput;
  asset?: InputMaybe<AdsAssetInput>;
  briicksMinMax?: InputMaybe<BriicksMinMaxInput>;
  calendar?: InputMaybe<CalendarInput>;
  distribution?: InputMaybe<DistributionInput>;
  financial?: InputMaybe<FinancialInput>;
  flipping?: InputMaybe<FlippingInput>;
  rental?: InputMaybe<RentalInput>;
  restrictions?: InputMaybe<BriicksRestrictionsInput>;
  spv?: InputMaybe<SpvInput>;
  step: Scalars['String'];
};

export type MutationMakerUserSignupArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type MutationRegisterNewAppArgs = {
  appName: Scalars['String'];
};

export type OperationDuration = {
  __typename?: 'OperationDuration';
  metadata: Array<DurationMetadata>;
  totalDuration: Scalars['Int'];
};

export type OperationDurationInput = {
  metadata: Array<DurationMetadataInput>;
  totalDuration: Scalars['Int'];
};

export type OtherDate = {
  __typename?: 'OtherDate';
  date?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
};

export type OtherDateInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type OtherDocument = {
  __typename?: 'OtherDocument';
  description: Scalars['String'];
  document: Document;
};

export type OtherDocumentInput = {
  description: Scalars['String'];
  document: DocumentInput;
};

export type OtherFinancialMetadata = {
  __typename?: 'OtherFinancialMetadata';
  metadata: Array<FinancialMetadata>;
  name: Scalars['String'];
};

export type OtherFinancialMetadataInput = {
  metadata: Array<FinancialMetadataInput>;
  name: Scalars['String'];
};

export enum Profitability_Metrics {
  /** APPRECIATION */
  Appreciation = 'APPRECIATION',
  /** COC */
  Coc = 'COC',
  /** R_O_I */
  ROI = 'R_O_I',
}

export type Pictures = {
  __typename?: 'Pictures';
  featured: Scalars['String'];
  galleries?: Maybe<Array<Gallery>>;
};

export type PicturesInput = {
  featured: Scalars['String'];
  galleries?: InputMaybe<Array<GalleryInput>>;
};

export type Plans = {
  __typename?: 'Plans';
  documents: Array<Document>;
  name: Scalars['String'];
};

export type PlansInput = {
  documents: Array<DocumentInput>;
  name: Scalars['String'];
};

export type Preference = {
  __typename?: 'Preference';
  currency: CurrencyCodes;
  language: LanguagesCode;
  measurement: MeasurementsCode;
  redirection: AdsRedirection;
};

export type PreferenceInput = {
  currency: CurrencyCodes;
  language: LanguagesCode;
  measurement: MeasurementsCode;
  redirection: AdsRedirectionInput;
};

export type ProfitabilityMetricArgs = {
  field?: InputMaybe<Profitability_Metrics>;
  value?: InputMaybe<ArithmeticComparator>;
};

export type PropertyManager = {
  __typename?: 'PropertyManager';
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type PropertyManagerInput = {
  name: Scalars['String'];
  website?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  adsListItems: AdsListingResult;
  adsLoadById: AdsResultUnion;
  adsLoadDraft: AdsDraftResult;
  getBriicksListingDetails: BriicksListingDetailsResult;
  getBriicksListingFilters: BriicksListingFiltersResult;
  getBriicksListings: BriicksListingPaginateResult;
};

export type QueryAdsListItemsArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
};

export type QueryAdsLoadByIdArgs = {
  _id: Scalars['String'];
};

export type QueryAdsLoadDraftArgs = {
  _id: Scalars['String'];
};

export type QueryGetBriicksListingDetailsArgs = {
  id: Scalars['String'];
};

export type QueryGetBriicksListingsArgs = {
  filters?: InputMaybe<BriicksListingsFiltersArgs>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<BriicksListingsSortArgs>;
};

export enum Reental_Currency {
  Esd = 'ESD',
  Eur = 'EUR',
}

export enum Reental_Property_Status {
  Draft = 'DRAFT',
  Listed = 'LISTED',
  Listing = 'LISTING',
  Sold = 'SOLD',
  Tokenized = 'TOKENIZED',
  Tokenizing = 'TOKENIZING',
}

export type RealT = {
  __typename?: 'RealT';
  _id: Scalars['ID'];
  asset: Asset;
  briicks: Briicks;
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  calendar: Calendar;
  financial: Financial;
  pageData: Array<RealTPageData>;
  preferences: Preference;
  property_manager?: Maybe<PropertyManager>;
  rental?: Maybe<Rental>;
  salesProgress: Scalars['Float'];
  spv?: Maybe<Spv>;
  status: AdsStatus;
  title: Scalars['String'];
  tokenizedFractionalized: Scalars['Boolean'];
  transactionType: Array<TransactionType>;
};

/** RealTBlockchain model */
export type RealTBlockchain = {
  __typename?: 'RealTBlockchain';
  ethereum: RealTEthereum;
  gnosis_chain: RealTGnosisChain;
  identifier?: Maybe<Scalars['String']>;
  total_tokens?: Maybe<Scalars['String']>;
};

export type RealTBlockchainInput = {
  ethereum: RealTEthereumInput;
  gnosis_chain: RealTGnosisChainInput;
  identifier?: InputMaybe<Scalars['String']>;
  total_tokens?: InputMaybe<Scalars['String']>;
};

/** RealTDetails model */
export type RealTDetails = {
  __typename?: 'RealTDetails';
  building_class?: Maybe<Scalars['String']>;
  cooling?: Maybe<Scalars['String']>;
  exterior_walls?: Maybe<Scalars['String']>;
  foundation?: Maybe<Scalars['String']>;
  heating?: Maybe<Scalars['String']>;
  interior_size?: Maybe<Scalars['String']>;
  lot_size?: Maybe<Scalars['String']>;
  parking?: Maybe<Scalars['String']>;
  property_manager?: Maybe<Scalars['String']>;
  renovated?: Maybe<Scalars['String']>;
  roof_type?: Maybe<Scalars['String']>;
};

export type RealTDetailsInput = {
  building_class?: InputMaybe<Scalars['String']>;
  cooling?: InputMaybe<Scalars['String']>;
  exterior_walls?: InputMaybe<Scalars['String']>;
  foundation?: InputMaybe<Scalars['String']>;
  heating?: InputMaybe<Scalars['String']>;
  interior_size?: InputMaybe<Scalars['String']>;
  lot_size?: InputMaybe<Scalars['String']>;
  parking?: InputMaybe<Scalars['String']>;
  property_manager?: InputMaybe<Scalars['String']>;
  renovated?: InputMaybe<Scalars['String']>;
  roof_type?: InputMaybe<Scalars['String']>;
};

/** RealTEthereum model */
export type RealTEthereum = {
  __typename?: 'RealTEthereum';
  contract_address?: Maybe<Scalars['String']>;
  owner_wallet?: Maybe<Scalars['String']>;
};

export type RealTEthereumInput = {
  contract_address?: InputMaybe<Scalars['String']>;
  owner_wallet?: InputMaybe<Scalars['String']>;
};

/** RealTFinancials model */
export type RealTFinancials = {
  __typename?: 'RealTFinancials';
  expected_income?: Maybe<Scalars['String']>;
  gross_rent_month?: Maybe<Scalars['String']>;
  gross_rent_year?: Maybe<Scalars['String']>;
  monthly_costs: RealTMonthlyCosts;
  net_rent_annual?: Maybe<Scalars['String']>;
  net_rent_monthly?: Maybe<Scalars['String']>;
  total_investment: RealTTotalInvestment;
};

export type RealTFinancialsInput = {
  expected_income?: InputMaybe<Scalars['String']>;
  gross_rent_month?: InputMaybe<Scalars['String']>;
  gross_rent_year?: InputMaybe<Scalars['String']>;
  monthly_costs: RealTMonthlyCostsInput;
  net_rent_annual?: InputMaybe<Scalars['String']>;
  net_rent_monthly?: InputMaybe<Scalars['String']>;
  total_investment: RealTTotalInvestmentInput;
};

/** RealTGnosisChain model */
export type RealTGnosisChain = {
  __typename?: 'RealTGnosisChain';
  contract_address?: Maybe<Scalars['String']>;
  levinswap_pool?: Maybe<Scalars['String']>;
  owner_wallet?: Maybe<Scalars['String']>;
};

export type RealTGnosisChainInput = {
  contract_address?: InputMaybe<Scalars['String']>;
  levinswap_pool?: InputMaybe<Scalars['String']>;
  owner_wallet?: InputMaybe<Scalars['String']>;
};

/** RealTHighlights model */
export type RealTHighlights = {
  __typename?: 'RealTHighlights';
  bedroom_bath?: Maybe<Scalars['String']>;
  construction_year?: Maybe<Scalars['String']>;
  expected_income?: Maybe<Scalars['String']>;
  full_address?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  non_us_tokens?: Maybe<Scalars['String']>;
  property_type?: Maybe<Scalars['String']>;
  rent_per_token?: Maybe<Scalars['String']>;
  rent_start_date?: Maybe<Scalars['String']>;
  rent_subsidy?: Maybe<Scalars['String']>;
  rental_type?: Maybe<Scalars['String']>;
  rented?: Maybe<Scalars['String']>;
  token_price?: Maybe<Scalars['String']>;
  total_tokens?: Maybe<Scalars['String']>;
  total_units?: Maybe<Scalars['String']>;
  us_only_tokens?: Maybe<Scalars['String']>;
};

export type RealTHighlightsInput = {
  bedroom_bath?: InputMaybe<Scalars['String']>;
  construction_year?: InputMaybe<Scalars['String']>;
  expected_income?: InputMaybe<Scalars['String']>;
  full_address?: InputMaybe<Scalars['String']>;
  neighborhood?: InputMaybe<Scalars['String']>;
  non_us_tokens?: InputMaybe<Scalars['String']>;
  property_type?: InputMaybe<Scalars['String']>;
  rent_per_token?: InputMaybe<Scalars['String']>;
  rent_start_date?: InputMaybe<Scalars['String']>;
  rent_subsidy?: InputMaybe<Scalars['String']>;
  rental_type?: InputMaybe<Scalars['String']>;
  rented?: InputMaybe<Scalars['String']>;
  token_price?: InputMaybe<Scalars['String']>;
  total_tokens?: InputMaybe<Scalars['String']>;
  total_units?: InputMaybe<Scalars['String']>;
  us_only_tokens?: InputMaybe<Scalars['String']>;
};

export type RealTInput = {
  asset: AdsAssetInput;
  briicks: BriicksInput;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  calendar: CalendarInput;
  financial: FinancialInput;
  pageData: Array<RealTPageDataInput>;
  preferences: PreferenceInput;
  property_manager?: InputMaybe<PropertyManagerInput>;
  rental?: InputMaybe<RentalInput>;
  salesProgress: Scalars['Float'];
  spv?: InputMaybe<SpvInput>;
  status: AdsStatus;
  title: Scalars['String'];
  tokenizedFractionalized: Scalars['Boolean'];
  transactionType: Array<TransactionType>;
};

/** RealTMetadata model */
export type RealTMetadata = {
  __typename?: 'RealTMetadata';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type RealTMetadataInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** RealTMonthlyCosts model */
export type RealTMonthlyCosts = {
  __typename?: 'RealTMonthlyCosts';
  metadata: Array<RealTMetadata>;
  total?: Maybe<Scalars['String']>;
};

export type RealTMonthlyCostsInput = {
  metadata: Array<RealTMetadataInput>;
  total?: InputMaybe<Scalars['String']>;
};

/** RealTOffering model */
export type RealTOffering = {
  __typename?: 'RealTOffering';
  amount_raised?: Maybe<Scalars['String']>;
  issuer?: Maybe<Scalars['String']>;
  max_investment_amount?: Maybe<Scalars['String']>;
  min_investment_amount?: Maybe<Scalars['String']>;
  offering_date?: Maybe<Scalars['String']>;
  offering_percent_of_total_tokens?: Maybe<Scalars['String']>;
};

export type RealTOfferingInput = {
  amount_raised?: InputMaybe<Scalars['String']>;
  issuer?: InputMaybe<Scalars['String']>;
  max_investment_amount?: InputMaybe<Scalars['String']>;
  min_investment_amount?: InputMaybe<Scalars['String']>;
  offering_date?: InputMaybe<Scalars['String']>;
  offering_percent_of_total_tokens?: InputMaybe<Scalars['String']>;
};

/** RealTPageData model */
export type RealTPageData = {
  __typename?: 'RealTPageData';
  about_the_city?: Maybe<Scalars['String']>;
  about_the_property?: Maybe<Scalars['String']>;
  blockchain?: Maybe<RealTBlockchain>;
  details?: Maybe<RealTDetails>;
  financials?: Maybe<RealTFinancials>;
  highlights?: Maybe<RealTHighlights>;
  launching_timer_seconds?: Maybe<Scalars['Float']>;
  link?: Maybe<Scalars['String']>;
  max_purchase?: Maybe<Scalars['Float']>;
  offering?: Maybe<RealTOffering>;
  pictures: Array<Scalars['String']>;
  sales_start_date?: Maybe<Scalars['DateTime']>;
  sku?: Maybe<Scalars['ID']>;
  smartContracts?: Maybe<RealTSmartContracts>;
  status?: Maybe<Scalars['String']>;
  status_badge?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  token_stock?: Maybe<Scalars['Float']>;
};

export type RealTPageDataInput = {
  about_the_city?: InputMaybe<Scalars['String']>;
  about_the_property?: InputMaybe<Scalars['String']>;
  blockchain?: InputMaybe<RealTBlockchainInput>;
  details?: InputMaybe<RealTDetailsInput>;
  financials?: InputMaybe<RealTFinancialsInput>;
  highlights?: InputMaybe<RealTHighlightsInput>;
  launching_timer_seconds?: InputMaybe<Scalars['Float']>;
  link?: InputMaybe<Scalars['String']>;
  max_purchase?: InputMaybe<Scalars['Float']>;
  offering?: InputMaybe<RealTOfferingInput>;
  pictures: Array<Scalars['String']>;
  sales_start_date?: InputMaybe<Scalars['DateTime']>;
  sku?: InputMaybe<Scalars['ID']>;
  smartContracts?: InputMaybe<RealTSmartContractsInput>;
  status?: InputMaybe<Scalars['String']>;
  status_badge?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  token_stock?: InputMaybe<Scalars['Float']>;
};

/** RealTSmartContracts model */
export type RealTSmartContracts = {
  __typename?: 'RealTSmartContracts';
  eth?: Maybe<Scalars['String']>;
  xdai?: Maybe<Scalars['String']>;
};

export type RealTSmartContractsInput = {
  eth?: InputMaybe<Scalars['String']>;
  xdai?: InputMaybe<Scalars['String']>;
};

/** RealTTotalInvestment model */
export type RealTTotalInvestment = {
  __typename?: 'RealTTotalInvestment';
  metadata: Array<RealTMetadata>;
  total?: Maybe<Scalars['String']>;
};

export type RealTTotalInvestmentInput = {
  metadata: Array<RealTMetadataInput>;
  total?: InputMaybe<Scalars['String']>;
};

export enum RedirectionModes {
  /** redirection to the maker ling */
  Default = 'DEFAULT',
  /** multiple redirection to the maker ling */
  Multiple = 'MULTIPLE',
  /** WEBHOOK */
  Webhook = 'WEBHOOK',
}

export type Reental = {
  __typename?: 'Reental';
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  administrative_area_level_1?: Maybe<Scalars['String']>;
  administrative_area_level_2?: Maybe<Scalars['String']>;
  amount?: Maybe<ReentalCurrencyValue>;
  apr?: Maybe<Scalars['Float']>;
  aprNetSale?: Maybe<Scalars['Float']>;
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crowdsale?: Maybe<ReentalCrowdsale>;
  crowdsaleAddress?: Maybe<Scalars['String']>;
  description_en?: Maybe<Scalars['String']>;
  description_es?: Maybe<Scalars['String']>;
  dividends?: Maybe<Scalars['Float']>;
  dividendsV1?: Maybe<Scalars['Float']>;
  dividends_starts_on?: Maybe<Scalars['DateTime']>;
  docs_en?: Maybe<Array<ReentalPropertyMedia>>;
  docs_es?: Maybe<Array<ReentalPropertyMedia>>;
  emittedTokens?: Maybe<Scalars['Int']>;
  geo?: Maybe<ReentalGeoposition>;
  images?: Maybe<Array<ReentalPropertyMedia>>;
  locality?: Maybe<Scalars['String']>;
  m2?: Maybe<Scalars['Float']>;
  minInvestmentTokens?: Maybe<Scalars['Int']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  netSale?: Maybe<Scalars['Float']>;
  netYearlyBenefit?: Maybe<ReentalCurrencyValue>;
  poolPercent?: Maybe<Scalars['Int']>;
  reental_id: Scalars['String'];
  saleProfitability?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  starts_on?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Reental_Property_Status>;
  tokenAddress?: Maybe<Scalars['String']>;
  tokenName: Scalars['String'];
  tokenPrice?: Maybe<ReentalCurrencyValue>;
  tokensLeft?: Maybe<Scalars['Float']>;
};

export type ReentalCrowdsale = {
  __typename?: 'ReentalCrowdsale';
  abi?: Maybe<Scalars['String']>;
  address: Scalars['String'];
};

export type ReentalCrowdsaleInput = {
  abi?: InputMaybe<Scalars['String']>;
  address: Scalars['String'];
};

export type ReentalCurrencyValue = {
  __typename?: 'ReentalCurrencyValue';
  currency?: Maybe<Reental_Currency>;
  value?: Maybe<Scalars['Float']>;
};

export type ReentalCurrencyValueInput = {
  currency?: InputMaybe<Reental_Currency>;
  value?: InputMaybe<Scalars['Float']>;
};

export type ReentalGeoposition = {
  __typename?: 'ReentalGeoposition';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type ReentalGeopositionInput = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
};

export type ReentalInput = {
  address?: InputMaybe<Scalars['String']>;
  administrative_area_level_1?: InputMaybe<Scalars['String']>;
  administrative_area_level_2?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<ReentalCurrencyValueInput>;
  apr?: InputMaybe<Scalars['Float']>;
  aprNetSale?: InputMaybe<Scalars['Float']>;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  crowdsale?: InputMaybe<ReentalCrowdsaleInput>;
  crowdsaleAddress?: InputMaybe<Scalars['String']>;
  description_en?: InputMaybe<Scalars['String']>;
  description_es?: InputMaybe<Scalars['String']>;
  dividends?: InputMaybe<Scalars['Float']>;
  dividendsV1?: InputMaybe<Scalars['Float']>;
  dividends_starts_on?: InputMaybe<Scalars['DateTime']>;
  docs_en?: InputMaybe<Array<ReentalPropertyMediaInput>>;
  docs_es?: InputMaybe<Array<ReentalPropertyMediaInput>>;
  emittedTokens?: InputMaybe<Scalars['Int']>;
  geo?: InputMaybe<ReentalGeopositionInput>;
  images?: InputMaybe<Array<ReentalPropertyMediaInput>>;
  locality?: InputMaybe<Scalars['String']>;
  m2?: InputMaybe<Scalars['Float']>;
  minInvestmentTokens?: InputMaybe<Scalars['Int']>;
  modifiedAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  netSale?: InputMaybe<Scalars['Float']>;
  netYearlyBenefit?: InputMaybe<ReentalCurrencyValueInput>;
  poolPercent?: InputMaybe<Scalars['Int']>;
  reental_id: Scalars['String'];
  saleProfitability?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['String']>;
  starts_on?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Reental_Property_Status>;
  tokenAddress?: InputMaybe<Scalars['String']>;
  tokenName: Scalars['String'];
  tokenPrice?: InputMaybe<ReentalCurrencyValueInput>;
  tokensLeft?: InputMaybe<Scalars['Float']>;
};

export type ReentalPropertyMedia = {
  __typename?: 'ReentalPropertyMedia';
  id?: Maybe<Scalars['String']>;
  keyS3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ReentalPropertyMediaInput = {
  id?: InputMaybe<Scalars['ID']>;
  keyS3?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

export type Rental = {
  __typename?: 'Rental';
  investment: RentalInvestment;
  leaseDetails: LeaseDetails;
  profitability: RentalProfitability;
};

export type RentalInput = {
  investment: RentalInvestmentInput;
  leaseDetails: LeaseDetailsInput;
  profitability: RentalProfitabilityInput;
};

export type RentalInvestment = {
  __typename?: 'RentalInvestment';
  metadata?: Maybe<Array<FinancialMetadata>>;
  totalAmount: Scalars['Float'];
};

export type RentalInvestmentInput = {
  metadata?: InputMaybe<Array<FinancialMetadataInput>>;
  totalAmount: Scalars['Float'];
};

export type RentalProfitability = {
  __typename?: 'RentalProfitability';
  /** in month */
  assetAppreciation?: Maybe<Scalars['Float']>;
  cashFlow: Scalars['Float'];
  cashOnCash: Scalars['Float'];
  /** in month */
  internalRateOfReturn?: Maybe<Scalars['Float']>;
};

export type RentalProfitabilityInput = {
  /** in month */
  assetAppreciation?: InputMaybe<Scalars['Float']>;
  cashFlow: Scalars['Float'];
  cashOnCash: Scalars['Float'];
  /** in month */
  internalRateOfReturn?: InputMaybe<Scalars['Float']>;
};

/** Object representing ReservedShare document */
export type ReservedShare = {
  __typename?: 'ReservedShare';
  as: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shares: Scalars['Int'];
};

export type ReservedShareInput = {
  as: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  shares: Scalars['Int'];
};

export type Result = Error | Success;

export type Spv = {
  __typename?: 'SPV';
  documents: SpvDocuments;
  metadata?: Maybe<Array<Metadata>>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  registrationPlace: Scalars['String'];
  sponsor: Sponsor;
};

export type SpvDocuments = {
  __typename?: 'SPVDocuments';
  establishementCertificate: Document;
  otherDocuments?: Maybe<Array<OtherDocument>>;
};

export type SpvDocumentsInput = {
  establishementCertificate: DocumentInput;
  otherDocuments?: InputMaybe<Array<OtherDocumentInput>>;
};

export type SpvInput = {
  documents: SpvDocumentsInput;
  metadata?: InputMaybe<Array<MetadataInput>>;
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  registrationPlace: Scalars['String'];
  sponsor: SponsorInput;
};

export type SmartCrowd = {
  __typename?: 'SmartCrowd';
  _id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  administrativeFee?: Maybe<Scalars['Float']>;
  advanceTaxFiler?: Maybe<Scalars['Float']>;
  advanceTaxNonFiler?: Maybe<Scalars['Float']>;
  annualCapitalAppreciations?: Maybe<Scalars['Float']>;
  annualCouncilTax?: Maybe<Scalars['Float']>;
  annualGroundRent?: Maybe<Scalars['Float']>;
  annualPropertyManagementFee?: Maybe<Scalars['String']>;
  annualServiceCharges?: Maybe<Scalars['Float']>;
  annualServiceChargesPeriodFrom?: Maybe<Scalars['String']>;
  annualServiceChargesPeriodTo?: Maybe<Scalars['String']>;
  annualTV?: Maybe<Scalars['Float']>;
  annualUtilities?: Maybe<Scalars['Float']>;
  annualizedReturn?: Maybe<Scalars['Float']>;
  approveBy?: Maybe<Scalars['Int']>;
  approveComments?: Maybe<Scalars['String']>;
  approveStatus?: Maybe<Scalars['String']>;
  areaId?: Maybe<Scalars['Int']>;
  balcony?: Maybe<Scalars['Int']>;
  basePlatform?: Maybe<SmartCrowdBasePlatform>;
  bathrooms?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  biddingEndDate?: Maybe<Scalars['String']>;
  biddingStartDate?: Maybe<Scalars['String']>;
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  brokerageFee?: Maybe<Scalars['Float']>;
  cardTitle?: Maybe<Scalars['String']>;
  categoryId?: Maybe<SmartCrowdCategoryId>;
  cityId?: Maybe<SmartCrowdPlaceId>;
  closeDate?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  corporateLet?: Maybe<Scalars['Boolean']>;
  corporateTaxRate?: Maybe<Scalars['Float']>;
  countryId?: Maybe<SmartCrowdPlaceId>;
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  createDate_iso?: Maybe<Scalars['DateTime']>;
  currencyId?: Maybe<SmartCrowdCurrencyId>;
  currentVersionNo?: Maybe<Scalars['Float']>;
  currentlyRented?: Maybe<Scalars['Boolean']>;
  cvtStampDutyFiler?: Maybe<Scalars['Float']>;
  cvtStampDutyNonFiler?: Maybe<Scalars['Float']>;
  dcRate?: Maybe<Scalars['Float']>;
  developer?: Maybe<Scalars['String']>;
  developerDetails?: Maybe<Scalars['String']>;
  developerDetailsStatus?: Maybe<Scalars['Boolean']>;
  developerEstablishedYear?: Maybe<Scalars['String']>;
  developerProperties?: Maybe<Scalars['String']>;
  developerTotalBuiltUpArea?: Maybe<Scalars['String']>;
  developerWebsite?: Maybe<Scalars['String']>;
  dewaDeposit?: Maybe<Scalars['Float']>;
  dhaMembershipFee?: Maybe<Scalars['Float']>;
  dividendAmount?: Maybe<Scalars['Float']>;
  dividendYeild?: Maybe<Scalars['Float']>;
  emicoolEmpowerDeposit?: Maybe<Scalars['Float']>;
  estimated5yrIrr?: Maybe<Scalars['Float']>;
  estimatedReturnOnInvestment5yr?: Maybe<Scalars['Float']>;
  exitFee?: Maybe<Scalars['Float']>;
  exited?: Maybe<Scalars['Boolean']>;
  expected1stRentDividendDate?: Maybe<Scalars['String']>;
  expectedNetRentalIncome?: Maybe<Scalars['Float']>;
  extendedDateFlag?: Maybe<Scalars['Boolean']>;
  fbrRate?: Maybe<Scalars['Float']>;
  freeHoldStatus?: Maybe<Scalars['String']>;
  fullFundingNotification?: Maybe<Scalars['Boolean']>;
  fundingCollected?: Maybe<Scalars['Float']>;
  fundingTarget?: Maybe<Scalars['Float']>;
  furnished?: Maybe<Scalars['String']>;
  furnitureCosts?: Maybe<Scalars['Float']>;
  grossRent?: Maybe<Scalars['Float']>;
  grossYield?: Maybe<Scalars['Float']>;
  holidayHome?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  insuranceCost?: Maybe<Scalars['Float']>;
  investmentCase?: Maybe<Scalars['String']>;
  investmentCaseText?: Maybe<Scalars['String']>;
  investmentPercentage?: Maybe<Scalars['Float']>;
  investmentPeriod?: Maybe<Scalars['Int']>;
  investmentType?: Maybe<Scalars['String']>;
  landDepartmentFee?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['String']>;
  legalFees?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['String']>;
  mainImageFilename?: Maybe<Scalars['String']>;
  mainImageTitles?: Maybe<Scalars['String']>;
  maintenanceAllowance?: Maybe<Scalars['Float']>;
  maintenanceCharge?: Maybe<Scalars['String']>;
  managerClients?: Maybe<Scalars['String']>;
  managerEstablishedYear?: Maybe<Scalars['String']>;
  managerTotalDeliveredArea?: Maybe<Scalars['String']>;
  maximumInvestmentAmount?: Maybe<Scalars['Float']>;
  maximumInvestmentAmountAED?: Maybe<Scalars['Float']>;
  minimumInvestmentAmountAED?: Maybe<Scalars['Float']>;
  minimumNumberOfShare?: Maybe<Scalars['Int']>;
  minimumRemainingAmount?: Maybe<Scalars['Float']>;
  miscCharge?: Maybe<Scalars['Float']>;
  netYield?: Maybe<Scalars['Float']>;
  noOfChqs?: Maybe<Scalars['Int']>;
  numberOfInvestors?: Maybe<Scalars['Int']>;
  numberOfShares?: Maybe<Scalars['Float']>;
  otherClosingCost?: Maybe<Scalars['Float']>;
  parking?: Maybe<Scalars['Int']>;
  pricePerSqFt?: Maybe<Scalars['Float']>;
  propertTypeId?: Maybe<SmartCrowdPropertTypeId>;
  propertyDetails?: Maybe<Scalars['String']>;
  propertyDetailsText?: Maybe<Scalars['String']>;
  propertyImageContentType?: Maybe<Scalars['String']>;
  propertyImageUrl?: Maybe<Scalars['String']>;
  propertyManager?: Maybe<Scalars['String']>;
  propertyManagerDetails?: Maybe<Scalars['String']>;
  propertyManagerDetailsStatus?: Maybe<Scalars['Boolean']>;
  propertyManagerWebsite?: Maybe<Scalars['String']>;
  propertyPrice?: Maybe<Scalars['Float']>;
  propertyStatus?: Maybe<SmartCrowdPropertyStatus>;
  propertyStatusFrom?: Maybe<Scalars['String']>;
  propertyStatusTo?: Maybe<Scalars['String']>;
  propertyType?: Maybe<Scalars['String']>;
  propertyValuation?: Maybe<Scalars['Float']>;
  qasaarFee?: Maybe<Scalars['Float']>;
  qasaarGstFee?: Maybe<Scalars['Float']>;
  recalculating?: Maybe<Scalars['Boolean']>;
  renovationCosts?: Maybe<Scalars['Float']>;
  rentCategory?: Maybe<Scalars['String']>;
  rentDeposit?: Maybe<Scalars['Float']>;
  rentDepositHeldBy?: Maybe<Scalars['String']>;
  reraPermit?: Maybe<Scalars['String']>;
  roi?: Maybe<Scalars['Float']>;
  saleProceed?: Maybe<Scalars['Float']>;
  seller?: Maybe<Scalars['String']>;
  sellerDetails?: Maybe<Scalars['String']>;
  sellerDetailsStatus?: Maybe<Scalars['Boolean']>;
  sellerWebsite?: Maybe<Scalars['String']>;
  sharePrices?: Maybe<Scalars['Float']>;
  shortTermRental?: Maybe<Scalars['Boolean']>;
  smartCrowdAdministrativeFee?: Maybe<Scalars['Float']>;
  smartcrowdStructuringFee?: Maybe<Scalars['Float']>;
  spvRegistration?: Maybe<Scalars['Float']>;
  squareFit?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Boolean']>;
  subArea?: Maybe<Scalars['Float']>;
  tenancyContractExpiryDate?: Maybe<Scalars['String']>;
  tenancyContractStartDate?: Maybe<Scalars['String']>;
  tipTax?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  titleDeedFee?: Maybe<Scalars['Float']>;
  totalRentCollected?: Maybe<Scalars['Float']>;
  totalReturn?: Maybe<Scalars['Float']>;
  tourId?: Maybe<Scalars['String']>;
  transactionAdjustment?: Maybe<Scalars['Float']>;
  transferFee?: Maybe<Scalars['Float']>;
  trusteeFee?: Maybe<Scalars['Float']>;
  unqiueId: Scalars['String'];
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
  updateDate_iso?: Maybe<Scalars['DateTime']>;
  vacant?: Maybe<Scalars['Boolean']>;
  valuationFees?: Maybe<Scalars['Float']>;
  vatTax?: Maybe<Scalars['Float']>;
  videoLink?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  visibilityStatus?: Maybe<Scalars['Int']>;
  whiteLabelConfigs?: Maybe<Array<SmartCrowdWhiteLabelConfigs>>;
};

export type SmartCrowdBasePlatform = {
  __typename?: 'SmartCrowdBasePlatform';
  applicationBaseUrl?: Maybe<Scalars['String']>;
  backgroundImageCss?: Maybe<Scalars['String']>;
  bannerCSS?: Maybe<Scalars['String']>;
  baseUrl?: Maybe<Scalars['String']>;
  bodyWrapperCSS?: Maybe<Scalars['String']>;
  bottomHozLineCss?: Maybe<Scalars['String']>;
  buttonCSS?: Maybe<Scalars['String']>;
  contentWrapperCss?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  leanPaymentActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  poaSubmissionRequired?: Maybe<Scalars['Boolean']>;
  referralApplicable?: Maybe<Scalars['Boolean']>;
  shortcode?: Maybe<Scalars['String']>;
  stepOneAttachmentFileName?: Maybe<Scalars['String']>;
  stepTenAttachmentFileName?: Maybe<Scalars['String']>;
  tncInvRegAttachmentFileName?: Maybe<Scalars['String']>;
  tncInvestmentAttachmentFileName?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
  userNameCss?: Maybe<Scalars['String']>;
};

export type SmartCrowdBasePlatformInput = {
  applicationBaseUrl?: InputMaybe<Scalars['String']>;
  backgroundImageCss?: InputMaybe<Scalars['String']>;
  bannerCSS?: InputMaybe<Scalars['String']>;
  baseUrl?: InputMaybe<Scalars['String']>;
  bodyWrapperCSS?: InputMaybe<Scalars['String']>;
  bottomHozLineCss?: InputMaybe<Scalars['String']>;
  buttonCSS?: InputMaybe<Scalars['String']>;
  contentWrapperCss?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  leanPaymentActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  poaSubmissionRequired?: InputMaybe<Scalars['Boolean']>;
  referralApplicable?: InputMaybe<Scalars['Boolean']>;
  shortcode?: InputMaybe<Scalars['String']>;
  stepOneAttachmentFileName?: InputMaybe<Scalars['String']>;
  stepTenAttachmentFileName?: InputMaybe<Scalars['String']>;
  tncInvRegAttachmentFileName?: InputMaybe<Scalars['String']>;
  tncInvestmentAttachmentFileName?: InputMaybe<Scalars['String']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
  userNameCss?: InputMaybe<Scalars['String']>;
};

export type SmartCrowdCategoryId = {
  __typename?: 'SmartCrowdCategoryId';
  categoryCode?: Maybe<Scalars['String']>;
  categoryName?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type SmartCrowdCategoryIdInput = {
  categoryCode?: InputMaybe<Scalars['String']>;
  categoryName?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
};

export type SmartCrowdCurrencyId = {
  __typename?: 'SmartCrowdCurrencyId';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  symbol?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type SmartCrowdCurrencyIdInput = {
  code?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  symbol?: InputMaybe<Scalars['String']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
};

export type SmartCrowdInput = {
  address?: InputMaybe<Scalars['String']>;
  administrativeFee?: InputMaybe<Scalars['Float']>;
  advanceTaxFiler?: InputMaybe<Scalars['Float']>;
  advanceTaxNonFiler?: InputMaybe<Scalars['Float']>;
  annualCapitalAppreciations?: InputMaybe<Scalars['Float']>;
  annualCouncilTax?: InputMaybe<Scalars['Float']>;
  annualGroundRent?: InputMaybe<Scalars['Float']>;
  annualPropertyManagementFee?: InputMaybe<Scalars['String']>;
  annualServiceCharges?: InputMaybe<Scalars['Float']>;
  annualServiceChargesPeriodFrom?: InputMaybe<Scalars['String']>;
  annualServiceChargesPeriodTo?: InputMaybe<Scalars['String']>;
  annualTV?: InputMaybe<Scalars['Float']>;
  annualUtilities?: InputMaybe<Scalars['Float']>;
  annualizedReturn?: InputMaybe<Scalars['Float']>;
  approveBy?: InputMaybe<Scalars['Int']>;
  approveComments?: InputMaybe<Scalars['String']>;
  approveStatus?: InputMaybe<Scalars['String']>;
  areaId?: InputMaybe<Scalars['Int']>;
  balcony?: InputMaybe<Scalars['Int']>;
  basePlatform?: InputMaybe<SmartCrowdBasePlatformInput>;
  bathrooms?: InputMaybe<Scalars['Int']>;
  bedrooms?: InputMaybe<Scalars['Int']>;
  biddingEndDate?: InputMaybe<Scalars['String']>;
  biddingStartDate?: InputMaybe<Scalars['String']>;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  brokerageFee?: InputMaybe<Scalars['Float']>;
  cardTitle?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<SmartCrowdCategoryIdInput>;
  cityId?: InputMaybe<SmartCrowdPlaceIdInput>;
  closeDate?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  corporateLet?: InputMaybe<Scalars['Boolean']>;
  corporateTaxRate?: InputMaybe<Scalars['Float']>;
  countryId?: InputMaybe<SmartCrowdPlaceIdInput>;
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  createDate_iso?: InputMaybe<Scalars['DateTime']>;
  currencyId?: InputMaybe<SmartCrowdCurrencyIdInput>;
  currentVersionNo?: InputMaybe<Scalars['Float']>;
  currentlyRented?: InputMaybe<Scalars['Boolean']>;
  cvtStampDutyFiler?: InputMaybe<Scalars['Float']>;
  cvtStampDutyNonFiler?: InputMaybe<Scalars['Float']>;
  dcRate?: InputMaybe<Scalars['Float']>;
  developer?: InputMaybe<Scalars['String']>;
  developerDetails?: InputMaybe<Scalars['String']>;
  developerDetailsStatus?: InputMaybe<Scalars['Boolean']>;
  developerEstablishedYear?: InputMaybe<Scalars['String']>;
  developerProperties?: InputMaybe<Scalars['String']>;
  developerTotalBuiltUpArea?: InputMaybe<Scalars['String']>;
  developerWebsite?: InputMaybe<Scalars['String']>;
  dewaDeposit?: InputMaybe<Scalars['Float']>;
  dhaMembershipFee?: InputMaybe<Scalars['Float']>;
  dividendAmount?: InputMaybe<Scalars['Float']>;
  dividendYeild?: InputMaybe<Scalars['Float']>;
  emicoolEmpowerDeposit?: InputMaybe<Scalars['Float']>;
  estimated5yrIrr?: InputMaybe<Scalars['Float']>;
  estimatedReturnOnInvestment5yr?: InputMaybe<Scalars['Float']>;
  exitFee?: InputMaybe<Scalars['Float']>;
  exited?: InputMaybe<Scalars['Boolean']>;
  expected1stRentDividendDate?: InputMaybe<Scalars['String']>;
  expectedNetRentalIncome?: InputMaybe<Scalars['Float']>;
  extendedDateFlag?: InputMaybe<Scalars['Boolean']>;
  fbrRate?: InputMaybe<Scalars['Float']>;
  freeHoldStatus?: InputMaybe<Scalars['String']>;
  fullFundingNotification?: InputMaybe<Scalars['Boolean']>;
  fundingCollected?: InputMaybe<Scalars['Float']>;
  fundingTarget?: InputMaybe<Scalars['Float']>;
  furnished?: InputMaybe<Scalars['String']>;
  furnitureCosts?: InputMaybe<Scalars['Float']>;
  grossRent?: InputMaybe<Scalars['Float']>;
  grossYield?: InputMaybe<Scalars['Float']>;
  holidayHome?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  insuranceCost?: InputMaybe<Scalars['Float']>;
  investmentCase?: InputMaybe<Scalars['String']>;
  investmentCaseText?: InputMaybe<Scalars['String']>;
  investmentPercentage?: InputMaybe<Scalars['Float']>;
  investmentPeriod?: InputMaybe<Scalars['Int']>;
  investmentType?: InputMaybe<Scalars['String']>;
  landDepartmentFee?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['String']>;
  legalFees?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['String']>;
  mainImageFilename?: InputMaybe<Scalars['String']>;
  mainImageTitles?: InputMaybe<Scalars['String']>;
  maintenanceAllowance?: InputMaybe<Scalars['Float']>;
  maintenanceCharge?: InputMaybe<Scalars['String']>;
  managerClients?: InputMaybe<Scalars['String']>;
  managerEstablishedYear?: InputMaybe<Scalars['String']>;
  managerTotalDeliveredArea?: InputMaybe<Scalars['String']>;
  maximumInvestmentAmount?: InputMaybe<Scalars['Float']>;
  maximumInvestmentAmountAED?: InputMaybe<Scalars['Float']>;
  minimumInvestmentAmountAED?: InputMaybe<Scalars['Float']>;
  minimumNumberOfShare?: InputMaybe<Scalars['Int']>;
  minimumRemainingAmount?: InputMaybe<Scalars['Float']>;
  miscCharge?: InputMaybe<Scalars['Float']>;
  netYield?: InputMaybe<Scalars['Float']>;
  noOfChqs?: InputMaybe<Scalars['Int']>;
  numberOfInvestors?: InputMaybe<Scalars['Int']>;
  numberOfShares?: InputMaybe<Scalars['Float']>;
  otherClosingCost?: InputMaybe<Scalars['Float']>;
  parking?: InputMaybe<Scalars['Int']>;
  pricePerSqFt?: InputMaybe<Scalars['Float']>;
  propertTypeId?: InputMaybe<SmartCrowdPropertTypeIdInput>;
  propertyDetails?: InputMaybe<Scalars['String']>;
  propertyDetailsText?: InputMaybe<Scalars['String']>;
  propertyImageContentType?: InputMaybe<Scalars['String']>;
  propertyImageUrl?: InputMaybe<Scalars['String']>;
  propertyManager?: InputMaybe<Scalars['String']>;
  propertyManagerDetails?: InputMaybe<Scalars['String']>;
  propertyManagerDetailsStatus?: InputMaybe<Scalars['Boolean']>;
  propertyManagerWebsite?: InputMaybe<Scalars['String']>;
  propertyPrice?: InputMaybe<Scalars['Float']>;
  propertyStatus?: InputMaybe<SmartCrowdPropertyStatusInput>;
  propertyStatusFrom?: InputMaybe<Scalars['String']>;
  propertyStatusTo?: InputMaybe<Scalars['String']>;
  propertyType?: InputMaybe<Scalars['String']>;
  propertyValuation?: InputMaybe<Scalars['Float']>;
  qasaarFee?: InputMaybe<Scalars['Float']>;
  qasaarGstFee?: InputMaybe<Scalars['Float']>;
  recalculating?: InputMaybe<Scalars['Boolean']>;
  renovationCosts?: InputMaybe<Scalars['Float']>;
  rentCategory?: InputMaybe<Scalars['String']>;
  rentDeposit?: InputMaybe<Scalars['Float']>;
  rentDepositHeldBy?: InputMaybe<Scalars['String']>;
  reraPermit?: InputMaybe<Scalars['String']>;
  roi?: InputMaybe<Scalars['Float']>;
  saleProceed?: InputMaybe<Scalars['Float']>;
  seller?: InputMaybe<Scalars['String']>;
  sellerDetails?: InputMaybe<Scalars['String']>;
  sellerDetailsStatus?: InputMaybe<Scalars['Boolean']>;
  sellerWebsite?: InputMaybe<Scalars['String']>;
  sharePrices?: InputMaybe<Scalars['Float']>;
  shortTermRental?: InputMaybe<Scalars['Boolean']>;
  smartCrowdAdministrativeFee?: InputMaybe<Scalars['Float']>;
  smartcrowdStructuringFee?: InputMaybe<Scalars['Float']>;
  spvRegistration?: InputMaybe<Scalars['Float']>;
  squareFit?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Boolean']>;
  subArea?: InputMaybe<Scalars['Float']>;
  tenancyContractExpiryDate?: InputMaybe<Scalars['String']>;
  tenancyContractStartDate?: InputMaybe<Scalars['String']>;
  tipTax?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
  titleDeedFee?: InputMaybe<Scalars['Float']>;
  totalRentCollected?: InputMaybe<Scalars['Float']>;
  totalReturn?: InputMaybe<Scalars['Float']>;
  tourId?: InputMaybe<Scalars['String']>;
  transactionAdjustment?: InputMaybe<Scalars['Float']>;
  transferFee?: InputMaybe<Scalars['Float']>;
  trusteeFee?: InputMaybe<Scalars['Float']>;
  unqiueId: Scalars['String'];
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
  updateDate_iso?: InputMaybe<Scalars['DateTime']>;
  vacant?: InputMaybe<Scalars['Boolean']>;
  valuationFees?: InputMaybe<Scalars['Float']>;
  vatTax?: InputMaybe<Scalars['Float']>;
  videoLink?: InputMaybe<Scalars['String']>;
  view?: InputMaybe<Scalars['String']>;
  visibilityStatus?: InputMaybe<Scalars['Int']>;
  whiteLabelConfigs?: InputMaybe<Array<SmartCrowdWhiteLabelConfigsInput>>;
};

export type SmartCrowdPlaceId = {
  __typename?: 'SmartCrowdPlaceId';
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  groupLebel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  residentialFlag?: Maybe<Scalars['Boolean']>;
  riskRating?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type SmartCrowdPlaceIdInput = {
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  groupLebel?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  residentialFlag?: InputMaybe<Scalars['Boolean']>;
  riskRating?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
};

export type SmartCrowdPropertTypeId = {
  __typename?: 'SmartCrowdPropertTypeId';
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  propertyCode?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type SmartCrowdPropertTypeIdInput = {
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  propertyCode?: InputMaybe<Scalars['String']>;
  propertyName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
};

export type SmartCrowdPropertyStatus = {
  __typename?: 'SmartCrowdPropertyStatus';
  code?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
};

export type SmartCrowdPropertyStatusInput = {
  code?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
};

export type SmartCrowdWhiteLabelConfigs = {
  __typename?: 'SmartCrowdWhiteLabelConfigs';
  applicationBaseUrl?: Maybe<Scalars['String']>;
  backgroundImageCss?: Maybe<Scalars['String']>;
  bannerCSS?: Maybe<Scalars['String']>;
  baseUrl?: Maybe<Scalars['String']>;
  bodyWrapperCSS?: Maybe<Scalars['String']>;
  bottomHozLineCss?: Maybe<Scalars['String']>;
  buttonCSS?: Maybe<Scalars['String']>;
  contentWrapperCss?: Maybe<Scalars['String']>;
  createBy?: Maybe<Scalars['Int']>;
  createDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  leanPaymentActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  poaSubmissionRequired?: Maybe<Scalars['Boolean']>;
  referralApplicable?: Maybe<Scalars['Boolean']>;
  shortcode?: Maybe<Scalars['String']>;
  stepOneAttachmentFileName?: Maybe<Scalars['String']>;
  stepTenAttachmentFileName?: Maybe<Scalars['String']>;
  tncInvRegAttachmentFileName?: Maybe<Scalars['String']>;
  tncInvestmentAttachmentFileName?: Maybe<Scalars['String']>;
  updateBy?: Maybe<Scalars['Int']>;
  updateDate?: Maybe<Scalars['String']>;
  userNameCss?: Maybe<Scalars['String']>;
};

export type SmartCrowdWhiteLabelConfigsInput = {
  applicationBaseUrl?: InputMaybe<Scalars['String']>;
  backgroundImageCss?: InputMaybe<Scalars['String']>;
  bannerCSS?: InputMaybe<Scalars['String']>;
  baseUrl?: InputMaybe<Scalars['String']>;
  bodyWrapperCSS?: InputMaybe<Scalars['String']>;
  bottomHozLineCss?: InputMaybe<Scalars['String']>;
  buttonCSS?: InputMaybe<Scalars['String']>;
  contentWrapperCss?: InputMaybe<Scalars['String']>;
  createBy?: InputMaybe<Scalars['Int']>;
  createDate?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  footer?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  leanPaymentActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  paymentEmail?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  poaSubmissionRequired?: InputMaybe<Scalars['Boolean']>;
  referralApplicable?: InputMaybe<Scalars['Boolean']>;
  shortcode?: InputMaybe<Scalars['String']>;
  stepOneAttachmentFileName?: InputMaybe<Scalars['String']>;
  stepTenAttachmentFileName?: InputMaybe<Scalars['String']>;
  tncInvRegAttachmentFileName?: InputMaybe<Scalars['String']>;
  tncInvestmentAttachmentFileName?: InputMaybe<Scalars['String']>;
  updateBy?: InputMaybe<Scalars['Int']>;
  updateDate?: InputMaybe<Scalars['String']>;
  userNameCss?: InputMaybe<Scalars['String']>;
};

export type Sponsor = {
  __typename?: 'Sponsor';
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  registrationPlace: Scalars['String'];
};

export type SponsorInput = {
  name: Scalars['String'];
  registrationNumber: Scalars['String'];
  registrationPlace: Scalars['String'];
};

export type Success = {
  __typename?: 'Success';
  ok: Scalars['Int'];
};

export type Trading = {
  __typename?: 'Trading';
  buy?: Maybe<TradingMinMax>;
  buyOrderTokens?: Maybe<Scalars['Int']>;
  sell?: Maybe<TradingMinMax>;
  sellOrderTokens?: Maybe<Scalars['Int']>;
};

export type TradingMinMax = {
  __typename?: 'TradingMinMax';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export enum TransactionType {
  /** FLIPPING INVESTMENT */
  FlippingInvestment = 'FLIPPING_INVESTMENT',
  /** HOTEL ROOMS */
  HotelRooms = 'HOTEL_ROOMS',
  /** NEW CONSTRUCTION */
  NewConstruction = 'NEW_CONSTRUCTION',
  /** OWNER TAKE BACK */
  OwnerTakeBack = 'OWNER_TAKE_BACK',
  /** RENTAL INVESTMENT */
  RentalInvestment = 'RENTAL_INVESTMENT',
  /** TIME SHARE */
  TimeShare = 'TIME_SHARE',
}

/** URLDescription model */
export type UrlDescription = {
  __typename?: 'URLDescription';
  description: Scalars['String'];
  url: Scalars['String'];
};

export type UrlDescriptionInput = {
  description: Scalars['String'];
  url: Scalars['String'];
};

export type Vairt = {
  __typename?: 'Vairt';
  _id: Scalars['ID'];
  acquisitionCost: Scalars['Float'];
  address: Scalars['String'];
  bathroom: Scalars['Int'];
  bedroom: Scalars['Int'];
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  city: Scalars['String'];
  country: Scalars['String'];
  description: Scalars['String'];
  estimateGrossProfit: Scalars['Float'];
  estimateReturnonInvestmentTotal: Scalars['Float'];
  estimateSaleCosts: Scalars['Float'];
  fullAddress: Scalars['String'];
  holdTimeYear: Scalars['Float'];
  images: Array<Scalars['String']>;
  investProgress: Scalars['Float'];
  minInvestment: Scalars['Float'];
  partnersShareROI: Scalars['Float'];
  propertySalePrice: Scalars['Float'];
  rehabCost: Scalars['Float'];
  sqft: Scalars['Float'];
  state: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  tokensRemaining: Scalars['Float'];
  totalInvestment: Scalars['Float'];
  totalToken: Scalars['Float'];
  vairtsShareROI: Scalars['Float'];
};

export type VairtInput = {
  acquisitionCost: Scalars['Float'];
  address: Scalars['String'];
  bathroom: Scalars['Int'];
  bedroom: Scalars['Int'];
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  city: Scalars['String'];
  country: Scalars['String'];
  description: Scalars['String'];
  estimateGrossProfit: Scalars['Float'];
  estimateReturnonInvestmentTotal: Scalars['Float'];
  estimateSaleCosts: Scalars['Float'];
  fullAddress: Scalars['String'];
  holdTimeYear: Scalars['Float'];
  images: Array<Scalars['String']>;
  investProgress: Scalars['Float'];
  minInvestment: Scalars['Float'];
  partnersShareROI: Scalars['Float'];
  propertySalePrice: Scalars['Float'];
  rehabCost: Scalars['Float'];
  sqft: Scalars['Float'];
  state: Scalars['String'];
  status: Scalars['String'];
  title: Scalars['String'];
  tokensRemaining: Scalars['Float'];
  totalInvestment: Scalars['Float'];
  totalToken: Scalars['Float'];
  vairtsShareROI: Scalars['Float'];
};

export type VestaEquity = {
  __typename?: 'VestaEquity';
  _id: Scalars['ID'];
  annual_future_value?: Maybe<Scalars['Float']>;
  briicksCalculatedMetrics: BriicksCalculatedMetrics;
  briicksCompanyInfos?: Maybe<BriicksCompanyInfos>;
  /** This field indicates the date of insertion.  */
  briicksDate?: Maybe<Scalars['DateTime']>;
  briicksImages: Array<BriicksImage>;
  briicksLastUpdateDate: Scalars['DateTime'];
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: Maybe<BriicksParsedAddress>;
  briicksShortCode?: Maybe<Scalars['String']>;
  briicksSource: Listing_Source_Names;
  buyback?: Maybe<Scalars['Boolean']>;
  buyback_appreciated_value?: Maybe<Scalars['Float']>;
  buyback_apr?: Maybe<Scalars['String']>;
  buyback_end_datetime?: Maybe<Scalars['DateTime']>;
  buyback_start_datetime?: Maybe<Scalars['DateTime']>;
  buyback_total_amount?: Maybe<Scalars['Float']>;
  buyback_triggered_datetime?: Maybe<Scalars['DateTime']>;
  buyback_year?: Maybe<Scalars['String']>;
  buyback_yearly_appreciation_percentage?: Maybe<Scalars['String']>;
  closing_datetime?: Maybe<Scalars['DateTime']>;
  coming_soon?: Maybe<Scalars['Boolean']>;
  has_pending_offer?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  is_bookmarked?: Maybe<Scalars['Boolean']>;
  is_owner: Scalars['Boolean'];
  listed_equity_percentage: Scalars['String'];
  marketplace_datetime?: Maybe<Scalars['DateTime']>;
  maximum_allowed_equity_percentage?: Maybe<Scalars['Float']>;
  minimum_allowed_equity_percentage?: Maybe<Scalars['Float']>;
  minimum_listed_equity_percentage?: Maybe<Scalars['String']>;
  monthly_future_value?: Maybe<Scalars['Float']>;
  mortgage_close_datetime?: Maybe<Scalars['DateTime']>;
  mortgage_exit_amount?: Maybe<Scalars['Float']>;
  offering_document?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  present_value?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  price_per_square_foot: Scalars['String'];
  property: VestaEquityProperty;
  publication_datetime?: Maybe<Scalars['DateTime']>;
  recording_completed?: Maybe<Scalars['Boolean']>;
  seller_type: Scalars['String'];
  sold_equity_percentage: Scalars['String'];
  status: Scalars['String'];
  total_visit_count?: Maybe<Scalars['Int']>;
  unique_visit_count?: Maybe<Scalars['Int']>;
};

export type VestaEquityAnalysis = {
  __typename?: 'VestaEquityAnalysis';
  one_year_roi: Scalars['Float'];
  ten_year_historical: Scalars['Float'];
  ten_year_historical_cagr: Scalars['Float'];
  three_year_roi: Scalars['Float'];
};

export type VestaEquityAnalysisInput = {
  one_year_roi: Scalars['Float'];
  ten_year_historical: Scalars['Float'];
  ten_year_historical_cagr: Scalars['Float'];
  three_year_roi: Scalars['Float'];
};

export type VestaEquityAppraisalReport = {
  __typename?: 'VestaEquityAppraisalReport';
  appraisal_date: Scalars['String'];
  max_equity_percentage_for_listing: Scalars['String'];
  pdf_document: Scalars['String'];
  property_value: Scalars['String'];
  title_service_attachment?: Maybe<Scalars['String']>;
  title_service_fee?: Maybe<Scalars['String']>;
};

export type VestaEquityAppraisalReportInput = {
  appraisal_date: Scalars['String'];
  max_equity_percentage_for_listing: Scalars['String'];
  pdf_document: Scalars['String'];
  property_value: Scalars['String'];
  title_service_attachment?: InputMaybe<Scalars['String']>;
  title_service_fee?: InputMaybe<Scalars['String']>;
};

export type VestaEquityAssessment = {
  __typename?: 'VestaEquityAssessment';
  apn?: Maybe<Scalars['String']>;
  assessment_year?: Maybe<Scalars['Int']>;
  tax_amount?: Maybe<Scalars['String']>;
  tax_year?: Maybe<Scalars['Int']>;
  total_assessed_value?: Maybe<Scalars['Int']>;
};

export type VestaEquityAssessmentInput = {
  apn?: InputMaybe<Scalars['String']>;
  assessment_year?: InputMaybe<Scalars['Int']>;
  tax_amount?: InputMaybe<Scalars['String']>;
  tax_year?: InputMaybe<Scalars['Int']>;
  total_assessed_value?: InputMaybe<Scalars['Int']>;
};

export type VestaEquityAsset = {
  __typename?: 'VestaEquityAsset';
  algorand_asset_id: Scalars['Int'];
  asset_name: Scalars['String'];
  id: Scalars['Int'];
  property: Scalars['String'];
  unit_name: Scalars['String'];
};

export type VestaEquityAssetInput = {
  algorand_asset_id: Scalars['Int'];
  asset_name: Scalars['String'];
  id: Scalars['Int'];
  property: Scalars['String'];
  unit_name: Scalars['String'];
};

export type VestaEquityDetails = {
  __typename?: 'VestaEquityDetails';
  air_conditioning?: Maybe<Scalars['String']>;
  attic?: Maybe<Scalars['Boolean']>;
  basement?: Maybe<Scalars['String']>;
  bathroom_count: Scalars['String'];
  bedroom_count: Scalars['Int'];
  condition_score?: Maybe<Scalars['Int']>;
  construction_type?: Maybe<Scalars['String']>;
  exterior_walls?: Maybe<Scalars['String']>;
  fireplace?: Maybe<Scalars['Boolean']>;
  full_bath_count: Scalars['Int'];
  garage_type_parking?: Maybe<Scalars['String']>;
  heating?: Maybe<Scalars['String']>;
  number_of_buildings?: Maybe<Scalars['Int']>;
  number_of_stories?: Maybe<Scalars['Int']>;
  number_of_units?: Maybe<Scalars['Int']>;
  parking_space_count?: Maybe<Scalars['Int']>;
  partial_bath_count?: Maybe<Scalars['String']>;
  pool?: Maybe<Scalars['Boolean']>;
  property_type: Scalars['String'];
  quality_score?: Maybe<Scalars['Int']>;
  roof_cover?: Maybe<Scalars['String']>;
  roof_type?: Maybe<Scalars['String']>;
  sewer?: Maybe<Scalars['String']>;
  site_area_acres?: Maybe<Scalars['String']>;
  square_feet: Scalars['Float'];
  style?: Maybe<Scalars['String']>;
  subdivision?: Maybe<Scalars['String']>;
  valuation_type: Scalars['String'];
  water?: Maybe<Scalars['String']>;
  year_built?: Maybe<Scalars['Int']>;
  zoning?: Maybe<Scalars['String']>;
};

export type VestaEquityDetailsInput = {
  air_conditioning?: InputMaybe<Scalars['String']>;
  attic?: InputMaybe<Scalars['Boolean']>;
  basement?: InputMaybe<Scalars['String']>;
  bathroom_count: Scalars['String'];
  bedroom_count: Scalars['Int'];
  condition_score?: InputMaybe<Scalars['Int']>;
  construction_type?: InputMaybe<Scalars['String']>;
  exterior_walls?: InputMaybe<Scalars['String']>;
  fireplace?: InputMaybe<Scalars['Boolean']>;
  full_bath_count: Scalars['Int'];
  garage_type_parking?: InputMaybe<Scalars['String']>;
  heating?: InputMaybe<Scalars['String']>;
  number_of_buildings?: InputMaybe<Scalars['Int']>;
  number_of_stories?: InputMaybe<Scalars['Int']>;
  number_of_units?: InputMaybe<Scalars['Int']>;
  parking_space_count?: InputMaybe<Scalars['Int']>;
  partial_bath_count?: InputMaybe<Scalars['String']>;
  pool?: InputMaybe<Scalars['Boolean']>;
  property_type: Scalars['String'];
  quality_score?: InputMaybe<Scalars['Int']>;
  roof_cover?: InputMaybe<Scalars['String']>;
  roof_type?: InputMaybe<Scalars['String']>;
  sewer?: InputMaybe<Scalars['String']>;
  site_area_acres?: InputMaybe<Scalars['String']>;
  square_feet: Scalars['Float'];
  style?: InputMaybe<Scalars['String']>;
  subdivision?: InputMaybe<Scalars['String']>;
  valuation_type: Scalars['String'];
  water?: InputMaybe<Scalars['String']>;
  year_built?: InputMaybe<Scalars['Int']>;
  zoning?: InputMaybe<Scalars['String']>;
};

export type VestaEquityDocument = {
  __typename?: 'VestaEquityDocument';
  amount?: Maybe<Scalars['String']>;
  file: Scalars['String'];
  id: Scalars['String'];
};

export type VestaEquityDocumentInput = {
  amount?: InputMaybe<Scalars['String']>;
  file: Scalars['String'];
  id: Scalars['String'];
};

export type VestaEquityInput = {
  annual_future_value?: InputMaybe<Scalars['Float']>;
  briicksCalculatedMetrics: BriicksCalculatedMetricsInput;
  briicksCompanyInfos?: InputMaybe<BriicksCompanyInfosInput>;
  briicksImages: Array<Scalars['String']>;
  briicksListingId: Scalars['String'];
  briicksListingURL: Scalars['String'];
  briicksParsedAddress?: InputMaybe<BriicksParsedAddressInput>;
  buyback?: InputMaybe<Scalars['Boolean']>;
  buyback_appreciated_value?: InputMaybe<Scalars['Float']>;
  buyback_apr?: InputMaybe<Scalars['String']>;
  buyback_end_datetime?: InputMaybe<Scalars['DateTime']>;
  buyback_start_datetime?: InputMaybe<Scalars['DateTime']>;
  buyback_total_amount?: InputMaybe<Scalars['Float']>;
  buyback_triggered_datetime?: InputMaybe<Scalars['DateTime']>;
  buyback_year?: InputMaybe<Scalars['String']>;
  buyback_yearly_appreciation_percentage?: InputMaybe<Scalars['String']>;
  closing_datetime?: InputMaybe<Scalars['DateTime']>;
  coming_soon?: InputMaybe<Scalars['Boolean']>;
  has_pending_offer?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  is_bookmarked?: InputMaybe<Scalars['Boolean']>;
  is_owner: Scalars['Boolean'];
  listed_equity_percentage: Scalars['String'];
  marketplace_datetime?: InputMaybe<Scalars['DateTime']>;
  maximum_allowed_equity_percentage?: InputMaybe<Scalars['Float']>;
  minimum_allowed_equity_percentage?: InputMaybe<Scalars['Float']>;
  minimum_listed_equity_percentage?: InputMaybe<Scalars['String']>;
  monthly_future_value?: InputMaybe<Scalars['Float']>;
  mortgage_close_datetime?: InputMaybe<Scalars['DateTime']>;
  mortgage_exit_amount?: InputMaybe<Scalars['Float']>;
  offering_document?: InputMaybe<Scalars['String']>;
  owner: Scalars['String'];
  present_value?: InputMaybe<Scalars['String']>;
  price: Scalars['String'];
  price_per_square_foot: Scalars['String'];
  property: VestaEquityPropertyInput;
  publication_datetime?: InputMaybe<Scalars['DateTime']>;
  recording_completed?: InputMaybe<Scalars['Boolean']>;
  seller_type: Scalars['String'];
  sold_equity_percentage: Scalars['String'];
  status: Scalars['String'];
  total_visit_count?: InputMaybe<Scalars['Int']>;
  unique_visit_count?: InputMaybe<Scalars['Int']>;
};

export type VestaEquityLocationCoordinate = {
  __typename?: 'VestaEquityLocationCoordinate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type VestaEquityLocationCoordinateInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type VestaEquityMedia = {
  __typename?: 'VestaEquityMedia';
  id: Scalars['String'];
  image: Scalars['String'];
  order: Scalars['Int'];
  video?: Maybe<Scalars['String']>;
};

export type VestaEquityMediaInput = {
  id: Scalars['String'];
  image: Scalars['String'];
  order: Scalars['Int'];
  video?: InputMaybe<Scalars['String']>;
};

export type VestaEquityProperty = {
  __typename?: 'VestaEquityProperty';
  adjusted_appraised_value?: Maybe<Scalars['String']>;
  analysis: VestaEquityAnalysis;
  appraisal_report: VestaEquityAppraisalReport;
  appraisal_status: Scalars['String'];
  assessment: VestaEquityAssessment;
  asset?: Maybe<VestaEquityAsset>;
  city: Scalars['String'];
  current_mortgage_amount?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  details: VestaEquityDetails;
  documents: Array<VestaEquityDocument>;
  has_mortgage?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  is_fee_paid?: Maybe<Scalars['Boolean']>;
  is_photography_paid?: Maybe<Scalars['Boolean']>;
  location_coordinates: VestaEquityLocationCoordinate;
  market_value: Scalars['String'];
  market_value_per_square_foot: Scalars['String'];
  media: Array<VestaEquityMedia>;
  minimum_investment_amount?: Maybe<Scalars['Float']>;
  mortgage_documents: Array<VestaEquityDocument>;
  owned_equity_percentage?: Maybe<Scalars['Float']>;
  postal_code: Scalars['String'];
  state: Scalars['String'];
  street_address: Scalars['String'];
  thirty_day_appreciation?: Maybe<Scalars['Float']>;
};

export type VestaEquityPropertyInput = {
  adjusted_appraised_value?: InputMaybe<Scalars['String']>;
  analysis: VestaEquityAnalysisInput;
  appraisal_report: VestaEquityAppraisalReportInput;
  appraisal_status: Scalars['String'];
  assessment: VestaEquityAssessmentInput;
  asset?: InputMaybe<VestaEquityAssetInput>;
  city: Scalars['String'];
  current_mortgage_amount?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  details: VestaEquityDetailsInput;
  documents: Array<VestaEquityDocumentInput>;
  has_mortgage?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  is_fee_paid?: InputMaybe<Scalars['Boolean']>;
  is_photography_paid?: InputMaybe<Scalars['Boolean']>;
  location_coordinates: VestaEquityLocationCoordinateInput;
  market_value: Scalars['String'];
  market_value_per_square_foot: Scalars['String'];
  media: Array<VestaEquityMediaInput>;
  minimum_investment_amount?: InputMaybe<Scalars['Float']>;
  mortgage_documents: Array<VestaEquityDocumentInput>;
  owned_equity_percentage?: InputMaybe<Scalars['Float']>;
  postal_code: Scalars['String'];
  state: Scalars['String'];
  street_address: Scalars['String'];
  thirty_day_appreciation?: InputMaybe<Scalars['Float']>;
};

export type TradingInput = {
  buy?: InputMaybe<TradingMinMaxInput>;
  buyOrderTokens?: InputMaybe<Scalars['Int']>;
  sell?: InputMaybe<TradingMinMaxInput>;
  sellOrderTokens?: InputMaybe<Scalars['Int']>;
};

export type TradingMinMaxInput = {
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type GetBriicksListingFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetBriicksListingFiltersQuery = {
  __typename?: 'Query';
  getBriicksListingFilters:
    | { __typename: 'Error'; code: string; message: string }
    | {
        __typename: 'GetBriicksListingFiltersSuccess';
        filters: {
          __typename?: 'BriicksListingFilters';
          status: Array<AdsStatus>;
          makers: Array<Listing_Source_Names>;
          countries: Array<CountryIso2Codes>;
        };
      };
};

export type GetBriicksListingDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetBriicksListingDetailsQuery = {
  __typename?: 'Query';
  getBriicksListingDetails:
    | { __typename: 'Error'; code: string; message: string }
    | {
        __typename: 'GetBriicksListingDetailsSuccess';
        listing: {
          __typename?: 'BriicksListing';
          _id: string;
          status: AdsStatus;
          source: Listing_Source_Names;
          makerName: string;
          tokenizedFractionalized: boolean;
          title: string;
          shortCode: string;
          transactionType: Array<TransactionType>;
          property_manager?: { __typename?: 'PropertyManager'; name: string; website?: string | null } | null;
          ledger: { __typename?: 'Ledger'; type: string; network: string };
          briicks: {
            __typename?: 'Briicks';
            minTakers: number;
            maxTakers: number;
            maxBriicksPurchase: number;
            minBriicksPurchase: number;
            price: number;
            distribution: {
              __typename?: 'Distribution';
              totalShares: number;
              hasReservedShares: boolean;
              unallocated: number;
              sharesOnSale: number;
              stock: number;
              sharesOffSale: number;
              reservedShare?: Array<{
                __typename?: 'ReservedShare';
                name: string;
                as: string;
                shares: number;
                description?: string | null;
              }> | null;
            };
            restrictions?: {
              __typename?: 'BriicksRestrictions';
              hasRestriction: boolean;
              description?: string | null;
              restrictionItems?: Array<{
                __typename?: 'BriicksRestrictionItem';
                title: string;
                quantity: number;
                stock: number;
                url?: string | null;
                description?: string | null;
                document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
              }> | null;
            } | null;
          };
          calendar: {
            __typename?: 'Calendar';
            advertise: any;
            salesStart: any;
            otherDates?: Array<{ __typename?: 'OtherDate'; title?: string | null; date?: any | null }> | null;
          };
          preferences: {
            __typename?: 'Preference';
            measurement: MeasurementsCode;
            currency: CurrencyCodes;
            language: LanguagesCode;
            redirection: {
              __typename?: 'AdsRedirection';
              mode: RedirectionModes;
              default?: string | null;
              multiRedirections?: Array<{ __typename?: 'URLDescription'; description: string; url: string }> | null;
            };
          };
          asset: {
            __typename?: 'Asset';
            description: string;
            video?: string | null;
            location: {
              __typename?: 'Location';
              address: {
                __typename?: 'Address';
                country: CountryIso2Codes;
                number: string;
                street: string;
                neighborhood?: string | null;
                zipCode: string;
                stateRegion: string;
                departement?: string | null;
                city: { __typename?: 'City'; name: string; descriptrion: string };
              };
              coordinates: { __typename?: 'Coordinates'; longitude: number; latitude: number };
              inside?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              anex?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
            };
            pictures: {
              __typename?: 'Pictures';
              featured: string;
              galleries?: Array<{ __typename?: 'Gallery'; name: string; pictures: Array<string> }> | null;
            };
            plans?: Array<{
              __typename?: 'Plans';
              name: string;
              documents: Array<{ __typename?: 'Document'; source: string; mimeType: string }>;
            }> | null;
            view_3d?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
            assetType: { __typename?: 'AssetType'; destination: AssetDestination; type: string; area: number };
            assetCondition: {
              __typename?: 'AssetCondition';
              description?: string | null;
              event: {
                __typename?: 'AssetEvents';
                constructionYear: { __typename?: 'ConstructionYear'; year: string };
                eventMetadata?: Array<{ __typename?: 'EventMetadata'; title: string; date: any }> | null;
              };
            };
            assetFeatures: Array<{
              __typename?: 'AssetFeatures';
              name: string;
              metadata?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
            }>;
          };
          spv?: {
            __typename?: 'SPV';
            name: string;
            registrationPlace: string;
            registrationNumber: string;
            sponsor: { __typename?: 'Sponsor'; name: string; registrationPlace: string; registrationNumber: string };
            metadata?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
            documents: {
              __typename?: 'SPVDocuments';
              establishementCertificate: { __typename?: 'Document'; source: string; mimeType: string };
              otherDocuments?: Array<{
                __typename?: 'OtherDocument';
                description: string;
                document: { __typename?: 'Document'; source: string; mimeType: string };
              }> | null;
            };
          } | null;
          financial: {
            __typename?: 'Financial';
            assetValue: {
              __typename?: 'AssetValue';
              totalAssetValue: number;
              metadata?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
            };
            aquisitionCost: {
              __typename?: 'AquisitionCost';
              totalCost: number;
              metadata?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              closingCost?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              reserve?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              setupCost?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              otherCosts?: Array<{
                __typename?: 'OtherFinancialMetadata';
                name: string;
                metadata: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }>;
              }> | null;
            };
            holdingCost: {
              __typename?: 'HoldingCost';
              totalCost: number;
              utilities?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              maintenance?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              inusrance?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              taxes?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              management?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              administrative?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              debt?: Array<{ __typename?: 'FinancialMetadata'; title: string; value: number }> | null;
              otherCosts?: Array<{
                __typename?: 'OtherFinancialMetadata';
                name: string;
                metadata: Array<{
                  __typename?: 'FinancialMetadata';
                  value: number;
                  title: string;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }>;
              }> | null;
            };
          };
          flipping?: {
            __typename?: 'Flipping';
            investment: { __typename?: 'FlippingInvestment'; totalAmount: number; holdingDuration: number };
            income: { __typename?: 'FlippingIncome'; totalAmount: number };
            operationDuration: {
              __typename?: 'OperationDuration';
              totalDuration: number;
              metadata: Array<{ __typename?: 'DurationMetadata'; title: string; value: number }>;
            };
            profitability: {
              __typename?: 'FlippingProfitability';
              profit: number;
              roiRation: number;
              holdingTime: number;
            };
          } | null;
          rental?: {
            __typename?: 'Rental';
            investment: { __typename?: 'RentalInvestment'; totalAmount: number };
            leaseDetails: {
              __typename?: 'LeaseDetails';
              description?: string | null;
              type: string;
              statut: string;
              annualGrossRent: number;
              startDate?: any | null;
              endtDate?: any | null;
              settlementFrequency?: string | null;
            };
            profitability: {
              __typename?: 'RentalProfitability';
              cashFlow: number;
              cashOnCash: number;
              assetAppreciation?: number | null;
              internalRateOfReturn?: number | null;
            };
          } | null;
        };
      };
};

export type GetBriicksListingsQueryVariables = Exact<{
  filters?: InputMaybe<BriicksListingsFiltersArgs>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<BriicksListingsSortArgs>;
}>;

export type GetBriicksListingsQuery = {
  __typename?: 'Query';
  getBriicksListings:
    | { __typename: 'Error'; code: string; message: string }
    | {
        __typename: 'GetBriicksListingSuccess';
        result: {
          __typename?: 'BriicksListingPaginate';
          pagingCounter: number;
          nextPage?: number | null;
          prevPage?: number | null;
          offset?: number | null;
          totalPages: number;
          page: number;
          hasNextPage?: boolean | null;
          hasPrevPage?: boolean | null;
          limit: number;
          totalDocs: number;
          docs: Array<{
            __typename?: 'BriicksListing';
            _id: string;
            status: AdsStatus;
            source: Listing_Source_Names;
            makerName: string;
            tokenizedFractionalized: boolean;
            title: string;
            shortCode: string;
            transactionType: Array<TransactionType>;
            property_manager?: { __typename?: 'PropertyManager'; name: string; website?: string | null } | null;
            ledger: { __typename?: 'Ledger'; type: string; network: string };
            briicks: {
              __typename?: 'Briicks';
              minTakers: number;
              maxTakers: number;
              maxBriicksPurchase: number;
              minBriicksPurchase: number;
              price: number;
              distribution: {
                __typename?: 'Distribution';
                totalShares: number;
                hasReservedShares: boolean;
                unallocated: number;
                sharesOnSale: number;
                stock: number;
                sharesOffSale: number;
                reservedShare?: Array<{
                  __typename?: 'ReservedShare';
                  name: string;
                  as: string;
                  shares: number;
                  description?: string | null;
                }> | null;
              };
              restrictions?: {
                __typename?: 'BriicksRestrictions';
                hasRestriction: boolean;
                description?: string | null;
                restrictionItems?: Array<{
                  __typename?: 'BriicksRestrictionItem';
                  title: string;
                  quantity: number;
                  stock: number;
                  url?: string | null;
                  description?: string | null;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
              } | null;
            };
            calendar: {
              __typename?: 'Calendar';
              advertise: any;
              salesStart: any;
              otherDates?: Array<{ __typename?: 'OtherDate'; title?: string | null; date?: any | null }> | null;
            };
            preferences: {
              __typename?: 'Preference';
              measurement: MeasurementsCode;
              currency: CurrencyCodes;
              language: LanguagesCode;
              redirection: {
                __typename?: 'AdsRedirection';
                mode: RedirectionModes;
                default?: string | null;
                multiRedirections?: Array<{ __typename?: 'URLDescription'; description: string; url: string }> | null;
              };
            };
            asset: {
              __typename?: 'Asset';
              description: string;
              video?: string | null;
              location: {
                __typename?: 'Location';
                address: {
                  __typename?: 'Address';
                  country: CountryIso2Codes;
                  number: string;
                  street: string;
                  neighborhood?: string | null;
                  zipCode: string;
                  stateRegion: string;
                  departement?: string | null;
                  city: { __typename?: 'City'; name: string; descriptrion: string };
                };
                coordinates: { __typename?: 'Coordinates'; longitude: number; latitude: number };
                inside?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
                anex?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              };
              pictures: {
                __typename?: 'Pictures';
                featured: string;
                galleries?: Array<{ __typename?: 'Gallery'; name: string; pictures: Array<string> }> | null;
              };
              plans?: Array<{
                __typename?: 'Plans';
                name: string;
                documents: Array<{ __typename?: 'Document'; source: string; mimeType: string }>;
              }> | null;
              view_3d?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              assetType: { __typename?: 'AssetType'; destination: AssetDestination; type: string; area: number };
              assetCondition: {
                __typename?: 'AssetCondition';
                description?: string | null;
                event: {
                  __typename?: 'AssetEvents';
                  constructionYear: {
                    __typename?: 'ConstructionYear';
                    year: string;
                    document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                  };
                  eventMetadata?: Array<{
                    __typename?: 'EventMetadata';
                    title: string;
                    date: any;
                    document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                  }> | null;
                };
              };
              assetFeatures: Array<{
                __typename?: 'AssetFeatures';
                name: string;
                metadata?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              }>;
            };
            spv?: {
              __typename?: 'SPV';
              name: string;
              registrationPlace: string;
              registrationNumber: string;
              sponsor: { __typename?: 'Sponsor'; name: string; registrationPlace: string; registrationNumber: string };
              metadata?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              documents: {
                __typename?: 'SPVDocuments';
                establishementCertificate: { __typename?: 'Document'; source: string; mimeType: string };
                otherDocuments?: Array<{
                  __typename?: 'OtherDocument';
                  description: string;
                  document: { __typename?: 'Document'; source: string; mimeType: string };
                }> | null;
              };
            } | null;
            flipping?: {
              __typename?: 'Flipping';
              investment: { __typename?: 'FlippingInvestment'; totalAmount: number; holdingDuration: number };
              income: { __typename?: 'FlippingIncome'; totalAmount: number };
              operationDuration: {
                __typename?: 'OperationDuration';
                totalDuration: number;
                metadata: Array<{ __typename?: 'DurationMetadata'; title: string; value: number }>;
              };
              profitability: {
                __typename?: 'FlippingProfitability';
                profit: number;
                roiRation: number;
                holdingTime: number;
              };
            } | null;
            rental?: {
              __typename?: 'Rental';
              investment: { __typename?: 'RentalInvestment'; totalAmount: number };
              leaseDetails: {
                __typename?: 'LeaseDetails';
                description?: string | null;
                type: string;
                statut: string;
                annualGrossRent: number;
                startDate?: any | null;
                endtDate?: any | null;
                settlementFrequency?: string | null;
              };
              profitability: {
                __typename?: 'RentalProfitability';
                cashFlow: number;
                cashOnCash: number;
                assetAppreciation?: number | null;
                internalRateOfReturn?: number | null;
              };
            } | null;
            financial: {
              __typename?: 'Financial';
              assetValue: {
                __typename?: 'AssetValue';
                totalAssetValue: number;
                metadata?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
              };
              aquisitionCost: {
                __typename?: 'AquisitionCost';
                totalCost: number;
                closingCost?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                reserve?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                setupCost?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                otherCosts?: Array<{
                  __typename?: 'OtherFinancialMetadata';
                  name: string;
                  metadata: Array<{
                    __typename?: 'FinancialMetadata';
                    title: string;
                    value: number;
                    document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                  }>;
                }> | null;
                metadata?: Array<{ __typename?: 'Metadata'; key: string; value: string }> | null;
              };
              holdingCost: {
                __typename?: 'HoldingCost';
                totalCost: number;
                utilities?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                maintenance?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                inusrance?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                taxes?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                management?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                administrative?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                }> | null;
                debt?: Array<{
                  __typename?: 'FinancialMetadata';
                  title: string;
                  value: number;
                  document?: { __typename?: 'Document'; mimeType: string; source: string } | null;
                }> | null;
                otherCosts?: Array<{
                  __typename?: 'OtherFinancialMetadata';
                  name: string;
                  metadata: Array<{
                    __typename?: 'FinancialMetadata';
                    value: number;
                    title: string;
                    document?: { __typename?: 'Document'; source: string; mimeType: string } | null;
                  }>;
                }> | null;
              };
            };
          }>;
        };
      };
};

export const GetBriicksListingFiltersDocument = gql`
  query GetBriicksListingFilters {
    getBriicksListingFilters {
      ... on Error {
        __typename
        code
        message
      }
      ... on GetBriicksListingFiltersSuccess {
        __typename
        filters {
          status
          makers
          countries
        }
      }
    }
  }
`;
export const GetBriicksListingDetailsDocument = gql`
  query GetBriicksListingDetails($id: String!) {
    getBriicksListingDetails(id: $id) {
      ... on Error {
        __typename
        code
        message
      }
      ... on GetBriicksListingDetailsSuccess {
        __typename
        listing {
          _id
          status
          source
          makerName
          property_manager {
            name
            website
          }
          tokenizedFractionalized
          title
          ledger {
            type
            network
          }
          shortCode
          briicks {
            minTakers
            maxTakers
            maxBriicksPurchase
            minBriicksPurchase
            price
            distribution {
              totalShares
              hasReservedShares
              unallocated
              sharesOnSale
              stock
              sharesOffSale
              reservedShare {
                name
                as
                shares
                description
              }
            }
            restrictions {
              hasRestriction
              description
              restrictionItems {
                title
                quantity
                stock
                url
                description
                document {
                  source
                  mimeType
                }
              }
            }
          }
          calendar {
            advertise
            salesStart
            otherDates {
              title
              date
            }
          }
          transactionType
          preferences {
            measurement
            currency
            language
            redirection {
              mode
              default
              multiRedirections {
                description
                url
              }
            }
          }
          asset {
            description
            video
            location {
              address {
                country
                number
                street
                neighborhood
                city {
                  name
                  descriptrion
                }
                zipCode
                stateRegion
                departement
              }
              coordinates {
                longitude
                latitude
              }
              inside {
                key
                value
              }
              anex {
                key
                value
              }
            }
            pictures {
              featured
              galleries {
                name
                pictures
              }
            }
            plans {
              name
              documents {
                source
                mimeType
              }
            }
            view_3d {
              key
              value
            }
            assetType {
              destination
              type
              area
            }
            assetCondition {
              description
              event {
                constructionYear {
                  year
                }
                eventMetadata {
                  title
                  date
                }
              }
            }
            assetFeatures {
              name
              metadata {
                key
                value
              }
            }
          }
          spv {
            name
            registrationPlace
            registrationNumber
            sponsor {
              name
              registrationPlace
              registrationNumber
            }
            metadata {
              key
              value
            }
            documents {
              establishementCertificate {
                source
                mimeType
              }
              otherDocuments {
                description
                document {
                  source
                  mimeType
                }
              }
            }
          }
          financial {
            assetValue {
              totalAssetValue
              metadata {
                title
                value
              }
            }
            aquisitionCost {
              totalCost
              metadata {
                key
                value
              }
              closingCost {
                title
                value
              }
              reserve {
                title
                value
              }
              setupCost {
                title
                value
              }
              otherCosts {
                name
                metadata {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
              }
            }
            holdingCost {
              totalCost
              utilities {
                title
                value
              }
              maintenance {
                title
                value
              }
              inusrance {
                title
                value
              }
              taxes {
                title
                value
              }
              management {
                title
                value
              }
              administrative {
                title
                value
              }
              debt {
                title
                value
              }
              otherCosts {
                name
                metadata {
                  value
                  title
                  document {
                    source
                    mimeType
                  }
                }
              }
            }
          }
          flipping {
            investment {
              totalAmount
              holdingDuration
            }
            income {
              totalAmount
            }
            operationDuration {
              totalDuration
              metadata {
                title
                value
              }
            }
            profitability {
              profit
              roiRation
              holdingTime
            }
          }
          rental {
            investment {
              totalAmount
            }
            leaseDetails {
              description
              type
              statut
              annualGrossRent
              startDate
              endtDate
              settlementFrequency
            }
            profitability {
              cashFlow
              cashOnCash
              assetAppreciation
              internalRateOfReturn
            }
          }
        }
      }
    }
  }
`;
export const GetBriicksListingsDocument = gql`
  query GetBriicksListings(
    $filters: BriicksListingsFiltersArgs
    $page: Int
    $limit: Int
    $sort: BriicksListingsSortArgs
  ) {
    getBriicksListings(filters: $filters, page: $page, limit: $limit, sort: $sort) {
      ... on Error {
        __typename
        code
        message
      }
      ... on GetBriicksListingSuccess {
        __typename
        result {
          pagingCounter
          nextPage
          prevPage
          offset
          totalPages
          page
          hasNextPage
          hasPrevPage
          limit
          totalDocs
          docs {
            _id
            status
            source
            makerName
            property_manager {
              name
              website
            }
            tokenizedFractionalized
            title
            ledger {
              type
              network
            }
            shortCode
            briicks {
              minTakers
              maxTakers
              maxBriicksPurchase
              minBriicksPurchase
              price
              distribution {
                totalShares
                hasReservedShares
                unallocated
                sharesOnSale
                stock
                sharesOffSale
                reservedShare {
                  name
                  as
                  shares
                  description
                }
              }
              restrictions {
                hasRestriction
                description
                restrictionItems {
                  title
                  quantity
                  stock
                  url
                  description
                  document {
                    source
                    mimeType
                  }
                }
              }
            }
            calendar {
              advertise
              salesStart
              otherDates {
                title
                date
              }
            }
            transactionType
            preferences {
              measurement
              currency
              language
              redirection {
                mode
                default
                multiRedirections {
                  description
                  url
                }
              }
            }
            asset {
              description
              video
              location {
                address {
                  country
                  number
                  street
                  neighborhood
                  city {
                    name
                    descriptrion
                  }
                  zipCode
                  stateRegion
                  departement
                }
                coordinates {
                  longitude
                  latitude
                }
                inside {
                  key
                  value
                }
                anex {
                  key
                  value
                }
              }
              pictures {
                featured
                galleries {
                  name
                  pictures
                }
              }
              plans {
                name
                documents {
                  source
                  mimeType
                }
              }
              view_3d {
                key
                value
              }
              assetType {
                destination
                type
                area
              }
              assetCondition {
                description
                event {
                  constructionYear {
                    year
                    document {
                      source
                      mimeType
                    }
                  }
                  eventMetadata {
                    title
                    document {
                      source
                      mimeType
                    }
                    date
                  }
                }
              }
              assetFeatures {
                name
                metadata {
                  key
                  value
                }
              }
            }
            spv {
              name
              registrationPlace
              registrationNumber
              sponsor {
                name
                registrationPlace
                registrationNumber
              }
              metadata {
                key
                value
              }
              documents {
                establishementCertificate {
                  source
                  mimeType
                }
                otherDocuments {
                  description
                  document {
                    source
                    mimeType
                  }
                }
              }
            }
            flipping {
              investment {
                totalAmount
                holdingDuration
              }
              income {
                totalAmount
              }
              operationDuration {
                totalDuration
                metadata {
                  title
                  value
                }
              }
              profitability {
                profit
                roiRation
                holdingTime
              }
            }
            rental {
              investment {
                totalAmount
              }
              leaseDetails {
                description
                type
                statut
                annualGrossRent
                startDate
                endtDate
                settlementFrequency
              }
              profitability {
                cashFlow
                cashOnCash
                assetAppreciation
                internalRateOfReturn
              }
            }
            financial {
              assetValue {
                totalAssetValue
                metadata {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
              }
              aquisitionCost {
                totalCost
                closingCost {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                reserve {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                setupCost {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                otherCosts {
                  name
                  metadata {
                    title
                    document {
                      source
                      mimeType
                    }
                    value
                  }
                }
                metadata {
                  key
                  value
                }
              }
              holdingCost {
                totalCost
                utilities {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                maintenance {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                inusrance {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                taxes {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                management {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                administrative {
                  title
                  value
                  document {
                    source
                    mimeType
                  }
                }
                debt {
                  title
                  value
                  document {
                    mimeType
                    source
                  }
                }
                otherCosts {
                  name
                  metadata {
                    value
                    title
                    document {
                      source
                      mimeType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetBriicksListingFilters(
      variables?: GetBriicksListingFiltersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetBriicksListingFiltersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBriicksListingFiltersQuery>(GetBriicksListingFiltersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetBriicksListingFilters',
        'query',
      );
    },
    GetBriicksListingDetails(
      variables: GetBriicksListingDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetBriicksListingDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBriicksListingDetailsQuery>(GetBriicksListingDetailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetBriicksListingDetails',
        'query',
      );
    },
    GetBriicksListings(
      variables?: GetBriicksListingsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<GetBriicksListingsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBriicksListingsQuery>(GetBriicksListingsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetBriicksListings',
        'query',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
