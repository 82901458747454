import Investment from './investement';
import HoldingCost from './holding-cost';
import Profitability from './profitability';
import Debt from './debt';
import { IFullAdsSection } from 'components/common/types';
import SectionContainer from '../../../article/section-container';

export const elements: IFullAdsSection[] = [
  {
    name: 'Investment',
    element: Investment,
  },
  {
    name: 'Holding cost',
    element: HoldingCost,
  },
  {
    name: 'Profitability',
    element: Profitability,
  },
  {
    name: 'Debt',
    element: Debt,
  },
];
const Financial = (props: { setVisibleArticle: (articleName: string) => void }) => {
  return (
    <SectionContainer>
      {elements.map((e, index) => {
        const V = e.element;

        return (
          <V
            id={e.name}
            key={index}
            viewPortObserver={(isVisible: boolean) => {
              if (isVisible) props.setVisibleArticle(elements[index].name);
            }}
          />
        );
      })}
    </SectionContainer>
  );
};

export default Financial;
