import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import tw from 'twin.macro';

const AmenitiesComponent = () => {
  return (
    <Box sx={{ width: 850, alignSelf: 'center' }}>
      <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
        Amenities
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              padding: 1,
              //border: 1
            },
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                no data
              </TableCell>
              <TableCell align="right" sx={tw`capitalize`}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AmenitiesComponent;
