import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Ads } from 'graphql/schema/__generated__/graphql-types';
import { Box, List, ListItem, Typography } from '@mui/material';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Title = tw.h2`
text-blue-500 font-medium text-xl mt-2
`;

const InvestmentType = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
      <Headline>Investment Type</Headline>
      <Divider />
      <Title css={tw`inline`}>
        This offer presents a <Title css={tw`inline text-green-500`}>RENTAL-TYPE</Title> investment opportunity
      </Title>
      <Typography variant="body2" gutterBottom sx={{ marginTop: 1 }}>
        Rental investment involves purchasing a property with the intention of leasing it to tenants, thereby generating
        income.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Key factors to optimize profitability in rental investment include the quality and location of the property,
        stability of rental income, market conditions, and effective management.
      </Typography>
      <Typography variant="body2" gutterBottom>
        While rental investment primarily focuses on rental yield, it also offers potential for capital gains during an
        opportune resale.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Therefore, you have the flexibility to retain your Briicks, sell them individually anytime at your discretion,
        or collectively when a predefined duration is reached or if the property is sold by a majority vote.
      </Typography>

      <Typography variant="body2" sx={{ marginTop: 1 }}>
        <strong>Advantages:</strong>
      </Typography>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            padding: 0,
          },
        }}
      >
        <ListItem>Regular rental income</ListItem>
        <ListItem>Potential capital appreciation upon resale</ListItem>
        <ListItem>Tax benefits</ListItem>
        <ListItem>Diversification of investment portfolio</ListItem>
      </List>

      <Typography variant="body2" sx={{ marginTop: 2 }}>
        <strong>Disadvantages:</strong>
      </Typography>

      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            padding: 0,
          },
        }}
      >
        <ListItem>Property and tenant management</ListItem>
        <ListItem>Maintenance and repair costs</ListItem>
        <ListItem>Risk of rental vacancies</ListItem>
        <ListItem>Fluctuation in real estate prices</ListItem>
      </List>

      <Title css={tw`mt-4`}>Is this investment right for me?</Title>
      <Typography variant="body2" gutterBottom>
        Residential rental-type investments may be particularly appealing to individuals seeking a steady, long-term
        income stream.
      </Typography>
      <Typography variant="body2" gutterBottom>
        Since each property is professionally managed, you won't have to worry about the day-to-day responsibilities of
        being a landlord.
      </Typography>
      <Typography variant="body2" gutterBottom>
        An investor in residential rentals should be comfortable with the potential of vacancies or maintenance issues,
        and must be patient, as significant returns from rental properties typically occur over a longer time frame
      </Typography>

      <Box css={tw`bg-orange-100 p-2 mt-4`}>
        <Title css={tw`mt-4 text-orange-500`}>Disclaimer</Title>
        <Typography variant="body2" gutterBottom sx={{ fontStyle: 'italic' }}>
          This explanation provides a general overview of rental investment and should not be considered as financial
          advice. For personalized guidance on investing in rental properties, it is recommended to consult with a
          professional financial advisor who can help assess individual financial situations and goals.
        </Typography>
      </Box>
    </ArticleContainer>
  );
};

export default InvestmentType;
