import moment from 'moment';

export function formatElapsedTime(oldDate: Date, futureDate?: Date): string {
  const currentDate = futureDate ? moment(futureDate) : moment();
  const oldMoment = moment(oldDate);
  const elapsedTime = moment.duration(currentDate.diff(oldMoment));

  const years = elapsedTime.years();
  const months = elapsedTime.months();
  const days = elapsedTime.days();
  const hours = elapsedTime.hours();
  const minutes = elapsedTime.minutes();
  const seconds = elapsedTime.seconds();

  if (elapsedTime.asSeconds() < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  } else if (elapsedTime.asMinutes() < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
  } else if (elapsedTime.asHours() < 24) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else if (elapsedTime.asDays() < 30) {
    return `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`;
  } else if (elapsedTime.asMonths() < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ${days} day${days !== 1 ? 's' : ''}`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`;
  }
}

export function formatRemainingTime(futureDate: Date, oldDate?: Date): string {
  const currentDate = oldDate ? moment(oldDate) : moment();
  const futureMoment = moment(futureDate);
  const remainingTime = moment.duration(futureMoment.diff(currentDate));

  const years = remainingTime.years();
  const months = remainingTime.months();
  const days = remainingTime.days();
  const hours = remainingTime.hours();
  const minutes = remainingTime.minutes();
  const seconds = remainingTime.seconds();

  if (remainingTime.asSeconds() < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  } else if (remainingTime.asMinutes() < 60) {
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ${seconds} second${seconds !== 1 ? 's' : ''}`;
  } else if (remainingTime.asHours() < 24) {
    return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else if (remainingTime.asDays() < 30) {
    return `${days} day${days !== 1 ? 's' : ''} ${hours} hour${hours !== 1 ? 's' : ''}`;
  } else if (remainingTime.asMonths() < 12) {
    return `${months} month${months !== 1 ? 's' : ''} ${days} day${days !== 1 ? 's' : ''}`;
  } else {
    return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`;
  }
}
