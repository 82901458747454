import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import ArticleContainer from 'components/article/article-container';
import { Ads, FinancialMetadata } from 'graphql/schema/__generated__/graphql-types';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const AssetPrice = (props: { ads: Ads; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { assetValue } = props.ads.financial;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver} minHeight={100}>
        <Headline>Asset price</Headline>
        <Divider />

        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <TableContainer component={Paper} sx={{ marginTop: 2, width: 650 }}>
            <Table
              sx={{
                width: 650,
                '& .MuiTableCell-root': {
                  padding: 1,
                  //border: 1
                },
              }}
              aria-label="simple table"
            >
              <TableBody>
                {assetValue.metadata &&
                  assetValue.metadata.map((metadata: FinancialMetadata, index: number) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body2" sx={tw`text-secondary-500`}>
                          {metadata.title}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" align="right" sx={tw`text-secondary-500`}>
                          <NumberFormat
                            value={metadata.value}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            prefix={'$ '}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" sx={tw`font-bold text-blue-500`}>
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" align="right" sx={tw`font-bold text-blue-500`}>
                      <NumberFormat
                        value={assetValue.totalAssetValue}
                        decimalScale={2}
                        fixedDecimalScale
                        displayType={'text'}
                        thousandSeparator={' '}
                        prefix={'$ '}
                        renderText={(value: string) => <span>{value}</span>}
                      />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ArticleContainer>
    </>
  );
};

export default AssetPrice;
