import tw from 'twin.macro';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { AdsStatus, Calendar, OtherDate } from 'graphql/schema/__generated__/graphql-types';
import moment from 'moment';
import _ from 'lodash';
import { formatRemainingTime, formatElapsedTime } from 'helpers/date-functions';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const Div = styled.div<{ status: AdsStatus }>(({ status }) => [
  tw`
    w-full flex flex-row mt-1 p-1 rounded-md bg-white  
    items-center justify-center
    `,
  status === AdsStatus.ComingSoon && tw` bg-blue-500 `,
  status === AdsStatus.NewLaunch && tw`bg-green-500 `,
  status === AdsStatus.Open && tw`bg-green-500 `,
  status === AdsStatus.AlmostDone && tw`bg-green-500 `,
  status === AdsStatus.LastChance && tw`bg-green-500 `,
  status === AdsStatus.SoldOut && tw` bg-gray-500`,
]);

const Span = tw.span`
    overflow-hidden text-center
    text-white uppercase whitespace-nowrap truncate
    mr-1
    `;

//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const DaysCount = (props: { calendar: Calendar; status: AdsStatus }) => {
  const refreshCountState = useState(0);

  const getRefreshInterval = (remainingMilliseconds: number) => {
    const seconds = 1000;
    const minutes = seconds * 60;
    const hours = minutes * 60;
    const days = hours * 24;
    const months = days * 30;
    const years = days * 365;

    if (remainingMilliseconds < minutes) return seconds;
    if (remainingMilliseconds < hours) return seconds;
    if (remainingMilliseconds < days) return minutes;
    if (remainingMilliseconds < months) return hours;
    if (remainingMilliseconds < years) return days;
    return months;
  };

  const shouldNotDisplay =
    moment.utc(props.calendar.salesStart).isBefore(moment().utc()) && props.status === AdsStatus.ComingSoon;

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    const updateTime = () => {
      refreshCountState[1]((refreshCount) => refreshCount + 1);
      const currentTime = new Date();
      const salesStart = moment.utc(props.calendar.salesStart).local().toDate();
      const remainingMilliseconds = salesStart.getTime() - currentTime.getTime();
      const refreshInterval = getRefreshInterval(remainingMilliseconds);

      timeoutId = setTimeout(updateTime, refreshInterval);
    };

    updateTime();

    if (shouldNotDisplay && timeoutId) clearTimeout(timeoutId);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [props.calendar.salesStart]);

  let daysCount: string | null = null;
  let tooltipTitle: string = ``;
  const m_salesStart = moment.utc(props.calendar.salesStart);
  const salesStart = m_salesStart.toDate();
  const soldOut = props.calendar.otherDates
    ? _.reduce(
        props.calendar.otherDates,
        (acc: Date | null, e: OtherDate) => {
          if (e.title?.toLowerCase() === 'sold out') return e.date || null;
          return acc;
        },
        null,
      )
    : null;
  switch (props.status) {
    case AdsStatus.ComingSoon:
      daysCount = formatRemainingTime(salesStart);
      tooltipTitle = `Sale start on ${m_salesStart.format('LL [-] HH:mm [UTC]')}`; //`Time before launch`
      break;
    case AdsStatus.SoldOut:
      daysCount = soldOut ? formatElapsedTime(salesStart, soldOut) : null;
      tooltipTitle = `Sale duration`;
      break;
    case AdsStatus.AlmostDone:
    case AdsStatus.LastChance:
    case AdsStatus.Open:
    case AdsStatus.NewLaunch:
      daysCount = formatElapsedTime(salesStart);
      tooltipTitle = `Time elapsed`;
      break;
    default:
      break;
  }

  if (daysCount === null || shouldNotDisplay) return null;
  else
    return (
      <Div status={props.status}>
        <Span>{daysCount}</Span>
        <Tooltip
          title={tooltipTitle}
          placement="right"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#faf5ff',
                color: '#44337a',
              },
            },
            arrow: {
              sx: {
                color: '#faf5ff',
              },
            },
          }}
        >
          <IconButton sx={{ height: 16, width: 16, padding: 0 }}>
            <InfoIcon sx={{ fontSize: 16, color: 'white' }} />
          </IconButton>
        </Tooltip>
      </Div>
    );
};

export default DaysCount;
