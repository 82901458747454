import { combineReducers } from 'redux';
import Listings from 'redux/reducers/listings';
import ListingDetails from 'redux/reducers/listing-details';
import listingFilters from 'redux/reducers/listing-filters';

export default combineReducers({
  listings: Listings.reducer,
  listingDetails: ListingDetails.reducer,
  listingFilters: listingFilters.reducer,
});
