import { FormControl, MenuItem, Select } from '@mui/material';
import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import React from 'react';
import Details from './details';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Card = tw.div`
min-w-86 max-w-lg mt-2   w-128  self-center
`;

const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`;
const HeadLine = tw.h1`
text-lg font-medium uppercase
`;
const Content = tw.div`
px-2 flex flex-row
`;

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`;

const Value = tw.div`
flex-grow text-sm text-right capitalize
`;

interface IProfitability {
  name: string;
  value: number;
}
const profitability: IProfitability[] = [
  {
    name: 'Cash On Cash',
    value: 8.75,
  },
  {
    name: 'Capital Appreciation',
    value: 15.56,
  },
  {
    name: 'Total (IRR)',
    value: 24.31,
  },
];

const Profitability = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const [year, setYear] = React.useState<number>(1);
  const handleChange = (event: { target: { value: string } }) => {
    setYear(parseInt(event.target.value));
  };

  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Profitability</Headline>
      <Divider />
      <Card>
        <CardHead css={tw`items-center justify-between  `}>
          <HeadLine>Global</HeadLine>
          <FormControl sx={{ m: 0, minWidth: 120, background: 'white' }} size="small">
            <Select value={`${year}`} onChange={handleChange}>
              <MenuItem value={1}>1 year</MenuItem>
              <MenuItem value={2}>2 years</MenuItem>
              <MenuItem value={3}>3 years</MenuItem>
              <MenuItem value={4}>4 years</MenuItem>
              <MenuItem value={5}>5 years</MenuItem>
            </Select>
          </FormControl>
        </CardHead>

        {profitability.map((e, index) => (
          <Content key={index}>
            <Label>{e.name}</Label>
            <Value>
              <NumberFormat
                value={e.value * year}
                decimalScale={2}
                fixedDecimalScale
                displayType={'text'}
                thousandSeparator={' '}
                suffix={' %'}
                renderText={(value: string) => <span>{value}</span>}
              />
            </Value>
          </Content>
        ))}
      </Card>

      <Details />
    </ArticleContainer>
  );
};

export default Profitability;
