import tw from 'twin.macro';
// @ts-ignore
import { css } from 'styled-components/cssprop'; //eslint-disable-line
import styled from 'styled-components';
import Config from './config';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import useAnimatedNavToggler from 'helpers/useAnimatedNavToggler.js';
import RootesData from 'routes/routes-data';
import { useLocation } from 'react-router-dom';
import { ReactElement } from 'react';
import { motion } from 'framer-motion';
import { Stack, Typography } from '@mui/material';
import packageJson from '../../../package.json';
const HeaderContainer = styled.header<{ light?: boolean }>`
  ${tw`flex justify-between items-center max-w-screen-xl mx-auto`}
  ${(props) => props.light && tw`pt-8 max-w-none`}
`;

const DesktopNavLinks = styled.nav<{ light?: boolean }>`
  ${tw`hidden lg:flex flex-1 justify-between items-center`}
  ${(props) => props.light && tw`text-gray-100 border-transparent hover:border-gray-300 hover:text-gray-300`}
`;

const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
const NavToggle = styled.button<{ light?: boolean }>`
  ${tw`lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300`}
  ${(props) => props.light && tw`text-gray-100 hover:text-gray-300`}
`;

const NavLinks = tw.div`inline-block`;

const NavLink = styled.a<{ active?: boolean; light?: boolean; mobile?: boolean }>`
  ${tw`text-lg my-2 lg:mx-3 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2  border-transparent hover:border-primary-500 hocus:text-primary-500`}
  ${(props) => props.light && tw`text-gray-100 border-transparent hover:border-gray-300 hover:text-gray-300`}
  ${(props) => props.active && tw`border-primary-500 text-primary-500`}
  ${(props) => props.active && props.light && tw`border-gray-100 text-gray-100`}
`;

const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${tw`flex! flex-col  items-center`}
`);

const NavLinkSeparator = tw.span``;

const LogoLink = styled(NavLink)`
  ${tw`flex items-start font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-10 mr-3`}
  }
`;

const Header = (
  props: {
    appearance?: 'light' | 'dark';
  } = { appearance: 'dark' },
) => {
  const collapseBreakpointCss = collapseBreakPointCssMap['lg'];
  const { showNavLinks, toggleNavbar, animation } = useAnimatedNavToggler();
  const navMenu = RootesData.filter((e) => !e.hidden);

  const location = useLocation();

  const Links = (props: { mobile?: boolean; light?: boolean } = { mobile: false, light: false }) =>
    navMenu.reduce((acc: ReactElement[], route, index) => {
      const item = (
        <NavLink {...props} href={route.path} key={index} active={location.pathname === route.path}>
          {route.name}
        </NavLink>
      );
      const separator = <NavLinkSeparator key={route.name}>|</NavLinkSeparator>;
      return index + 1 < navMenu.length && !props.mobile ? [...acc, item, separator] : [...acc, item];
    }, []) as ReactElement[];

  return (
    <HeaderContainer light={props.appearance === 'light'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks} light={props.appearance === 'light'}>
        <LogoLink href="/" light={props.appearance === 'light'}>
          <img src={Config.Logo.imgSrc} alt={Config.Logo.text} />
          <Stack direction="column" spacing={10}>
            {Config.Logo.text}
            <Typography variant="body1" sx={tw`text-blue-500 -mt-2`}>
              {packageJson.version}
            </Typography>
          </Stack>
        </LogoLink>

        <NavLinks>{Links({ light: props.appearance === 'light' })}</NavLinks>
      </DesktopNavLinks>
      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        <LogoLink href="/" light={props.appearance === 'light'}>
          <img src={Config.Logo.imgSrc} alt={Config.Logo.text} />
          {Config.Logo.text}
        </LogoLink>

        <MobileNavLinks
          initial={{ x: '150%', display: 'none' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {Links({ mobile: true })}
        </MobileNavLinks>

        <NavToggle
          light={props.appearance === 'light'}
          onClick={toggleNavbar}
          className={showNavLinks ? 'open' : 'closed'}
        >
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </HeaderContainer>
  );
};

export default Header;

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
