import { useState } from 'react';
import Slider from 'react-slick';
import tw from 'twin.macro';
import styled from 'styled-components';
import ImageSlider from './image-slider';
//import NoImagePlaceHolder from 'images/no_picture.png';
import _ from 'lodash';
import AdStatus from './ad-status';
//import TakersBadge from './takers-badge';
import DaysCount from './days-count';
import AdId from './ad-id';
import TakerRestriction from './taker-restriction';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons';
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';
import Briicks from './briicks';
import ReturnOnInvestment from './return-on-investment';
import Address from './address';
import Type from './type';
import Payment from './payment';
import AddCartWichList from './add-cart-wichlist';
import Maker from './maker';
import VistBuy from './visit-buy';
import Progress from './progress';
import { BriicksListing } from 'graphql/schema/__generated__/graphql-types';
import { Stack } from '@mui/material';
import AdvertiseDate from './advertise-date';
import TotalAssetPrice from './total-asset-price';

//min-w-96
const Card = tw.div`min-w-86 max-w-sm overflow-hidden h-auto border-gray-400 rounded-lg border`;
const ImageContainer = styled.div`
  ${tw`w-full h-64 lg:h-72 border-gray-400 border-b relative`}
`;

//
//w-52
const Badges = tw.div`
absolute mx-2 my-2 top-0 left-0 flex-row w-48 text-xs
`;

const ControlsContainer = tw.div`
absolute flex bottom-0 left-0 right-0 flex-row text-xs
items-end
`;
const Controls = tw.div`
flex items-center justify-center bottom-0 right-0 p-3 bg-white 
rounded-tl-3xl place-self-end
`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`sm:mt-0 first:ml-0 ml-4 rounded-full p-0 text-lg`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const DetailsContainer = tw.div`
w-full p-2
`;

const Divider = tw.hr`
-mr-2 -ml-2 mt-1
`;

const Grid2ColsContainer = tw.div`
flex grid grid-cols-3 pt-1
`;

function calculePercent(val: number, total: number): number {
  return (val * 100) / total;
}

const AssetCard = (props: { listing: BriicksListing }) => {
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  const { listing } = props;
  const saleProgress = calculePercent(
    listing.briicks.distribution.totalShares - listing.briicks.distribution.stock,
    listing.briicks.distribution.totalShares,
  );
  const minInvest = listing.briicks.minBriicksPurchase * listing.briicks.price;
  const maxInvest = listing.briicks.maxBriicksPurchase * listing.briicks.price;
  let images = [listing.asset.pictures.featured];
  if (listing.asset.pictures.galleries) {
    images = _.reduce(
      listing.asset.pictures.galleries,
      (acc: string[], galerie) => {
        return [...acc, ..._.map(galerie.pictures, (e) => `${e}?tr=w-500,h-500`)];
      },
      [],
    );
  }

  return (
    <Card>
      <ImageContainer>
        <ImageSlider imagesSrc={images} onSliderRef={setSliderRef} />
        <Badges>
          <AdStatus status={listing.status} />
          {/* <TakersBadge>bought by 3652 Takers</TakersBadge> */}

          <DaysCount calendar={listing.calendar} status={listing.status} />
        </Badges>
        <Progress progress={saleProgress} status={listing.status} />
        <ControlsContainer>
          <div css={tw`flex-1 flex flex-row`}>
            <Stack justifyContent="flex-end" sx={tw`w-full`}>
              <AdId adId={listing.shortCode} />
              <AdvertiseDate date={listing.calendar.advertise} />
            </Stack>
            <Stack justifyContent="flex-end" sx={tw`w-full mr-1`}>
              {listing.briicks.restrictions && listing.briicks.restrictions.hasRestriction && <TakerRestriction />}
              <TotalAssetPrice price={listing.financial.assetValue.totalAssetValue} />
            </Stack>
          </div>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </ControlsContainer>
      </ImageContainer>
      <DetailsContainer>
        <Briicks briicks={listing.briicks} />
        <Divider />
        <ReturnOnInvestment
          financial={listing.financial}
          rental={listing.rental!}
          rentalProfitability={listing.rental!.profitability}
        />
        <Divider />
        <Grid2ColsContainer>
          <Address address={listing.asset.location.address} />
          <Type
            assetFeatures={listing.asset.assetFeatures}
            assetType={listing.asset.assetType}
            measurement={listing.preferences.measurement}
          />
        </Grid2ColsContainer>

        <Divider />

        <Grid2ColsContainer>
          <Payment minInvest={minInvest} maxInvest={maxInvest} />
          <AddCartWichList />
        </Grid2ColsContainer>

        <Divider />

        <Grid2ColsContainer>
          <Maker source={listing.source} />
          <VistBuy id={listing._id} />
        </Grid2ColsContainer>
      </DetailsContainer>
    </Card>
  );
};

export default AssetCard;
