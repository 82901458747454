import tw from 'twin.macro';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@mui/lab';
import ArticleContainer from 'components/article/article-container';

const Headline = tw.h1`
text-primary-500 font-bold text-2xl
`;
const Divider = tw.hr`
`;

const Content = tw.div`
flex justify-center pt-8
`;

const Agenda = (props: { id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  return (
    <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
      <Headline>Agenda</Headline>
      <Divider />

      <Content>
        <Timeline>
          <TimelineItem>
            <TimelineOppositeContent>31/06/2023</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#38b2ac' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#38b2ac'}>rent END</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>01/04/2023</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#38b2ac' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#38b2ac'}>Start RENT</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>31/03/2023</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#38b2ac' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#38b2ac'}>rent END</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>01/01/2023</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#38b2ac' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#38b2ac'}>Start RENT</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>31/12/2022 - 18:00 GMT</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#f97316' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#f97316'}>Réhabiliation End</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>31/12/2022 - 15:00 GMT</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#f97316' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#f97316'}>Réhabiliation Start</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>31/12/2022 - 18:00 GMT</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#f56565' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#f56565'}>Closing</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>05/08/2022 - 07:00 GMT</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#22c55e' }} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent color={'#22c55e'}>Launch</TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineOppositeContent>01/08/2022 - 07:00 GMT</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant="outlined" sx={{ borderColor: '#4299e1' }} />
            </TimelineSeparator>
            <TimelineContent color={'#4299e1'}>Preorder</TimelineContent>
          </TimelineItem>
        </Timeline>
      </Content>
    </ArticleContainer>
  );
};

export default Agenda;
