import { FormControl, MenuItem, Select } from '@mui/material';
import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import React from 'react';

const Container = tw.div`min-w-86 max-w-lg mt-8`;

const Card = tw.div`
min-w-86 max-w-lg
`;
const CardHead = tw.div`
bg-primary-500 text-white p-2 flex flex-row
`;
const HeadLine = tw.h1`
text-lg font-medium uppercase
`;

const Content = tw.div`
px-2 flex flex-row
`;

const Label = tw.div`
w-40 pr-1 text-sm font-medium capitalize
`;

const Value = tw.div`
flex-grow text-sm text-right capitalize
`;

interface ICapitalApreciation {
  name: string;
  value: number;
}
const capitalApreciationValues: ICapitalApreciation[] = [
  {
    name: 'Current Value',
    value: 450000,
  },
  {
    name: 'Expected',
    value: 520000,
  },
  {
    name: 'Capital Appreciation',
    value: 15.56,
  },
];

const CapitalApreciation = () => {
  const [year, setYear] = React.useState<number>(1);
  const handleChange = (event: { target: { value: string } }) => {
    setYear(parseInt(event.target.value));
  };

  return (
    <Container>
      <Card>
        <CardHead css={tw`items-center justify-between  `}>
          <HeadLine>Capital Appreciation</HeadLine>
          <FormControl sx={{ m: 0, minWidth: 120, background: 'white' }} size="small">
            <Select value={`${year}`} onChange={handleChange}>
              <MenuItem value={1}>1 year</MenuItem>
              <MenuItem value={2}>2 years</MenuItem>
              <MenuItem value={3}>3 years</MenuItem>
              <MenuItem value={4}>4 years</MenuItem>
              <MenuItem value={5}>5 years</MenuItem>
            </Select>
          </FormControl>
        </CardHead>

        {capitalApreciationValues.map((e, index) => (
          <Content key={index}>
            <Label>{e.name}</Label>
            <Value>
              <NumberFormat
                value={
                  e.name === 'Expected'
                    ? capitalApreciationValues[2].value * 0.01 * (year - 1) * e.value + e.value
                    : e.value
                }
                decimalScale={2}
                fixedDecimalScale
                displayType={'text'}
                thousandSeparator={' '}
                suffix={' %'}
                renderText={(value: string) => <span>{value}</span>}
              />
            </Value>
          </Content>
        ))}
      </Card>
    </Container>
  );
};

export default CapitalApreciation;
