import Header from 'components/header';
import Footer from 'components/footer';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
//import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import tw from 'twin.macro';
import FullCard from 'components/cards/full-card';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'redux/store';
import { motion } from 'framer-motion';
import { ReactComponent as LoaderIcon } from 'feather-icons/dist/icons/loader.svg';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ListingDetailsActions } from '../../redux/reducers/listing-details';

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const LoadingContainer = styled(motion.div)`
  ${tw`flex w-full h-10 py-8 justify-center items-center`}
  svg {
    ${tw`h-10 w-10`}
  }
`;

const ListingDetails = () => {
  const listingDetails = useSelector((state) => state.listingDetails);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) dispatch(ListingDetailsActions.getBriicksListingDetailsRequest({ id }));
  }, [id, dispatch]);
  //<AnimationRevealPage isNavigation={true}>
  return (
    <>
      <Header />
      <Container>
        <Content>
          {listingDetails.isLoading && (
            <LoadingContainer
              animate={{ rotate: 360 }}
              transition={{
                loop: Infinity,
                ease: 'linear',
                duration: 1.5,
              }}
            >
              <LoaderIcon />
            </LoadingContainer>
          )}
          {listingDetails.listing && <FullCard listing={listingDetails.listing} isNavigation={true} />}
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default ListingDetails;
