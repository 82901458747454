import { Pannellum } from 'pannellum-react';
import image360 from 'images/img-360/img1.jpeg';
//https://medium.com/swlh/how-to-display-360-images-using-react-and-select-coordinates-b1467d09fb5c
const View360 = () => {
  return (
    <Pannellum
      width="100%"
      height="500px"
      image={image360}
      pitch={10}
      yaw={180}
      hfov={110}
      autoLoad
      onLoad={() => {
        //console.log("panorama loaded");
      }}
    ></Pannellum>
  );
};

export default View360;
