import tw from 'twin.macro';
import styled from 'styled-components';
import { AdsStatus as Listing_Status } from 'graphql/schema/__generated__/graphql-types';

const Span = styled.span<{ status: Listing_Status }>(({ status }) => [
  status === Listing_Status.NewLaunch && tw`bg-green1-500`,
  status === Listing_Status.AlmostDone && tw`bg-orange1-500`,
  status === Listing_Status.SoldOut && tw` bg-red1-500`,
  status === Listing_Status.ComingSoon && tw` bg-blue-500`,
  status === Listing_Status.Open && tw`bg-teal-500`,
  status === Listing_Status.LastChance && tw` bg-yellow-500`,
  status === Listing_Status.NotFunded && tw` bg-secondary-100`,

  tw`w-full rounded-md text-center p-1 text-white uppercase block`,
]);
//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const AdStatus = (props: { status: Listing_Status; children: React.ReactNode }) => {
  return <Span status={props.status}>{props.children}</Span>;
};

export default AdStatus;
