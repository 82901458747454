import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { AssetCondition, EventMetadata } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import parse from 'html-react-parser';
import styled from 'styled-components';

const AboutContent = styled.div`
  h2 {
    ${tw`font-semibold py-2 mt-2 text-base`}
  }
  a {
    ${tw`text-blue-500`}
  }
  ul {
    ${tw`list-disc list-inside`}
  }

  li {
    ${tw`mb-1 ml-4`}
  }
`;

const AssetConditionComponent = (props: { assetCondition: AssetCondition }) => {
  const { assetCondition } = props;
  return (
    <>
      <Box sx={{ width: 850, alignSelf: 'center' }}>
        <Typography variant="body2" sx={tw`text-xl text-blue-500 mt-6`}>
          Conditions
        </Typography>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                padding: 1,
                //border: 1
              },
            }}
            aria-label="simple table"
          >
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Construction Year
                </TableCell>
                <TableCell align="right" sx={tw`capitalize`}>
                  {assetCondition.event.constructionYear.year}
                </TableCell>
              </TableRow>

              {assetCondition.event.eventMetadata?.map((event: EventMetadata, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {event.title}
                  </TableCell>
                  <TableCell align="right">{event.date}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="body2" sx={tw`text-lg text-blue-500 mt-2`}>
                    Summary
                  </Typography>

                  <AboutContent>
                    {assetCondition.description ? parse(assetCondition.description, { trim: true }) : 'no data'}
                  </AboutContent>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AssetConditionComponent;
